import { Box, Typography } from '@material-ui/core';
import { useGetRestaurantById } from 'graphql/hooks';
import React, { useEffect, useState } from 'react';
import { useStyles } from './style';
import { ServiceType } from 'generated/types';
import { filter } from 'lodash';
import { useStore } from 'store';
import { getTimeData } from 'components/DateTimePicker/utils';
import { isRestaurantClosed } from 'pages/MyCart/util';
import TodayBizHour from './TodayBizHour';
import moment from 'moment';

interface IProps {
  restaurantId: string | number;
}

const BizHoursStatus = ({ restaurantId }: IProps) => {
  const classes = useStyles();

  const { restaurant } = useGetRestaurantById(restaurantId, 'ALL');

  const {
    state: { deliveryProvider }
  } = useStore();

  const bizHours = restaurant?.getBizHours;

  const isTakeoutDisabled = !(restaurant?.takeoutPaymentSetting?.onlinePaymentTypes || restaurant?.takeoutPaymentSetting?.onsitePaymentTypes) || !restaurant.enableTakeoutOrder;

  const isDiningDisabled = !(restaurant?.diningPaymentSetting?.onlinePaymentTypes || restaurant?.diningPaymentSetting?.onsitePaymentTypes) || !restaurant.enableDiningOrder;

  const isDeliveryDisabled = !(restaurant?.deliveryPaymentSetting?.onlinePaymentTypes || restaurant?.deliveryPaymentSetting?.onsitePaymentTypes) || !restaurant.enableDeliveryOrder;

  const diningBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === ServiceType.RestaurantDining) : [];

  const [diningClosed, setDiningClosed] = useState(false);

  const [nextSlotStr, setNextSlotStr] = useState('');

  const noOrderTypesSupported = isTakeoutDisabled && isDiningDisabled && isDeliveryDisabled;

  useEffect(() => {
    const isdiningClosed = isRestaurantClosed(diningBizHours, 'DINING', deliveryProvider);
    setDiningClosed(isdiningClosed);
  }, [deliveryProvider, diningBizHours]);

  useEffect(() => {
    if (bizHours) {
      let waitMins = 0;

      const curHours = filter(bizHours, (hour) => hour.serviceType === ServiceType.RestaurantDining);

      const availableSlots = getTimeData(curHours, waitMins);
      if (availableSlots.length) {
        const firstSlot = availableSlots[0].availableSlots[0];

        if (firstSlot) {
          const tomorrow = moment().add(1, 'day');
          const firstSlotMoment = moment(firstSlot);
          const nextSlot = firstSlot.format('LT');
          if (firstSlotMoment.isSame(tomorrow, 'day')) {
            setNextSlotStr(nextSlot + ' Tomorrow');
          } else if (firstSlotMoment.isSame(moment(), 'day')) {
            setNextSlotStr(nextSlot + ' Today');
          } else {
            setNextSlotStr(moment(firstSlot).format('Do MMM, LT'));
          }
        }
      }
    }
  }, [bizHours]);
  if (diningBizHours && diningBizHours.length > 0) {
    return (
      <Box className={`${classes.container} ${diningClosed ? classes.errorBg : classes.successBg}`}>
        <Box padding={1}>
          {noOrderTypesSupported && <Typography variant="body1"> Orders not supported by the restaurant</Typography>}
          {diningClosed ? (
            <Typography variant="body1">
              Opens: {nextSlotStr} {!isTakeoutDisabled ? '| Place advance takeout order ' : ''}
            </Typography>
          ) : (
            <TodayBizHour bizHours={diningBizHours} />
          )}
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export default BizHoursStatus;
