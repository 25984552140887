import React, { useState } from 'react';
import { Typography, Link, makeStyles } from '@material-ui/core';

interface IProps {
  text: string;
  limit?: number;
  className?: string;
}

const useStyles = makeStyles(() => ({
  link: {
    '&:hover': {
      textDecoration: 'none'
    },
    cursor: 'pointer',
    textDecoration: 'none'
  }
}));

const Paragraph = ({ text, limit = 200, className = undefined }: IProps) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  if (text) {

    if (text.length > limit) {
      const _text = show ? text : text.substr(0, limit).concat('...').concat(' ');

      return (
        <Typography variant="caption" paragraph={true} className={className ? className : undefined} align="justify">
          {_text}
          <Link onClick={() => setShow(!show)} color="primary" className={classes.link}>
            <strong className="lb-color-blue">Read {show ? 'Less' : 'More'}</strong>
          </Link>
        </Typography>
      );
    } else {
      return (
        <Typography variant="caption" paragraph={true} className={className ? className : undefined} align="justify">
          {text}
        </Typography>
      );
    }
  }

  return null;
};

Paragraph.defaultProps = {
  text: '',
  limit: 200
};
export default Paragraph;
