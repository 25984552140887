import Geocode from 'react-geocode';
import { includes } from 'lodash';

interface IGetAddressByCoordinatesArgs {
  latitude: number;
  longitude: number;
}

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

const useLocation = () => {
  /**
   * This method determines the user current postion based on navigator geolocation.
   *
   * @param onSuccessCallback - Callback function on success response.
   * @param onError - [Optional] Callback function in case of error.
   */
  const getUserCurrentLocation = (onSuccessCallback: (postion: Position) => void, onError?: (error: PositionError) => void) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccessCallback, (error) => {
        if (error.PERMISSION_DENIED) {
          console.log('Please allow app to use geolocation');
        }

        if (error.POSITION_UNAVAILABLE) {
          console.log('Position Not available');
        }

        if (error.TIMEOUT) {
          console.log('Connection Timeout');
        }

        onError && onError(error);
      });
    }
  };

  /**
   * Retrieves city, address and country from address component.
   *
   * @param addressComponent
   */
  const parseAddress = (addressComponent: any) => {
    const location: any = [];

    for (const component of addressComponent) {
      switch (component.types[0]) {
        case 'locality':
          location.push(component.long_name);
          break;
        case 'administrative_area_level_1':
          location.push(component.long_name);
          break;
        case 'country':
          location.push(component.long_name);
          break;
      }
    }

    return location;
  };

  /**
   * Fetches formatted address based on latitude and longitude.
   *
   * @param {IGetAddressByCoordinatesArgs} - Latitude and longitude input.
   */
  const getAddressByCoordinates = async (
    response: any,
    shouldParse: boolean = true
  ): Promise<{
    pincode: string;
    address: string;
    addressComponent: any;
  }> => {
    const addressComponent = response.results[0].address_components;

    const formattedAddress = response.results[0].formatted_address;

    let pincode = '';

    for (const component of addressComponent) {
      if (includes(component.types, 'postal_code')) {
        pincode = component.long_name;
      }
    }

    let parsedAddress = '';

    if (shouldParse) {
      parsedAddress = parseAddress(addressComponent).join(', ');
    } else {
      parsedAddress = formattedAddress;
    }

    return {
      address: parsedAddress,
      addressComponent,
      pincode
    };
  };

  return {
    getAddressByCoordinates,
    getUserCurrentLocation
  };
};

export default useLocation;
