import { useMutationWithLoader, useLazyQueryWithLoader, useQueryWithLoader } from 'hooks/loader';
import { createOrderMutation, createStripeSetupIntentMutation, createTableServiceOrderMutation } from 'graphql/mutations';
import { DeliveryProviders, MutationCreateOrderArgs, MutationCreateTableServiceOrderArgs } from 'generated/types';
import { getBuyerDeliveryOrders, getBuyerGroceryOrders, getBuyerOrdersQuery, getGroceryOrderByOrderId, getOrderByOrderIdQuery } from 'graphql/query';
import { useGetLokobeeUser } from './user.hooks';
import { useState, useEffect, useContext } from 'react';
import { map } from 'lodash';
import { plainToClass } from 'class-transformer';
import { Order } from 'models';
import { useStore } from 'store';
import { AuthContext } from 'fbase/authContext';
import GroceryOrderModel from 'models/GroceryOrder';

export const useGetBuyerOrdersQuery = () => {
  const [hasMore, setHasMore] = useState<boolean | null | undefined>(false);

  const [endCursor, setEndCursor] = useState<string | null | undefined>(null);

  const { data: currentUserData } = useGetLokobeeUser();

  const [orders, setOrders] = useState<Order[]>([]);

  const [loading, setLoading] = useState(true);

  const [fetching, setFetching] = useState(false);

  const pageLength = 20;

  const [getOrderData, { data, error, fetchMore }] = useLazyQueryWithLoader(getBuyerOrdersQuery, {
    variables: {
      input: {
        first: pageLength,
        after: undefined
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    //Waiting to get user token for passing it to backend Authorization header
    if (currentUserData) {
      getOrderData();
    }
  }, [currentUserData, getOrderData]);

  useEffect(() => {
    if (data) {
      const { getBuyerOrders } = data;

      if (getBuyerOrders && getBuyerOrders.pageInfo) {
        const { hasNextPage, endCursor } = getBuyerOrders.pageInfo;
        setHasMore(hasNextPage);

        setEndCursor(endCursor);

        if (getBuyerOrders.edges?.length) {
          const orderEdgeArray = map(getBuyerOrders.edges, ({ node }) => node);

          const orderArray = plainToClass(Order, orderEdgeArray);

          setOrders(orderArray);
        }
        setLoading(false);
        setFetching(false);
      }
    }
  }, [data]);

  const fetchMoreRecords = () => {
    if (hasMore) {
      try {
        setFetching(true);
        fetchMore({
          variables: {
            input: {
              first: pageLength,
              after: endCursor
            }
          },
          updateQuery: (previousResult: any, { fetchMoreResult }) => {
            const previousEntry = map(previousResult.getBuyerOrders.edges, ({ node }) => node);

            const newComments = map(fetchMoreResult?.getBuyerOrders.edges, ({ node }) => node);

            const updated = [...previousEntry, ...newComments];

            return {
              getBuyerOrders: {
                edges: map(updated, (i) => {
                  return {
                    node: i,
                    __typename: 'OrderConnectionEdge'
                  };
                }),
                pageInfo: {
                  endCursor: fetchMoreResult?.getBuyerOrders.pageInfo.endCursor,
                  hasNextPage: fetchMoreResult?.getBuyerOrders.pageInfo.hasNextPage,
                  __typename: fetchMoreResult?.getBuyerOrders.pageInfo.__typename
                },
                __typename: previousResult.getBuyerOrders.__typename
              },
              __typename: previousResult.__typename
            };
          }
        });
      } catch (e) {
        setFetching(false);
        console.log(e);
      }
    }
  };

  return {
    data: orders,
    fetching,
    loading,
    error,
    fetchMoreRecords,
    hasMore
  };
};

export const useGetOrderQuery = (orderId: string | undefined | null, buyerUid: string | undefined | null) => {
  const [order, setOrder] = useState<Order | null>(null);

  const { loading, data, error, refetch } = useQueryWithLoader(getOrderByOrderIdQuery, {
    skip: !orderId || !buyerUid,
    variables: {
      input: {
        orderId: orderId,
        buyerUid: buyerUid
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      const dishObject = plainToClass(Order, data.getOrderByOrderId);

      setOrder(dishObject);
    }
  }, [data, loading]);

  return {
    loading,
    order,
    error,
    refetch
  };
};

export const useCreateOrder = () => {
  const [createOrder, { data, loading, error }] = useMutationWithLoader<any, any>(createOrderMutation);

  return {
    createOrder,
    data,
    loading,
    error
  };
};

export const useCreateSetupIntent = () => {
  const [createStripeSetupIntent, { data, loading, error }] = useMutationWithLoader<any, MutationCreateOrderArgs>(createStripeSetupIntentMutation);

  return {
    createStripeSetupIntent,
    data,
    loading,
    error
  };
};

export const useCreateTableServiceOrderMutation = () => {
  const [createTableServiceOrder, { data, loading, error }] = useMutationWithLoader<any, MutationCreateTableServiceOrderArgs>(createTableServiceOrderMutation, {}, true);

  return {
    createTableServiceOrder,
    data,
    loading,
    error
  };
};

export const useGetDeliveryOrder = (deliveryProvider: DeliveryProviders) => {
  const { dispatch } = useStore();

  const { currentUser } = useContext(AuthContext);

  const [getDeliveryOrders, { loading, data, error }] = useLazyQueryWithLoader(
    getBuyerDeliveryOrders,
    {
      fetchPolicy: 'network-only'
    },
    false
  );

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const interval = setInterval(() => {
        getDeliveryOrders({
          variables: {
            input: {
              buyerUid: currentUser.uid,
              type: 'DELIVERY'
            }
          }
        });
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [currentUser, deliveryProvider, getDeliveryOrders]);

  useEffect(() => {
    if (data) {
      const buyerBoonsOngoingOrders: Order[] = data.getBuyerOngoingOrders;

      dispatch({
        type: 'SET_ONGOING_BOONS_DELIVERY',
        payload: buyerBoonsOngoingOrders.length
      });
    }
  }, [data, dispatch]);

  return {
    loading,
    data,
    error
  };
};
export const useGetBuyerGroceryOrdersQuery = () => {
  const [hasMore, setHasMore] = useState<boolean | null | undefined>(false);

  const [endCursor, setEndCursor] = useState<string | null | undefined>(null);

  const { data: currentUserData } = useGetLokobeeUser();

  const [orders, setOrders] = useState<Order[]>([]);

  const [loading, setLoading] = useState(true);

  const [fetching, setFetching] = useState(false);

  const pageLength = 20;

  const [getOrderData, { data, error, fetchMore }] = useLazyQueryWithLoader(getBuyerGroceryOrders, {
    variables: {
      input: {
        first: pageLength,
        after: undefined
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (currentUserData) {
      getOrderData();
    }
  }, [currentUserData, getOrderData]);

  useEffect(() => {
    if (data) {
      const { getBuyerGroceryOrders } = data;

      if (getBuyerGroceryOrders && getBuyerGroceryOrders.pageInfo) {
        const { hasNextPage, endCursor } = getBuyerGroceryOrders.pageInfo;
        setHasMore(hasNextPage);

        setEndCursor(endCursor);

        if (getBuyerGroceryOrders.edges?.length) {
          const orderEdgeArray = map(getBuyerGroceryOrders.edges, ({ node }) => node);

          const orderArray = plainToClass(Order, orderEdgeArray);

          setOrders(orderArray);
        }
        setLoading(false);
        setFetching(false);
      }
    }
  }, [data]);

  const fetchMoreRecords = () => {
    if (hasMore) {
      try {
        setFetching(true);
        fetchMore({
          variables: {
            input: {
              first: pageLength,
              after: endCursor
            }
          },
          updateQuery: (previousResult: any, { fetchMoreResult }) => {
            const previousEntry = map(previousResult.getBuyerGroceryOrders.edges, ({ node }) => node);

            const newComments = map(fetchMoreResult?.getBuyerGroceryOrders.edges, ({ node }) => node);

            const updated = [...previousEntry, ...newComments];

            return {
              getBuyerGroceryOrders: {
                edges: map(updated, (i) => {
                  return {
                    node: i,
                    __typename: 'OrderConnectionEdge'
                  };
                }),
                pageInfo: {
                  endCursor: fetchMoreResult?.getBuyerGroceryOrders.pageInfo.endCursor,
                  hasNextPage: fetchMoreResult?.getBuyerGroceryOrders.pageInfo.hasNextPage,
                  __typename: fetchMoreResult?.getBuyerGroceryOrders.pageInfo.__typename
                },
                __typename: previousResult.getBuyerGroceryOrders.__typename
              },
              __typename: previousResult.__typename
            };
          }
        });
      } catch (e) {
        setFetching(false);
        console.log(e);
      }
    }
  };

  return {
    data: orders,
    fetching,
    loading,
    error,
    fetchMoreRecords,
    hasMore
  };
};

export const useGroceryGetOrderQuery = (orderId: string | undefined | null, buyerUid: string | undefined | null) => {
  const [order, setOrder] = useState<GroceryOrderModel | null>(null);

  const { loading, data, error } = useQueryWithLoader(getGroceryOrderByOrderId, {
    variables: {
      input: {
        orderId: orderId,
        buyerUid: buyerUid
      }
    },
    skip: !buyerUid || !orderId,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      const orderObject = plainToClass(GroceryOrderModel, data.getGroceryOrderByOrderId);

      setOrder(orderObject);
    }
  }, [data, loading]);

  return {
    loading,
    order,
    error
  };
};
