import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { createOrderInput } from '../createOrderInput';
import { OrderType, PaymentType } from 'generated/types';
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import { AuthContext } from 'fbase/authContext';
import { useCreateOrder } from 'graphql/hooks';
import Big from 'big.js';
import Logger from 'util/logger';
import { convertPriceTo } from 'util/number';
import { isEmpty } from 'lodash';
import { useAlert } from 'hooks';

interface IProps {
  canMakePayment: (data: boolean) => void;
}

const PayByRequestButton: React.FC<IProps> = ({ canMakePayment }) => {
  const history = useHistory();

  const {
    state: { totalPriceAfterTax, boonsQuoteId, deliveryProvider, deliveryMode, cartSessionId }
  } = useStore();

  const { state } = useStore();

  const { createOrder } = useCreateOrder();

  const { lokobeeUser } = useContext(AuthContext);

  const [paymentRequest, setPaymentRequest] = useState<any>(null);

  // Reference to stripe object.
  const stripe = useStripe();

  const snackbar = useSnackbar();

  const { lokoAlert } = useAlert();

  type createLokobeeOrderResponse = null | { paymentKey: string; restaurantId: string; subtotalAmount: string; googleWriteReviewLink: string; yelpWriteReviewLink: string; issueRewardPoint: boolean };

  const createLokobeeOrder = useCallback(async (): Promise<createLokobeeOrderResponse> => {
    let paymentKey: string = '',
      subtotalAmount: string = '',
      restaurantId: string = '',
      googleWriteReviewLink: string = '',
      yelpWriteReviewLink: string = '';

    let issueRewardPoint: boolean | null | undefined = false;

    if (!stripe) {
      return null;
    }

    if (!lokobeeUser) {
      lokoAlert('Please login to continue.');

      return null;
    }

    let email = null;

    if (state.user && state.user.email) {
      email = state.user.email;
    }

    if (!email) {
      lokoAlert('Email does not exists');

      return null;
    }

    let orderPhoneNumber = null;

    if (state.user && state.user.orderPhoneNumber) {
      orderPhoneNumber = state.user.orderPhoneNumber;
    }

    if (!orderPhoneNumber) {
      lokoAlert('Phone number does not exists');
      return null;
    }

    try {
      if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
        const { usedPoints, tip, totalPriceAfterTax, deliveryTime, orderNote, deliveryAddress, deliveryNote, orderType, serviceFee, subtotal, discount, isDiscountApplied } = state;

        if (!totalPriceAfterTax) {
          lokoAlert('Total price cannot be null');
          return null;
        }

        let totalAmount: any = totalPriceAfterTax.toFixed(2);

        subtotalAmount =
          isDiscountApplied && discount
            ? Big(subtotal)
                .minus(discount)
                .toFixed(2)
            : Big(subtotal).toFixed(2);

        if ((state.cartItems && state.cartItems.length) || (state.cartRewardItems && state.cartRewardItems.length)) {
          restaurantId = state.cartRestaurant?.id ? state.cartRestaurant?.id : '';

          googleWriteReviewLink = state.cartRestaurant?.googleWriteReviewLink ? state.cartRestaurant?.googleWriteReviewLink : '';
          yelpWriteReviewLink = state.cartRestaurant?.yelpWriteReviewLink ? state.cartRestaurant?.yelpWriteReviewLink : '';

          issueRewardPoint = state.cartRestaurant?.issueRewardPoint ? state.cartRestaurant?.issueRewardPoint : false;

          const { tableNumber, numberOfPeople } = state;

          let type = OrderType.Takeout;

          if (orderType === 'DINING') type = OrderType.Dining;

          if (orderType === 'DELIVERY') type = OrderType.Delivery;

          const intParsedNumberOfPeople = typeof numberOfPeople === 'number' ? numberOfPeople : parseInt(numberOfPeople, 10);

          const lokobeeServiceFee = serviceFee ? serviceFee?.toFixed(2) : '0';

          const phoneOrder = state.phoneOrder;

          const input = createOrderInput({
            cartItems: state.cartItems,
            cartRewardItems: state.cartRewardItems,
            expectTime: deliveryTime,
            note: orderNote,
            tipAmount: tip.toFixed(2),
            totalAmount,
            type,
            paymentType: PaymentType.OnlineStripe,
            email,
            orderPhoneNumber,
            tableName: tableNumber,
            partySize: intParsedNumberOfPeople,
            deliveryAddress,
            deliveryNote,
            couponToken: state.couponToken,
            serviceFee: lokobeeServiceFee,
            redeemPoints: usedPoints,
            isPrediscountEnabled: state.isPrediscountEnabled,
            deliveryFee: state.deliveryCharge.gt(Big(0)) ? state.deliveryCharge.toFixed(2) : undefined,
            dropOffInstruction: state.dropOffType,
            apartmentNumber: state.apartmentNumber,
            boonsQuoteId,
            deliveryProvider,
            deliveryMode,
            doordashClassicQuoteId: state.doordashQuoteId,
            phoneOrder: phoneOrder,
            sessionId: cartSessionId
          });

          const response = await createOrder({
            variables: {
              input
            }
          });

          if (response && response.data) {
            const {
              data: {
                createOrder: { clientSecret }
              }
            } = response;

            paymentKey = clientSecret;
          } else {
            Logger.log('[ORDER ERROR]', {
              createOrderInput: input,
              createOrderResponse: response
            });
            throw new Error('Something went wrong. Please try again.');
          }
        } else {
          Logger.log('[CART ERROR]', state);
          throw new Error('Something went wrong. Please try again.');
        }
      } else {
        Logger.log('[CART ERROR]', state);
        throw new Error('Something went wrong. Please try again.');
      }
    } catch (e) {
      if ((e as any).message !== '') {
        lokoAlert('createOrder ' + (e as any).message);
      }
    }
    return { paymentKey, googleWriteReviewLink, yelpWriteReviewLink, issueRewardPoint, restaurantId, subtotalAmount };
  }, [boonsQuoteId, cartSessionId, createOrder, deliveryMode, deliveryProvider, lokoAlert, lokobeeUser, state, stripe]);

  useEffect(() => {
    if (!totalPriceAfterTax) {
      lokoAlert('Total price cannot be null');
      return;
    }

    if (stripe && totalPriceAfterTax) {
      let totalAmount: number = parseInt(convertPriceTo(totalPriceAfterTax.toFixed(2), 'CENT'));

      let pr: any = '';

      if (paymentRequest) {
        pr = paymentRequest;
      } else {
        pr = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Total',
            amount: totalAmount
          }
        });
      }

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result: any) => {
        if (result) {
          setPaymentRequest(pr);
        }
        canMakePayment(result);
      });
    }
  }, [canMakePayment, lokoAlert, paymentRequest, stripe, totalPriceAfterTax]);

  /**
   * Capturing payment
   */
  useEffect(() => {
    if (stripe && paymentRequest) {
      paymentRequest.on('paymentmethod', async (ev: any) => {
        const createOrderResponse = await createLokobeeOrder();

        if (!isEmpty(createOrderResponse) && createOrderResponse?.paymentKey) {
          const { googleWriteReviewLink, yelpWriteReviewLink, issueRewardPoint, paymentKey, restaurantId, subtotalAmount } = createOrderResponse;
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(paymentKey, { payment_method: ev.paymentMethod.id }, { handleActions: false });
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
            Logger.log(`[PAYMENT ERROR]`, {
              requires_action: false,
              stripeError: confirmError
            });

            lokoAlert(confirmError.message ? confirmError.message : 'Stripe error');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent && paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error: confirmError2 } = await stripe.confirmCardPayment(paymentKey);
              if (confirmError2) {
                // The payment failed -- ask your customer for a new payment method.
                Logger.log(`[PAYMENT ERROR]`, {
                  requires_action: true,
                  stripeError: confirmError2
                });

                lokoAlert(confirmError2.message ? confirmError2.message : 'Stripe error');
              } else {
                // The payment has succeeded.
                history.push('/success', {
                  show: true,
                  restaurantId,
                  subtotalAmount,
                  googleWriteReviewLink,
                  yelpWriteReviewLink,
                  issueRewardPoint
                });
              }
            } else {
              history.push('/success', {
                show: true,
                restaurantId,
                subtotalAmount,
                googleWriteReviewLink,
                yelpWriteReviewLink,
                issueRewardPoint
              });
            }
          }
        } else {
          //Failed due to Order Error
          ev.complete('fail');
        }
      });
    }
  }, [createLokobeeOrder, history, lokoAlert, paymentRequest, snackbar, stripe]);

  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'buy'
                // One of 'default', 'book', 'buy', or 'donate'
                // Defaults to 'default'
              }
            }
          }}
        />
      </>
    );
  }

  return <></>;
};

export default PayByRequestButton;
