import React, { useState, useContext } from 'react';
import { Box, Button, Typography, TextField, InputAdornment, IconButton, Grid } from '@material-ui/core';
import Loading from './Loading';
import 'react-phone-number-input/style.css';
import { FirebaseContext } from 'fbase';
import { loginValidation } from './validations';
import { useFormik, FormikHelpers, getIn } from 'formik';
import { ILoginFormikInitialState } from './types';
import { useStore } from 'store';
import { useStyles } from './style';
import GoogleSignin from './GoogleSignIn';
import logo_v from 'assets/img/lokobee-logo_V.png';
import money_bag from 'assets/img/money-bag.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import amber from '@material-ui/core/colors/amber';
import { AuthContext } from 'fbase/authContext';
import coin_img from 'assets/img/coin_img.png';
import { useAlert } from 'hooks';
import { useHistory } from 'react-router-dom';
import ContactDetailsFormForGuest from '../ContactDetailsFormForGuest';
import ContactDetailsFormForLokobeeUser from '../ContactDetailsFormForLokobeeUser';
import { useQueryWithLoader } from 'hooks/loader';
import { getLokobeeUserQuery } from 'graphql/query';
import { useApolloClient } from '@apollo/react-hooks';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const Login: React.FC<IProps> = ({ setStep }) => {
  const { firebase } = useContext(FirebaseContext);
  const { currentUser } = useContext(AuthContext);
  const auth = firebase!.getAuth();

  const { lokoAlert } = useAlert();
  const classes = useStyles();

  const { state } = useStore();

  const [{ done }, setProgress] = useState({ loading: false, done: false });

  const { dispatch } = useStore();

  const [showPassword, setShowPassword] = useState(false);

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const history = useHistory();

  const [openContactDetailsForm, setOpenContactDetailsForm] = useState(false);

  const [openGuestContactDetailsForm, setOpenGuestContactDetailsForm] = useState(false);

  const client = useApolloClient();

  const { refetch } = useQueryWithLoader(getLokobeeUserQuery, {
    skip: true,
    fetchPolicy: 'network-only'
  });

  const handleClickShowPassword = () => {
    let tempShowPassword = showPassword;
    setShowPassword(!tempShowPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const onClose = () => {
    closeLoginPopup();
  };

  const onLoginSuccess = () => {
    client.resetStore();
    if (state.ui.onLoginRoute) {
      closeLoginPopup();
      history.push(state.ui.onLoginRoute);
    } else {
      closeLoginPopup();
    }

    dispatch({
      type: 'UI_ACTION_UPDATED_TERMS_POPUP',
      payload: true
    });
    dispatch({
      type: 'RESET_ONGOING_ORDERS'
    });
  };

  const onGuestLoginSuccess = () => {
    client.resetStore();
    if (state.ui.onGuestLoginRoute) {
      closeLoginPopup();
      history.push(state.ui.onGuestLoginRoute);
    } else {
      closeLoginPopup();
    }
  };

  const closeLoginPopup = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: {
        openLoginPopup: false,
        onGuestLoginRoute: undefined,
        onLoginRoute: undefined,
        showContactFormForGuest: false
      }
    });
  };

  /**
   *
   *
   * @param values - Form values
   * @param actions - Formik Helpers
   */
  const onSubmit = async (values: ILoginFormikInitialState, actions: FormikHelpers<ILoginFormikInitialState>) => {
    actions.setSubmitting(true);

    setProgress((prev) => ({ ...prev, loading: true }));

    const { email, password } = values;

    try {
      /**
       * If email already exists link it with credentials and update user profile.
       */
      let persistence = firebase?.getAuth().Auth.Persistence.LOCAL || 'LOCAL';
      await auth().setPersistence(persistence);
      const loggedInUSer = await auth().signInWithEmailAndPassword(email, password);

      actions.setSubmitting(false);

      const token = await loggedInUSer.user?.getIdToken();

      if (token) {
        try {
          const response = await refetch({
            context: {
              headers: {
                Authorization: token
              }
            }
          });

          if (response && response.data) {
            const { data } = response;

            if (data && data.getLokobeeUser) {
              const { orderPhoneNumber, email } = data.getLokobeeUser;

              if (!orderPhoneNumber || !email) {
                if (state.ui.showContactFormForLokobeeUser) {
                  setOpenContactDetailsForm(true);
                } else {
                  onLoginSuccess();
                }
              } else {
                onLoginSuccess();
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      let message = '';

      if (e.code) {
        switch (e.code) {
          case 'auth/user-disabled':
            message = 'User has been disabled';
            break;
          case 'auth/user-not-found':
            message = 'User not found. Please try again';
            break;
          case 'auth/wrong-password':
            message = 'Wrong Password';
            break;
          case 'auth/email-already-in-use':
            message = 'Email already exists';
            break;
          case 'auth/invalid-email':
            message = 'Invalid email';
            break;
          default:
            message = e.message;
        }

        actions.setSubmitting(false);
        lokoAlert(message);
      }
    }
  };

  const formik = useFormik<ILoginFormikInitialState>({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnMount: true,
    validationSchema: loginValidation,
    onSubmit
  });

  const isSubmitButtonDisabled = !formik.isValid;

  const guestLogin = async () => {
    try {
      if (!currentUser) {
        setIsLoggingIn(true);
        let persistence = firebase?.getAuth().Auth.Persistence.NONE || 'NONE';
        await auth().setPersistence(persistence);
        await auth().signInAnonymously();

        if (state.ui.showContactFormForGuest) {
          setOpenGuestContactDetailsForm(true);
        } else {
          onGuestLoginSuccess();
        }
        setIsLoggingIn(false);
      } else {
        closeLoginPopup();
      }
    } catch (e) {
      setIsLoggingIn(false);
      console.log(e);
    }
  };

  const handleContactDetailsFormClose = (submitted: boolean) => {
    setOpenContactDetailsForm(false);
    if (submitted) {
      onLoginSuccess();
    }
  };

  const handleGuestContactDetailsFormClose = (submitted: boolean) => {
    setOpenGuestContactDetailsForm(false);
    closeLoginPopup();
    if (submitted) {
      onGuestLoginSuccess();
    }
  };

  return (
    <>
      <Loading open={formik.isSubmitting} done={done} />
      <ArrowBackIcon className={classes.backArrow} onClick={onClose} />

      <Box bgcolor={amber[100]} paddingY={5} display="flex" flexDirection={'column'} alignItems="center">
        <Typography variant="h6">
          <Box display="flex" marginBottom={1} alignItems="center">
            <img alt="" src={logo_v} style={{ borderRadius: 5 }} height="40px" width="40px" /> &nbsp; Lokobee
          </Box>
        </Typography>

        <Box display="flex" marginBottom={4} flexDirection={'column'} alignItems="center">
          <Typography variant="body1" align="center">
            Save
            <span>
              <img src={money_bag} alt="money" height="20px" width="20px" />
            </span>{' '}
            for you and the restaurant
          </Typography>
          <Typography variant="body1" align="center">
            Quick checkout, save time
          </Typography>
        </Box>

        <Box className={classes.btnContainer}>
          <Button color="secondary" variant="contained" size="medium" disabled={isLoggingIn} fullWidth className={classes.guestBtn} onClick={guestLogin}>
            Continue as guest
          </Button>
        </Box>
      </Box>
      <Box bgcolor={amber[50]} paddingY={3} display="flex" flexDirection={'column'} alignItems="center">
        <GoogleSignin />
        <Box marginTop={2} display="flex" alignItems="center">
          <Typography variant="subtitle2" align="center">
            Earn Reward Points
          </Typography>
          &nbsp; <img src={coin_img} alt="money" height="18" /> <img src={coin_img} alt="money" height="18px" />
        </Box>
      </Box>
      <Box display="flex" flexDirection={'column'} paddingY={3} alignItems="center">
        <form className={classes.formWrapper} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={6} lg={6}>
              <TextField
                className={classes.textField}
                name="email"
                type="email"
                label="Email address"
                fullWidth
                placeholder="Email address"
                value={formik.values.email}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  formik.setFieldValue('email', e.target.value.trim());
                }}
                onFocus={formik.handleBlur}
                onBlur={formik.handleBlur}
                error={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email')}
                helperText={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email') ? getIn(formik.errors, 'email') : ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6} lg={6}>
              <TextField
                className={classes.textField}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                label="Password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={!!getIn(formik.touched, 'password') && !!getIn(formik.errors, 'password')}
                helperText={!!getIn(formik.touched, 'password') && !!getIn(formik.errors, 'password') ? getIn(formik.errors, 'password') : ''}
                onBlur={formik.handleBlur}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Box display="flex" marginBottom={2}>
            <Button variant="text" className={classes.guestBtn} onClick={() => setStep(3)}>
              Forgot password?
            </Button>
          </Box>
          <Box marginBottom={2} display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.btnContainer}>
              <Button type="submit" variant="contained" fullWidth className={classes.guestBtn} color="primary" size="medium" disabled={isSubmitButtonDisabled}>
                Sign in with Lokobee
              </Button>
            </Box>
          </Box>

          <Box>
            <Typography onClick={() => setStep(2)} color="secondary" style={{ cursor: 'pointer' }} variant="body1" align="center">
              Quick Sign up with just email & password
            </Typography>

            <Box marginTop={1} display="flex" justifyContent={'center'} alignItems="center">
              <Typography variant="subtitle2" align="center">
                Earn Reward Points
              </Typography>
              &nbsp; <img src={coin_img} alt="money" height="18" /> <img src={coin_img} alt="money" height="18px" />
            </Box>
          </Box>
        </form>
      </Box>
      {openGuestContactDetailsForm && <ContactDetailsFormForGuest open={openGuestContactDetailsForm} onHandleClose={handleGuestContactDetailsFormClose} />}
      {openContactDetailsForm && <ContactDetailsFormForLokobeeUser open={openContactDetailsForm} onHandleClose={handleContactDetailsFormClose} />}
    </>
  );
};

export default Login;
