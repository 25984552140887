import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, ButtonGroup } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { DeliveryProviders, PaymentType } from 'generated/types';
import AmericanCardIcon from 'assets/img/american_express_icon.svg';
import ApplePayIcon from 'assets/img/apple_pay.svg';
import DiscoverIcon from 'assets/img/discover_icon.svg';
import MasterCardIcon from 'assets/img/mastercard.svg';
import GooglePayIcon from 'assets/img/google_pay_icon.png';
import CashIcon from 'assets/img/cash_icon.svg';
import VisaIcon from 'assets/img/visa.svg';
import { map } from 'lodash';
import CheckoutSummary from './CheckoutSummary';
import { grey } from '@material-ui/core/colors';
import PayByStripeCheckout from './PayByStripeCheckout';
import PayByCash from './PayByCash';

const Payment: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState('PAYMENT_METHOD_CARD');

  const {
    state: { cartRestaurant, orderType, totalPriceAfterTax, deliveryProvider, deliveryAddress, deliveryTime, numberOfPeople, tableNumber }
  } = useStore();

  const history = useHistory();

  const [onsiteAvailablePaymentTypeIcons, setOnsiteAvailablePaymentTypeIcons] = useState<PaymentType[] | null>([]);

  const [onlineAvailablePaymentTypeIcons, setOnlineAvailablePaymentTypeIcons] = useState<PaymentType[] | null>([]);

  const isOnlineAllowed = orderType === 'TAKE_OUT' || orderType === 'DINING' || (orderType === 'DELIVERY' && deliveryProvider === DeliveryProviders.Self);

  // On press of back button
  const onBack = () => {
    /**
     * We need to send state in location so that cart can go back. Not a proper solution.
     *
     */
    const restaurantId = cartRestaurant?.id;

    // Needs to change later as history goBack is not an appropriate way to handle back navigation.
    history.replace('/cart/select-type', {
      from: `/restaurants/${restaurantId}`
    });
  };

  useEffect(() => {
    if (orderType === 'TAKE_OUT') {
      if (cartRestaurant?.takeoutPaymentSetting?.onsitePaymentTypes?.length) {
        setOnsiteAvailablePaymentTypeIcons(cartRestaurant?.takeoutPaymentSetting?.onsitePaymentTypes);
      }

      if (cartRestaurant?.takeoutPaymentSetting?.onlinePaymentTypes?.length) {
        setOnlineAvailablePaymentTypeIcons(cartRestaurant?.takeoutPaymentSetting?.onlinePaymentTypes);
      }

      if (cartRestaurant?.takeoutPaymentSetting?.onsitePaymentTypes?.length && !cartRestaurant?.takeoutPaymentSetting?.onlinePaymentTypes?.length) {
        setSelectedValue('PAYMENT_METHOD_CASH');
      }
    }

    if (orderType === 'DINING') {
      if (cartRestaurant?.diningPaymentSetting?.onsitePaymentTypes?.length) {
        setOnsiteAvailablePaymentTypeIcons(cartRestaurant?.diningPaymentSetting?.onsitePaymentTypes);
      }

      if (cartRestaurant?.diningPaymentSetting?.onlinePaymentTypes?.length) {
        setOnlineAvailablePaymentTypeIcons(cartRestaurant?.diningPaymentSetting?.onlinePaymentTypes);
      }

      if (cartRestaurant?.diningPaymentSetting?.onsitePaymentTypes?.length && !cartRestaurant?.diningPaymentSetting?.onlinePaymentTypes?.length) {
        setSelectedValue('PAYMENT_METHOD_CASH');
      }
    }
    if (orderType === 'DELIVERY') {
      if (cartRestaurant?.deliveryPaymentSetting?.onsitePaymentTypes?.length) {
        setOnsiteAvailablePaymentTypeIcons(cartRestaurant?.deliveryPaymentSetting?.onsitePaymentTypes);
      }

      if (cartRestaurant?.deliveryPaymentSetting?.onlinePaymentTypes?.length) {
        setOnlineAvailablePaymentTypeIcons(cartRestaurant?.deliveryPaymentSetting?.onlinePaymentTypes);
      }

      if (cartRestaurant?.deliveryPaymentSetting?.onsitePaymentTypes?.length && !cartRestaurant?.deliveryPaymentSetting?.onlinePaymentTypes?.length) {
        setSelectedValue('PAYMENT_METHOD_CASH');
      }
    }
  }, [cartRestaurant, orderType]);

  const images = map(onsiteAvailablePaymentTypeIcons, (image, index) => {
    let src = null;

    switch (image) {
      case PaymentType.OnsiteCash:
        src = CashIcon;
        break;
      case PaymentType.OnsiteApplePay:
        src = ApplePayIcon;
        break;
      case PaymentType.OnsiteDiscoverCard:
        src = DiscoverIcon;
        break;
      case PaymentType.OnsiteMasterCard:
        src = MasterCardIcon;
        break;
      case PaymentType.OnsiteAmericanExpressCard:
        src = AmericanCardIcon;
        break;
      case PaymentType.OnsiteVisaCard:
        src = VisaIcon;
        break;
      case PaymentType.OnsiteGooglePay:
        src = GooglePayIcon;
        break;
      default:
        src = null;
    }

    if (src) {
      return <img style={{ marginRight: 10 }} width="30" key={index} src={src} alt="Payment Icon" />;
    }
  });
  const isOnlyPaymentOption = () => {
    if (!!onlineAvailablePaymentTypeIcons && !!onlineAvailablePaymentTypeIcons.length && !(!!onsiteAvailablePaymentTypeIcons && !!onsiteAvailablePaymentTypeIcons.length && isOnlineAllowed)) {
      return 'CARD_PAYMENT_ONLY';
    } else if (!(!!onlineAvailablePaymentTypeIcons && !!onlineAvailablePaymentTypeIcons.length) && !!onsiteAvailablePaymentTypeIcons && !!onsiteAvailablePaymentTypeIcons.length && isOnlineAllowed) {
      return 'ONSITE_PAYMENT_ONLY';
    } else {
      return null;
    }
  };
  return (
    <>
      <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" justifyContent="center" height="100%" bgcolor="#fff">
        <DialogHeader onBack={onBack} text={!isOnlyPaymentOption() ? 'Select payment method' : isOnlyPaymentOption() === 'CARD_PAYMENT_ONLY' ? 'Pay online' : 'Pay at Restaurant'} />
        <Box paddingY={1} flex={1} overflow="scroll" display="flex" flexDirection="column">
          <Box paddingX={1} marginBottom={2}>
            {!isOnlyPaymentOption() && (
              <ButtonGroup fullWidth={true} color="secondary">
                {!!onlineAvailablePaymentTypeIcons && !!onlineAvailablePaymentTypeIcons.length && (
                  <Button size="small" disableElevation={true} variant={selectedValue === 'PAYMENT_METHOD_CARD' ? 'contained' : 'outlined'} onClick={() => setSelectedValue('PAYMENT_METHOD_CARD')}>
                    Pay Online
                  </Button>
                )}
                {!!onsiteAvailablePaymentTypeIcons && !!onsiteAvailablePaymentTypeIcons.length && isOnlineAllowed && (
                  <Button size="small" disableElevation={true} variant={selectedValue === 'PAYMENT_METHOD_CASH' ? 'contained' : 'outlined'} onClick={() => setSelectedValue('PAYMENT_METHOD_CASH')}>
                    Pay at Restaurant
                  </Button>
                )}
              </ButtonGroup>
            )}
          </Box>
          <Box paddingY={2} marginX={1} marginBottom={2} bgcolor={grey[200]} borderRadius={5} textAlign="center" fontWeight="bold">
            <Typography variant="body1">PAY AMOUNT:&nbsp;${totalPriceAfterTax?.toFixed(2)}</Typography>
          </Box>
          {selectedValue === 'PAYMENT_METHOD_CASH' && (
            <>
              <Box textAlign="center" paddingTop={2}>
                <Typography variant="caption">WE ACCEPT FOLLOWING PAYMENT METHODS</Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {images}
                </Box>
              </Box>
              <Box padding={1}>
                {/* <ContactDetails /> */}
                <CheckoutSummary
                  restaurantName={cartRestaurant?.getName}
                  restaurantAddress={cartRestaurant?.getAddress}
                  orderType={orderType}
                  partySize={numberOfPeople}
                  tableNumber={tableNumber}
                  deliveryAddress={deliveryAddress}
                  eta={deliveryTime}
                />
              </Box>
            </>
          )}
          {selectedValue === 'PAYMENT_METHOD_CARD' && <PayByStripeCheckout />}
        </Box>
        {selectedValue === 'PAYMENT_METHOD_CASH' && <PayByCash />}
      </Box>
    </>
  );
};

export default Payment;
