import React, { useState } from 'react';
import { Box, DialogContent, useMediaQuery } from '@material-ui/core';
import { includes } from 'lodash';
import { useGetDishQuery, useGetRestaurantById } from 'graphql/hooks';
import { useStyles } from './style';
import { DishTag, DishType } from 'generated/types';
import { useActiveRestaurant } from 'hooks/restaurant';
import DishTypeRegular from './DishTypeRegular';
import DishTypePizza from './DishTypePizza';
import { grey } from '@material-ui/core/colors';
import DishHeader from './DishHeader';
import { useSwipeable } from 'react-swipeable';
interface IProps {
  id: string | null;
  handleClose: () => void;
  currency?: 'USD' | 'POINT';
}
interface SwipeableHandlers {
  ref(element: HTMLElement | null): void;
  onMouseDown?(event: React.MouseEvent): void;
}
const Content: React.FC<IProps> = ({ id, handleClose, currency = 'USD' }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const { restaurantId } = useActiveRestaurant();

  const { restaurant } = useGetRestaurantById(restaurantId, 'ALL');

  const { dish } = useGetDishQuery(id);

  const { images } = dish || { images: null };

  const { md } = (images && images[0]) || { md: { url: null } };
  const { preview } = (images && images[0]) || { preview: { url: null } };
  const imgSrc = isDesktop ? (md && md.url ? md.url : null) : preview && preview.url ? preview.url : null;

  const [isHeaderExpanded, setIsHeaderExpanded] = useState(true);

  const hideImg = () => {
    setIsHeaderExpanded(false);
  };

  const showImg = () => {
    setIsHeaderExpanded(true);
  };

  /**
   * Renders Dish Extra Group
   */
  const swipeHandlers: SwipeableHandlers = useSwipeable({
    onSwipedUp: (eventData) => {
      if (isHeaderExpanded) {
        hideImg();
      }
    },
    onSwipedDown: (eventData) => {
      if (!isHeaderExpanded) {
        showImg();
      }
    }
  });
  const renderContent = () => {
    if (dish) {
      const dishTitle = dish.getTitle;

      const dishDescription = dish.getDescription;

      const rateScoreValue = dish.rateScore;

      const rateCountValue = dish.rateCount;

      const popular: DishTag = DishTag.Popular;

      const isPopular = includes(dish.getTags, popular);

      const isAlcohol = dish.isAlcohol;

      const content = () => {
        if (dish.type === DishType.Pizza) {
          return <DishTypePizza dish={dish} isHeaderExpanded={isHeaderExpanded} hideImg={hideImg} showImg={showImg} restaurant={restaurant} handleClose={handleClose} />;
        }

        if (dish.type === DishType.Regular) {
          return <DishTypeRegular dish={dish} isHeaderExpanded={isHeaderExpanded} hideImg={hideImg} showImg={showImg} restaurant={restaurant} handleClose={handleClose} currency={currency} />;
        }
      };

      return (
        <DialogContent {...swipeHandlers} className={classes.dialogContent} id={'diaglogWrapper'}>
          <DishHeader
            dishTitle={dishTitle}
            dishDescription={dishDescription}
            handleClose={handleClose}
            imgSrc={imgSrc}
            isHeaderExpanded={isHeaderExpanded}
            showImg={showImg}
            hideImg={hideImg}
            isPopular={isPopular}
            rateScoreValue={rateScoreValue}
            isAlcohol={isAlcohol}
            rateCountValue={rateCountValue}
          />
          <Box className={classes.dishContainer} paddingY={1} border={imgSrc ? `solid 1px ${grey[100]}` : ''} borderRadius={imgSrc ? '15px 15px 0 0' : 0} boxShadow={imgSrc ? 1 : 0}>
            {content()}
          </Box>
        </DialogContent>
      );
    }

    return null;
  };

  return renderContent();
};

export default Content;
