import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Box, Button, Hidden } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartSharp';
import { useStore } from 'store';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import { useGetRestaurantById } from 'graphql/hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import logo from 'assets/img/icon-1024.png';
import S3Image from 'components/S3Image';
import { useStyles } from './style';
import UserLocation from './UserLocation';
import { useUserLogout } from 'hooks';
import restaurant_empty_icon from 'assets/img/restaurant-empty-icon.png';
import BizHoursStatus from './BizHoursStatus';

const Header = () => {
  const classes = useStyles();

  const history = useHistory();

  const { currentUser } = useContext(AuthContext);

  const { pathname } = useLocation();

  const { restaurantId } = useActiveRestaurant();

  const { restaurant } = useGetRestaurantById(restaurantId, 'PROFILE');

  const restaurantLogo = restaurant?.getLogo || null;

  const restaurantName = restaurant?.getName;

  const restaurantSubname = restaurant?.getSubName;

  const { logOut } = useUserLogout();

  const isAnonymousUser = currentUser?.isAnonymous;
  const match = useRouteMatch({
    path: '/',
    strict: true,
    sensitive: true
  });

  const shouldShowLocationComponent = (!!match && match.isExact) || false;

  const {
    state: { cartItems, cartRewardItems },
    dispatch
  } = useStore();

  const openLoginDialog = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  const toggleCartDisplay = () => {
    if (cartItems.length || cartRewardItems.length) {
      history.push('/cart', {
        from: {
          pathname
        }
      });
    }
  };

  const signOut = async () => {
    logOut();
  };

  let totalItemInCart = 0;

  for (const item of cartItems) {
    totalItemInCart += item.quantity;
  }

  for (const item of cartRewardItems) {
    totalItemInCart += item.quantity;
  }

  return (
    <Box className={classes.root} id="header">
      <AppBar position="sticky" className={classes.appBar}>
        {!shouldShowLocationComponent && restaurantId && <BizHoursStatus restaurantId={restaurantId} />}
        <Toolbar disableGutters={true} className={classes.toolbar}>
          {shouldShowLocationComponent && (
            <Box component="span">
              <UserLocation />
            </Box>
          )}

          {restaurantLogo && (
            <Box className={classes.restaurantLogoBox} overflow="hidden">
              <S3Image src={restaurantLogo} defaultImage={restaurant_empty_icon} />
            </Box>
          )}

          <Box textAlign="left" className={classes.titleContainer} flexGrow={1}>
            <Typography variant="h6" className={classes.title}>
              {restaurantName}
            </Typography>
            <Typography variant="caption" className={classes.title}>
              {restaurantSubname}
            </Typography>
          </Box>
          <Hidden xsDown={true}>
            <Box onClick={() => history.push('/')}>
              <img className={classes.logo} src={logo} alt="LOKOBEE" />
            </Box>
            <Button className={classes.headerBtn} onClick={() => history.push('/myaccount')}>
              {currentUser && !isAnonymousUser ? 'My Account' : 'About'}
            </Button>
            {currentUser && !isAnonymousUser && (
              <Button className={classes.headerBtn} onClick={() => history.push('/orders')}>
                My orders
              </Button>
            )}
            {/* <Box paddingLeft={2}>
              <Badge
                badgeContent={'New'}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                color="error">
                {' '}
                <Button className={classes.headerBtn} onClick={() => history.push('/wallet')}>
                  Wallet{' '}
                </Button>
              </Badge>
            </Box> */}
            {!currentUser || isAnonymousUser ? (
              <Button className={classes.headerBtn} onClick={openLoginDialog}>
                Sign In
              </Button>
            ) : (
              <Button className={classes.headerBtn} onClick={signOut}>
                Sign out
              </Button>
            )}
          </Hidden>
          <Box className={classes.boxCart} onClick={toggleCartDisplay}>
            <ShoppingCartIcon />
            {totalItemInCart > 0 && (
              <Box m={0.5} pb={0.5}>
                <Typography> {totalItemInCart}</Typography>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
