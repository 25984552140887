import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000'
    }
  },
  circularProgress: {
    width: 20,
    height: 20
  }
}));
