import React, { useContext, useState, useEffect } from 'react';
import { Typography, Box, TextField, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './style';
import validations from './validations';
import { useHistory } from 'react-router-dom';
import { pickBy } from 'lodash';
import { useFormik, FormikHelpers } from 'formik';
import { AuthContext } from 'fbase/authContext';
import { useUserFeedbackMutation } from 'graphql/hooks';
import DialogHeader from 'components/DialogHeader';
import RatingWidget from 'components/RatingWidget';
import { useAlert } from 'hooks';

interface IUserData {
  message: string;
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
}

const initialValues: IUserData = {
  message: '',
  name: '',
  email: '',
  phoneNumber: ''
};

const Feedback: React.FC = () => {
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);

  const { currentUser } = useContext(AuthContext);

  const classes = useStyles();

  const { lokoAlert } = useAlert();

  const history = useHistory();

  const snackbar = useSnackbar();

  const [tempLoading, setTempLoading] = useState(false);

  const [rating, setRating] = useState(0);

  const { sendFeedback } = useUserFeedbackMutation();

  const setRatingCallback = (rate: number) => {
    setRating(rate);
  };

  useEffect(() => {
    setFormikInitialValues({
      message: '',
      name: currentUser ? currentUser.displayName : '',
      email: currentUser ? currentUser.email : '',
      phoneNumber: currentUser ? currentUser.phoneNumber : ''
    });
  }, [currentUser]);

  useEffect(() => {
    setTimeout(() => {
      setTempLoading(true);
    }, 2000);
  }, [setTempLoading]);

  /**
   * Form onSubmit initiated by Formik
   * @param values
   * @param FormikHelpers
   */
  const onSubmit = async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
    setSubmitting(true);

    const formValues = pickBy(values);

    const data = {
      ...formValues,
      rating: rating
    };

    const response = await sendFeedback({
      variables: {
        input: data
      }
    });

    if (response) {
      resetForm();
      setSubmitting(false);
      setRating(0);
      snackbar.enqueueSnackbar('Thank you so much for your valuable feedback.', { variant: 'success' });
      history.push('/');
    } else {
      setSubmitting(false);
      lokoAlert('Something went wrong please try again');
    }
  };

  const formik = useFormik<IUserData>({
    initialValues: formikInitialValues,
    validationSchema: validations,
    enableReinitialize: true,
    onSubmit
  });

  const isSubmitDisabled = !rating || !formik.isValid || formik.isSubmitting;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <DialogHeader onBack={() => history.goBack()} text="Feedback" />
      <Box display="flex" flexDirection="column" className={classes.content} height="100%" padding={2}>
        <Box textAlign="center" paddingY={2}>
          <Typography variant="subtitle1">How would you describe your experience?</Typography>
          <Box paddingY={2}>
            <RatingWidget value={rating} onChange={setRatingCallback} />
          </Box>
        </Box>
        <Box textAlign="center" paddingY={2}>
          <Typography variant="subtitle1">Is there anything else you would like to share about our product and customer experience?</Typography>
        </Box>
        <Box paddingY={1}>
          <TextField
            error={formik.touched.message && formik.errors.message ? true : false}
            helperText={formik.touched.message && formik.errors.message ? formik.errors.message : ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message ? formik.values.message : ''}
            label="Type your message"
            name="message"
            variant="outlined"
            rows={5}
            fullWidth={true}
            multiline={true}
          />
        </Box>

        {!currentUser && tempLoading && (
          <>
            <Box paddingY={1}>
              <TextField
                error={formik.touched.name && formik.errors.name ? true : false}
                helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name ? formik.values.name : ''}
                label="Your name"
                name="name"
                variant="outlined"
                fullWidth={true}
              />
            </Box>
            <Box width="100%" paddingY={1}>
              <TextField
                error={formik.touched.email && formik.errors.email ? true : false}
                helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email ? formik.values.email : ''}
                label="Your e-mail address"
                name="email"
                variant="outlined"
                fullWidth={true}
              />
            </Box>
            <Box width="100%" paddingY={1}>
              <TextField
                error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber ? formik.values.phoneNumber : ''}
                label="Your phone number"
                name="phoneNumber"
                variant="outlined"
                fullWidth={true}
              />
            </Box>
          </>
        )}
        <Box flex={1}></Box>
        <Box>
          <Button onClick={formik.submitForm} variant="contained" color="primary" fullWidth={true} disabled={isSubmitDisabled}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Feedback;
