import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '80%'
  },
  boldText: {
    fontWeight: 'bold'
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  phoneInputField: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    border: `1px solid ${grey[300]}`,
    borderRadius: 20,
    '& input': {
      border: 'none',
      fontFamily: "'Itim'",
      '&::placeholder': {
        color: grey[500],
        fontWeight: '200'
      }
    }
  },
  textWithStike: {
    margin: theme.spacing(2, 0),
    overflow: 'hidden',
    textAlign: 'center',
    color: '#000',
    '&:after, &:before': {
      backgroundColor: '#000',
      content: "''",
      display: 'inline-block',
      height: '1px',
      position: 'relative',
      verticalAlign: 'middle',
      width: '50%'
    },
    '&:before': {
      right: '0.5em',
      marginLeft: '-50%'
    },
    '&:after': {
      left: '0.5em',
      marginRight: '-50%'
    }
  },
  divider: {
    backgroundColor: '#000',

    height: '1px',
    margin: theme.spacing(3, 0)
  },
  logoImg: {
    boxShadow: theme.shadows[3],
    borderRadius: 5
  },
  logoBtn: {
    paddingLeft: '8px',
    paddingTop: '2px',
    paddingBottom: '2px'
  },

  backArrow: {
    position: 'absolute',
    top: 8,
    left: 8,
    cursor: 'pointer'
  },
  guestBtn: {
    // padding: theme.spacing(0.5, 4),
    textTransform: 'initial'
  },
  btnContainer: {
    maxWidth: '240px',
    width: '100%'
  }
}));
