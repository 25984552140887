import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#000',
    padding: theme.spacing(0.5),
    height: 'max-content',
    borderRadius: theme.spacing(0.5),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      margin: 0,
      left: 0,
      right: 0,
      zIndex: 20
    }
  },
  volumeIcon:{
    paddingLeft: theme.spacing(1),
  },
}));
