import React, { useState, useCallback, useRef } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Box, Typography, TextField } from '@material-ui/core';
import { useStyles } from './style';
import ClearIcon from '@material-ui/icons/Clear';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import { filter } from 'lodash';
import geoDistance from 'util/geoDistance';
import { useAlert } from 'hooks';
import { useStore } from 'store';

// const getApiKey = (): string => {
//   const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
//   if (apiKey) {
//     return apiKey;
//   }

//   throw new Error('GOOGLE API KEY NOT DEFINED');
// };

const InputField = ({
  defaultValue,
  validationError = false,
  errorMsg = '',
  onAddressSelect,
  onChange,
  onAddressClear,
  onBlur,
  restaurantLatitude = 37.543862,
  restaurantLongitude = -121.986923,
  checkRange = true,
  deliveryRadius = 0,
  ...props
}: any) => {
  const classes = useStyles();

  const [addressValue, setAddressValue] = useState('');

  const inputEl = useRef<any>(null);

  const { lokoAlert } = useAlert();

  const {
    state: { deliveryProvider }
  } = useStore();

  React.useEffect(() => {
    if (defaultValue) {
      setAddressValue(defaultValue);
    }
  }, [defaultValue]);

  const onInputChange = useCallback(
    (val: string) => {
      onChange(val);
      setAddressValue(val);
    },
    [onChange]
  );

  const onSelect = async (val: string, placeId: string) => {
    const results = await geocodeByAddress(val);

    if (results && results.length) {
      const { address_components, formatted_address } = results[0];
      const { long_name: country } = filter(address_components, (obj: any) => obj.types[0] === 'country')[0] || {};
      const { long_name: city } = filter(address_components, (obj: any) => obj.types[0] === 'locality')[0] || {};
      const { long_name: state } = filter(address_components, (obj: any) => obj.types[0] === 'administrative_area_level_1')[0] || {};
      const { long_name: postalCode } = filter(address_components, (obj: any) => obj.types[0] === 'postal_code')[0] || {};
      const { long_name: street_number } = filter(address_components, (obj: any) => obj.types[0] === 'street_number')[0] || {};
      const { long_name: route } = filter(address_components, (obj: any) => obj.types[0] === 'route')[0] || {};

      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();

      if (deliveryProvider === 'SELF') {
        const miles = geoDistance(restaurantLatitude, restaurantLongitude, lat, lng, 'M');

        if (miles > deliveryRadius) {
          onClear();
          lokoAlert("Selected address is out of the restaurant's delivery range");
        }
      }

      setAddressValue(formatted_address);

      onAddressSelect({
        text: formatted_address,
        lat,
        lng,
        country,
        city,
        state,
        zipcode: postalCode,
        street: street_number && route ? street_number + ' ' + route : ''
      });
    }
  };

  const onClear = useCallback(() => {
    onInputChange('');
    onAddressClear();

    inputEl?.current?.focus();
  }, [onAddressClear, onInputChange]);

  return (
    <PlacesAutocomplete value={addressValue} onChange={onInputChange} onSelect={onSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box position="relative">
          <TextField
            {...getInputProps()}
            value={addressValue}
            error={validationError}
            helperText={validationError ? errorMsg : ''}
            fullWidth={true}
            InputProps={{
              startAdornment: <LocationOnIcon className={classes.locationIcon} />,
              endAdornment: loading ? <CircularProgress color="secondary" size={20} /> : addressValue !== '' ? <ClearIcon onClick={onClear} className={classes.clearIcon} /> : undefined
            }}
            placeholder="Delivery Address"
            onBlur={(e) => {
              getInputProps().onBlur(e);
              if (onBlur) {
                onBlur(e);
              }
            }}
            {...props}
            inputRef={inputEl}
          />
          <Box className={classes.suggestionContainer} boxShadow={1}>
            {suggestions.map((suggestion, index) => (
              <Box {...getSuggestionItemProps(suggestion)} padding={1} display="flex" className={classes.suggestionBox} key={index}>
                <LocationOnIcon className={classes.locationIcon} />
                <Typography variant="body2">{suggestion.description || ''}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
};

export default InputField;
