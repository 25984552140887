import Moment from 'moment';

export const Date = {
  convertDateToMeridienFormat: (hour: number, minutes: number) => {
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? ' AM' : ' PM';
    return h + ':' + minutes.toString() + ampm;
  }
};

export const getCurrentDate = () => {
  return Moment();
};

export const getMaximumDeliveryDate = () => {
  return Moment().add(3, 'w');
};

export const getTimestamp = (date: Date) => {
  return Moment(date).valueOf();
};

export const getFormattedDateString = (date: Date | string | number) => {
  try {
    if (typeof date === 'string') {
      return Moment(parseInt(date, 10)).format('MMMM Do YYYY, [at] h:mm a');
    }
    return Moment(date).format('MMMM Do YYYY, [at] h:mm a');
  } catch (e) {
    return null;
  }
};

export const isFutureDate = (date: number) => {
  const now = Moment();

  const _deliveryTime = Moment(date);

  const todayOrder = now.date() === _deliveryTime.date() && now.month() === _deliveryTime.month() && now.year() === _deliveryTime.year();

  if (!todayOrder) {
    return true;
  } else {
    return false;
  }
}
