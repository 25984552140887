import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ChangeDisplayName from './ChangeDisplayName';
import RouteList from './RouteList';
import ChangePassword from './ChangePassword';
import { useProfileRoles } from 'hooks';

const Profile: React.FC = () => {
  const { isPhoneLogin } = useProfileRoles();

  return (
    <Switch>
      {isPhoneLogin && <Route path="/profile/update-password" component={ChangePassword} />}
      <Route path="/profile/update-name" component={ChangeDisplayName} />
      <Route path="/profile" component={RouteList} />
    </Switch>
  );
};

export default Profile;
