import React from 'react';
import { chain, includes } from 'lodash';
import { Box, useMediaQuery, Typography } from '@material-ui/core';
import PopularDishCard from 'components/PopularDishCard';
import { DishTag } from 'generated/types';
import { useStyles } from './style';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import PopularIcon from 'assets/img/popular_icon.svg';
import { Dish } from 'models';

// install Swiper components
SwiperCore.use([Pagination, Autoplay]);

interface IProps {
  restaurantId: string;
  dishes: Dish[] | null;
  loading: Boolean;
}

const PopularDishes: React.FC<IProps> = ({ restaurantId, dishes, loading }) => {
  const classes = useStyles();

  const isSmallerDevice = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const numberOfCards = isSmallerDevice ? 2.5 : 4.5;
  if (loading) {
    return null;
  }

  if (dishes && dishes.length) {
    const popular: DishTag = DishTag.Popular;

    const dataList = chain(dishes)
      .filter(({ getTags, images, isDiscounted, price, id }) => {
        const {
          preview: { url }
        } = (images && images[0]) || { preview: { url: null } };

        const isPopular = includes(getTags, popular);

        //todo double check if prices also have discountAmount when isDiscounted is true then push to popular list
        return (isPopular || isDiscounted) && !!url;
      })
      .orderBy([(dish: any) => dish.isDiscounted || false, (dish: any) => dish.getMenuPrice.maxDiscountPercent], ['desc', 'desc'])
      .map(({ id, getTitle, images, getPrice, getMenuPrice }) => {
        const title: string = getTitle;

        const price: string = getPrice;

        const menuPrice = getMenuPrice;
        const {
          preview: { url: url_preview }
        } = (images && images[0]) || { preview: { url: null } };

        const {
          md: { url: url_md }
        } = (images && images[0]) || { md: { url: null } };

        return (
          <SwiperSlide key={id}>
            <Box key={id} height="100%">
              <PopularDishCard id={id} title={title} imageSrc={isSmallerDevice ? url_preview : url_md} price={price} menuPrice={menuPrice} />
            </Box>
          </SwiperSlide>
        );
      })
      .value();

    if (dataList && dataList.length) {
      return (
        <Box bgcolor="#fff" paddingX={isSmallerDevice ? 1 : 2} borderRadius={isSmallerDevice ? 1 : 8}>
          <Box paddingY={1} display="flex" alignContent="center">
            <Typography display="inline" variant="body1">
              Popular
            </Typography>
            <img src={PopularIcon} alt="Popular" />
          </Box>
          <Box className={classes.swiper}>
            <Swiper watchOverflow={true} autoHeight={true} spaceBetween={10} slidesPerView={numberOfCards}>
              {dataList}
            </Swiper>
          </Box>
        </Box>
      );
    }
  }

  return null;
};

export default PopularDishes;
