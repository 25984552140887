import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './style';

interface IFormLabelTextProps {
  title: string;
  price: number | string;
  rewardPoints?: number | string;
  discount?: string;
  strikedPrice?: string;
  currency: 'USD' | 'POINT';
}

const FormLabelText: React.FC<IFormLabelTextProps> = ({ title, price, rewardPoints = 0, discount = null, strikedPrice = null, currency = 'USD' }) => {
  const classes = useStyles();

  const getPrice = () => {
    if (currency === 'USD') {
      return `$ ${price}`;
    }

    return `${rewardPoints} PTS`;
  };

  return (
    <Box display="flex">
      <Box flex={1}>{title}</Box>
      {/* <Typography variant="body2" align="right">
        {getPrice()}
      </Typography> */}
      {!!strikedPrice && (
        <Box className={classes.priceItem}>
          <Typography className={classes.priceStriked} variant="body2" align="right">
            ${strikedPrice}
          </Typography>
        </Box>
      )}
      <Box className={classes.priceItem}>
        <Typography variant="body2" align="right">
          {getPrice()}
        </Typography>
      </Box>
      {!!discount && (
        <Box className={classes.priceItem}>
          <Typography className={classes.priceDiscount} variant="body2" align="right">
            {discount}%&nbsp;OFF
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormLabelText;
