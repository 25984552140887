import React, { useState } from 'react';
import { chain, includes } from 'lodash';
import DishDetails from 'components/DishDetails';
import { Grid, Box, Divider, useMediaQuery } from '@material-ui/core';
import { useStyles } from './style';
import DishCategories from 'components/DishCategories';
import DishDialog from 'components/DishDialog';
import { useStore } from 'store';
import { DishTag } from 'generated/types';
import { IDishCategories } from 'generated/custom';

interface IProps {
  restaurantId: string;
  dishes: any;
  dishCategories: IDishCategories[] | null;
  loading: Boolean;
}

const Menu: React.FC<IProps> = ({ restaurantId, dishes, dishCategories, loading }) => {
  const classes = useStyles();

  const [isDishDialogOpen, setDishDialogOpen] = useState(false);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const [activeDish, setActiveDish] = useState<null | string>(null);

  const {
    state: {
      restaurantMenu: { activeCategory }
    }
  } = useStore();

  const _dishGridItemOnClick = (id: string) => {
    setActiveDish(id);

    setDishDialogOpen(true);
  };

  const _handleClose = () => {
    setDishDialogOpen(false);
  };

  if (loading) {
    return null;
  }

  if (dishes && dishes.length && dishCategories && dishCategories.length) {
    const dataList = chain(dishes)
      .filter(({ getCategory }) => {
        if (activeCategory !== 'All') {
          return getCategory === activeCategory;
        }

        return true;
      })
      .map(({ id: dishId, getTitle, images, getMenuPrice, getDescription, getTags, isAlcohol, rateScore, rateCount }) => {
        const title = getTitle;

        const description = getDescription;

        const menuPrice = getMenuPrice;

        const {
          preview: { url: url_preview }
        } = (images && images[0]) || { preview: { url: null } };

        const {
          md: { url: url_md }
        } = (images && images[0]) || { md: { url: null } };

        const popular: DishTag = DishTag.Popular;

        const isPopular = includes(getTags, popular);

        return (
          <Grid item={true} sm={6} md={6} xs={12} zeroMinWidth={true} key={dishId} onClick={() => _dishGridItemOnClick(dishId)}>
            <DishDetails
              title={title}
              description={description}
              imgSrc={isDesktop ? url_md : url_preview}
              menuPrice={menuPrice}
              isPopular={isPopular}
              isAlcohol={isAlcohol}
              rateScore={rateScore}
              rateCount={rateCount}
            />
            <Box paddingX="5%">
              <Divider />
            </Box>
          </Grid>
        );
      })
      .value();

    return (
      <Box>
        <Box marginBottom={2}>
          <DishCategories restaurantId={restaurantId} dishCategories={dishCategories} />
        </Box>
        <Box className={classes.dishListWrapper}>
          <Grid container={true} spacing={1} alignItems="stretch">
            {dataList}
          </Grid>
        </Box>
        <DishDialog isOpen={isDishDialogOpen} handleClose={_handleClose} id={activeDish} />
      </Box>
    );
  }

  return null;
};

export default Menu;
