import React from 'react';
import { Box, Typography, Button, Dialog, DialogContent } from '@material-ui/core';
import Transition from 'components/Transition';
import { useStore } from 'store';
import error_bee from 'assets/img/error_bee.png';

import { useStyles } from './style';

export const Alert: React.FC = () => {
  const classes = useStyles();

  const {
    state: {
      alert: { isOpen: open, message }
    },
    dispatch
  } = useStore();

  const handleCllose = () => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isOpen: false,
        message: ''
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleCllose} TransitionComponent={Transition} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogContent className={classes.dialogBg}>
        <Box paddingBottom={2} display="flex" justifyContent="center">
          <img src={error_bee} width="150px" alt="Error" />
        </Box>
        <Typography variant="subtitle2" align="center">
          {message}
        </Typography>
        <Box marginY={2} display="flex" justifyContent="center">
          <Button className={classes.errorBtn} variant="contained" onClick={handleCllose}>
            OK
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Alert;
