import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStyles } from './style';
import { IDish } from 'pages/MyCart/CheckoutSummary/types';
import { getIn, useFormik } from 'formik';
import validate from './validations';
import { useAlert } from 'hooks';
import DialogHeader from 'components/DialogHeader';
import { useStore } from 'store';
import { Dialog, Box, Button, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { find, isEmpty } from 'lodash';
import PhoneInput from 'react-phone-number-input';
import { useContext } from 'react';
import { AuthContext } from 'fbase/authContext';
import { grey } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import AlcoholAgreement from 'components/AlcoholAgreement';
import useCart from 'hooks/useCart';
interface IProps {
  onHandleClose: (submitted: boolean) => void;
  open: boolean;
}

const ContactDetailsFormForGuest: React.FC<IProps> = ({ open, onHandleClose }: IProps) => {
  const classes = useStyles();

  const { currentUser } = useContext(AuthContext);

  const [openAlcoholAgreement, setOpenAlcoholAgreement] = useState(false);
  const [isNickNameSuggest, setIsNickNameSuggest] = useState(false);
  const [isCartAlcoholic, setIsCartAlcoholic] = useState(false);

  const { lokoAlert } = useAlert();

  const { dispatch } = useStore();
  const { state } = useStore();
  const { cartItems, cartRewardItems } = useCart();
  const orderPhoneNumber = state && state.user ? state.user.orderPhoneNumber : '';
  const email = state && state.user ? state.user.email : '';
  const nickName = state && state.user ? state.user.nickName : '';
  const orderPhoneNumberFromUrl = state && state.user.orderPhoneNumberFromUrl ? state.user.orderPhoneNumberFromUrl : '';

  useEffect(() => {
    const checkIfHasAlcohol = (items: IDish[]) => {
      const isAlcoholic = find(items, (item) => item.isAlcohol);

      return !!isAlcoholic;
    };
    const isAlcoholic = checkIfHasAlcohol(cartItems);

    const isRewardAlcoholic = checkIfHasAlcohol(cartRewardItems);

    setIsCartAlcoholic(!!isAlcoholic || !!isRewardAlcoholic);
  }, [cartItems, cartRewardItems]);
  const setUserInStore = (phoneNumber: string, email: string, nickName?: string) => {
    const input = {
      phoneNumber,
      email,
      nickName
    };

    dispatch({
      type: 'USER_ACTION_SET_CONTACT_DETAILS',
      payload: input
    });
  };

  const formik = useFormik({
    initialValues: {
      phonenumber: orderPhoneNumberFromUrl ? orderPhoneNumberFromUrl : orderPhoneNumber || '',
      email: email || '',
      nickName: nickName || ''
    },
    enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      const { phonenumber, email, nickName } = values;
      formik.setSubmitting(true);
      try {
        await currentUser?.updateProfile({
          displayName: nickName
        });
        setUserInStore(phonenumber, email, nickName);

        onHandleClose(true);
      } catch (e) {
        console.log(e);
        formik.setSubmitting(false);
        lokoAlert('Something went wrong.');
      }
    }
  });
  const handleClearNickName = () => {
    formik.setValues({
      ...formik.values,
      nickName: ''
    });
  };

  const handleMouseDownClear = (event: any) => {
    event.preventDefault();
  };
  const setNickNameFromEmail = () => {
    if (formik.values.nickName === '') {
      let emailItem = formik.values.email;
      let newNickName = emailItem.split('@').shift();
      formik.setFieldTouched('nickName');
      formik.setFieldValue('nickName', newNickName);
      setIsNickNameSuggest(true);
    }
  };

  const onPrivacyClick = () => {
    dispatch({
      type: 'UI_ACTION_PRIVACY_POPUP',
      payload: true
    });
  };

  const onAgreementClick = () => {
    dispatch({
      type: 'UI_ACTION_AGREEMENT_POPUP',
      payload: true
    });
  };
  const handleFormSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    if (e) {
      e.preventDefault();
    }
    if (isCartAlcoholic) {
      setOpenAlcoholAgreement(true);
    } else {
      formik.handleSubmit();
    }
  };
  return (
    <Dialog open={open} fullScreen>
      <DialogHeader text="Contact Details" onBack={() => onHandleClose(false)} />
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <form onSubmit={handleFormSubmit} className={classes.alignFormLayout}>
          <Box>
            <Box display="flex" alignItems="center" paddingX={4} paddingY={2}>
              <Typography variant="body1" align="center">
                Email is required for order receipt. Phone number is required for order status update (text).
              </Typography>
            </Box>
            <Box paddingX={2}>
              <TextField
                error={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email')}
                helperText={!!getIn(formik.touched, 'email') && getIn(formik.errors, 'email')}
                label="Email Address"
                type={'email'}
                name="email"
                className={classes.textField}
                variant="outlined"
                value={formik.values.email}
                fullWidth
                required={true}
                onChange={formik.handleChange}
                onFocus={formik.handleBlur('email')}
                onBlur={(e) => {
                  formik.handleBlur('email');
                  if (!getIn(formik.errors, 'email')) {
                    setNickNameFromEmail();
                  }
                }}
                autoComplete="off"
              />
              <Box marginBottom={2}>
                <PhoneInput
                  className={classes.phoneInputField}
                  name="phonenumber"
                  label="Enter Your Phone number*"
                  placeholder="Enter Your Phone number*"
                  defaultCountry="US"
                  value={formik.values.phonenumber}
                  autoComplete="off"
                  required={true}
                  varient="outlined"
                  onBlur={formik.handleBlur('phonenumber')}
                  onChange={(val: string) => formik.handleChange({ target: { value: val, name: 'phonenumber' } } as ChangeEvent<HTMLInputElement>)}
                />
                {!!getIn(formik.touched, 'phonenumber') && formik.errors.phonenumber && (
                  <Box paddingX={2} marginBottom={1}>
                    <Typography variant="caption" color="error">
                      {getIn(formik.errors, 'phonenumber')}
                    </Typography>
                  </Box>
                )}
              </Box>
              <TextField
                label="Nick Name(Optional)"
                name="nickName"
                className={classes.textField}
                variant="outlined"
                value={formik.values.nickName}
                fullWidth
                InputProps={{
                  style: {
                    color: isNickNameSuggest ? grey[500] : 'black'
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="handleClearNickName" onClick={handleClearNickName} onMouseDown={handleMouseDownClear}>
                        {formik.values?.nickName !== '' && <ClearIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={formik.handleChange}
                onFocus={(e) => {
                  formik.handleBlur('nickName');
                  setIsNickNameSuggest(false);
                }}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
            </Box>
            <Box paddingTop={1} paddingX={2} marginBottom={1} display="flex">
              <Typography className={classes.link} variant="body1" onClick={onPrivacyClick}>
                Privacy Policy,
              </Typography>
              &nbsp;
              <Typography className={classes.link} variant="body1" onClick={onAgreementClick}>
                User Agreement
              </Typography>
            </Box>
            <Box paddingX={2}>
              <Typography variant="body2">By clicking SUBMIT, I agree to Lokobee user agreement and privacy policy.</Typography>
            </Box>
          </Box>

          <Box paddingX={3} paddingBottom={3}>
            <Button type="submit" variant="contained" color="primary" fullWidth disabled={!isEmpty(formik.errors) || formik.isSubmitting || isEmpty(formik.values)}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
      {openAlcoholAgreement && <AlcoholAgreement acceptDisclaimer={formik.handleSubmit} onClose={() => setOpenAlcoholAgreement(false)} />}
    </Dialog>
  );
};

export default ContactDetailsFormForGuest;
