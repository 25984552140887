import gql from 'graphql-tag';

export const createGroceryOrderMutation = gql`
  mutation createGroceryOrder($input: CreateGroceryOrderInput!) {
    createGroceryOrder(input: $input) {
      orderId
      requestId
      redirectUrl
    }
  }
`;
