import React from 'react';
import { Tabs, Tab, Box, Grow, useMediaQuery } from '@material-ui/core';
import { map } from 'lodash';
import { withStyles } from '@material-ui/styles';
import { useStore } from 'store';
import Sticky from 'components/Sticky';
import { useStyles } from './style';
import { IDishCategories } from 'generated/custom';

interface IProps {
  restaurantId: string;
  dishCategories: IDishCategories[];
}

interface IStyledTabsProps {
  id: string;
  value: string;
  variant: 'scrollable' | 'standard' | 'fullWidth' | undefined;
  scrollButtons: 'auto' | 'on' | 'off' | 'desktop' | undefined;
  className: string | undefined;
}

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#000',
    height: '4px',
    display: 'none'
  }
})((props: IStyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const DishCategories: React.FC<IProps> = ({ restaurantId, dishCategories }) => {
  const classes = useStyles();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const {
    state: {
      restaurantMenu: { activeCategory }
    },
    dispatch
  } = useStore();

  const onTabClickHandler = (category: string) => {
    if (activeCategory !== category) {
      dispatch({
        type: 'SET_ACTIVE_CATEGORY',
        payload: category
      });
    }
  };

  if (dishCategories && dishCategories.length) {
    return (
      <Sticky>
        <Box bgcolor="#fff" zIndex={10} borderRadius={isDesktop ? 8 : 0}>
          <Grow in={true}>
            <StyledTabs id="catergory-tabbar" className={classes.tabs} value={activeCategory} variant="scrollable" scrollButtons="on">
              <Tab key="All" value="All" label="All" selected={'All' === activeCategory} onClick={() => onTabClickHandler('All')} />
              {map(dishCategories, ({ title }: IDishCategories) => {
                return <Tab key={title} value={title} label={title} selected={title === activeCategory} onClick={() => onTabClickHandler(title)} />;
              })}
            </StyledTabs>
          </Grow>
        </Box>
      </Sticky>
    );
  }

  return null;
};

export default DishCategories;
