import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useProfileRoles } from 'hooks';
import DialogHeader from 'components/DialogHeader';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const RouteList: React.FC = () => {
  const history = useHistory();

  const { isFacebookLogin, isGoogleLogin } = useProfileRoles();

  const changeRoute = (route: string) => {
    history.push(route);
  };

  const onBack = () => {
    history.goBack();
  };

  const isSocialMediaLogin = isFacebookLogin || isGoogleLogin;

  const DisplayLink = ({ text, onClick, icon, showArrow = true, showLine = true }: any) => {
    return (
      <>
        <Box style={{ cursor: 'pointer' }} width="100%" display="flex" alignItems="center" paddingY={1.5} onClick={onClick}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Box display="flex" paddingLeft={2} paddingRight={1}>
              {icon}
            </Box>
            <Typography variant="body1"> {text}</Typography>
          </Box>
          {showArrow && (
            <Box paddingX={1}>
              <ChevronRightIcon />
            </Box>
          )}
        </Box>
        {showLine && (
          <Box paddingX={2}>
            <Divider style={{ height: '1px' }} />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <DialogHeader text="My Profile" onBack={onBack} />
      <DisplayLink text="Change display name" icon={<AccountBoxIcon fontSize="small" />} onClick={() => changeRoute('/profile/update-name')} />
      {!isSocialMediaLogin && <DisplayLink text="Change password" icon={<VpnKeyIcon fontSize="small" />} onClick={() => changeRoute('/profile/update-password')} />}
    </>
  );
};

export default RouteList;
