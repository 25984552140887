import React, { useState } from 'react';
import { Dish } from 'models';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Grid, Box, Divider, makeStyles, Backdrop } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { DishTag } from 'generated/types';
import { includes } from 'lodash';
import DishDetails from 'components/DishDetails';
import Fuse from 'fuse.js';
import DishDialog from 'components/DishDialog';

interface IProps {
  dishes: Dish[];
}

const zIndex = 10;

export const useStyles = makeStyles((theme) => ({
  input: (props: any) => ({
    backgroundColor: '#fff',
    zIndex: props.open ? zIndex : 0,
    borderRadius: '5px',
    border: 'none'
  }),
  root: (props: any) => ({
    zIndex: props.open ? zIndex : 0
  }),
  popper: (props: any) => ({
    zIndex: props.open ? zIndex : 0
  })
}));

const DishSearch = ({ dishes }: IProps) => {
  const [query, setQuery] = useState('');

  const [filteredDishes, setFilteredDishes] = useState<Dish[]>([]);

  const [isDishDialogOpen, setDishDialogOpen] = useState(false);

  const [activeDish, setActiveDish] = useState<null | string>(null);

  const [openDropDown, setOpenDropDown] = useState(false);

  const classes = useStyles({ open: openDropDown });

  const onDishSelect = (id: string) => {
    setActiveDish(id);
    setDishDialogOpen(true);
    setOpenDropDown(false);
  };

  const handleDialogClose = () => {
    setDishDialogOpen(false);
  };

  const onChange = (q: string) => {
    setQuery(q);
    const fuse = new Fuse(dishes, {
      keys: ['getTitle'],
      isCaseSensitive: false,
      shouldSort: true,
      minMatchCharLength: 3
    });

    setFilteredDishes(fuse.search(q).map((res) => res.item));
    setOpenDropDown(true);
  };

  return (
    <>
      <Backdrop open={openDropDown} onClick={() => setOpenDropDown(false)} style={{ zIndex: zIndex }} />
      <Autocomplete
        classes={classes}
        open={openDropDown}
        noOptionsText="No dishes found"
        selectOnFocus={false}
        popupIcon={
          query !== '' ? (
            <ClearIcon
              onClick={() => {
                setQuery('');
                setOpenDropDown(false);
                setFilteredDishes([]);
              }}
            />
          ) : null
        }
        onFocus={() => {
          if (filteredDishes.length) {
            setOpenDropDown(true);
          }
        }}
        inputValue={query}
        options={filteredDishes}
        filterOptions={(x) => x}
        renderOption={(props, option) => [props, option]}
        ListboxComponent={React.forwardRef(function ListboxComponent(props, ref: any) {
          return (
            <Grid className={props.className} container={true} style={{ zIndex: zIndex }}>
              {filteredDishes.map(({ id: dishId, getTitle, images, getMenuPrice, getDescription, getTags, isAlcohol, rateScore, rateCount }: Dish) => {
                const title = getTitle;

                const description = getDescription;

                const menuPrice = getMenuPrice;

                const {
                  preview: { url }
                } = (images && images[0]) || { preview: { url: null } };

                const popular: DishTag = DishTag.Popular;

                const isPopular = includes(getTags, popular);

                return (
                  <Grid item={true} sm={6} md={6} xs={12} zeroMinWidth={true} key={dishId} onClick={() => onDishSelect(dishId)}>
                    <DishDetails title={title} description={description} imgSrc={url} menuPrice={menuPrice} isPopular={isPopular} isAlcohol={isAlcohol} rateScore={rateScore} rateCount={rateCount} />
                    <Box paddingX="5%">
                      <Divider />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
        renderInput={(params) => (
          <TextField
            className={classes.input}
            {...params}
            fullWidth={true}
            value={query}
            variant="outlined"
            onChange={(e) => onChange(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />
            }}
            size="small"
            placeholder="Search items..."
          />
        )}
      />
      <DishDialog isOpen={isDishDialogOpen} handleClose={handleDialogClose} id={activeDish} />
    </>
  );
};

export default DishSearch;
