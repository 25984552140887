import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  phoneInputField: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    background: 'transparent',
    border: 'solid 1px black',
    borderRadius: 5,
    '& input': {
      background: 'transparent',
      border: 'none',
      //   fontFamily: "'Itim'",
      outline: 'none',
      '&::placeholder': {
        color: grey[500],
        fontWeight: '200'
      }
    },
    '& .PhoneInputCountryIcon': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none'
    }
  }
}));
