import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import { useRestaurantPause } from 'hooks/restaurant';
import RestaurantModel from 'models/Restaurant';

interface IProps {
  restaurant: RestaurantModel;
}

const RestaurantPause = ({ restaurant }: IProps) => {
  const classes = makeStyles((theme: Theme) => ({
    text: {
      fontWeight: 600
    }
  }))();

  const { pauseData, deliveryPause, diningPause, takeOutPause } = useRestaurantPause({ restaurant });

  if (pauseData) {
    const orderTypes = [];
    if (diningPause) orderTypes.push('DINING');

    if (takeOutPause) orderTypes.push('TAKEOUT');

    if (deliveryPause) orderTypes.push('DELIVERY');

    if (orderTypes.length) {
      return (
        <Box padding={1}>
          <Typography variant="body1" color="error" align="justify" className={classes.text}>
            NOT TAKING ORDERS FOR {orderTypes.join(', ')} FOR TODAY. FUTURE ORDERS CAN BE PLACED.
          </Typography>
        </Box>
      );
    }
  }
  return null;
};

export default RestaurantPause;
