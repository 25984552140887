import React from 'react';
import Location from 'components/Location';
import { useStore } from 'store';
import { Box, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useStyles } from '../style';

const UserLocation: React.FC = () => {
  const classes = useStyles();

  const {
    dispatch,
    state: {
      user: { address }
    }
  } = useStore();

  const onSelect = ({ address, lat, lng }: any) => {
    dispatch({
      type: 'USER_ACTION_SET_COORDINATES',
      payload: {
        latitude: lat,
        longitude: lng
      }
    });

    dispatch({
      type: 'USER_ACTION_SET_ADDRESS',
      payload: address
    });
  };

  const textStyle = {
    marginBottom: -6
  };

  return (
    <Location address={address} onSelect={onSelect}>
      {() => {
        return (
          <Box display="flex" alignItems="center">
            <Box marginRight={1}>
              <LocationOnIcon className={classes.locationIcon} fontSize="small" />
            </Box>
            <Box>
              <Typography variant="body1" align="left" style={textStyle}>
                LOCATION
              </Typography>
              <Typography variant="caption" noWrap={true}>
                {address}
              </Typography>
            </Box>
          </Box>
        );
      }}
    </Location>
  );
};

export default UserLocation;
