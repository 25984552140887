import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialogBg: {
    background: 'linear-gradient(rgb(255 192 50 / 55%), white 60%)'
  },
  errorBtn: {
    background: 'red',
    color: 'white'
  }
}));
