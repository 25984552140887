import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(( theme ) => ({
  dialog: {
    '& .MuiPaper-root': {
      height: '100%'
    }
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  lightBold: {
    fontWeight: 500
  },
  bold: {
    fontWeight: 600
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid lightgrey'
    }
  },
  
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}));