import React from 'react';
import { Box } from '@material-ui/core';
import { Map, Marker } from 'google-maps-react';

interface IProps {
  location: {
    lat: number | null | undefined;
    lng: number | null | undefined;
  } | null;
}

const GoogleMap = ({ location }: IProps) => {
  const lat = location && location.lat ? location.lat : 41.850033;

  const long = location && location.lng ? location.lng : -87.6500523;

  const onMarkerClick = () => {
    window.open('https://maps.google.com?q=' + lat + ',' + long + '', '_blank');
  };

  if (window.google) {
    return (
      <Box position="relative" height="auto" paddingTop="66.66%">
        <Map
          google={window.google}
          initialCenter={{
            lat: lat,
            lng: long
          }}
          scrollwheel={false}
          draggable={false}
          containerStyle={{ position: 'absolute', top: 0 }}
          zoom={location ? 15 : 3}>
          <Marker onClick={onMarkerClick} position={{ lat: lat, lng: long }} />
        </Map>
      </Box>
    );
  }

  return null;
};

export default GoogleMap;
