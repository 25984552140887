import { useEffect, useState } from 'react';

import { useLazyQueryWithLoader, useQueryWithLoader } from 'hooks/loader';

import { Coupon } from 'generated/types';

import { getCouponByCouponId, getCoupons, verifyCouponQuery } from 'graphql/query';

// Regular Coupon
export const useGetCouponsByBizId = (bizId: string) => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const [getCouponsCaller, { data, loading, error }] = useLazyQueryWithLoader(getCoupons, {}, true);

  useEffect(() => {
    if (bizId) {
      getCouponsCaller({
        variables: {
          input: {
            bizId
          }
        }
      });
    }
  }, [bizId, getCouponsCaller]);

  useEffect(() => {
    if (data && data.getCoupons) {
      const couponsData: Coupon[] = data.getCoupons;
      setCoupons(couponsData);
    }
  }, [data]);

  return {
    coupons,
    loading,
    error
  };
};
export const useVerifyCoupon = () => {
  const [verifyCoupon, { data, loading, error }] = useLazyQueryWithLoader<any, any>(verifyCouponQuery, {}, true);

  return {
    verifyCoupon,
    data,
    loading,
    error
  };
};
export const useGetCouponByCouponId = (couponId: string | null) => {
  const { loading, data, error } = useQueryWithLoader(getCouponByCouponId, {
    skip: !couponId,
    variables: {
      input: {
        couponId
      }
    }
  });

  return {
    loading,
    data,
    error
  };
};
