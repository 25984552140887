import React from 'react';
import { IPizzaToppingItemExtendedType } from '../types';
import { PizzaToppingDensity } from 'generated/types';
import { Box, Typography, MenuItem, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { useStyles } from '../style';
import { find, map, reduce } from 'lodash';
import { convertPrice } from 'util/number';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import grey from '@material-ui/core/colors/grey';
import { useState } from 'react';

interface IProps {
  groupTitle: string;
  selectedSize: string;
  itemName: string;
  items: IPizzaToppingItemExtendedType[];
  handleChange: (e: React.ChangeEvent<any>) => void;
}

interface IToppingItem extends IPizzaToppingItemExtendedType {
  itemIndex: number;
}

const Toppings = ({ groupTitle, selectedSize, items, itemName, handleChange }: IProps) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);

  const presetItems: IToppingItem[] = reduce(
    items,
    (result: IToppingItem[], item, index) => {
      if (item.preset) {
        result.push({
          ...item,
          itemIndex: index
        });
      }
      return result;
    },
    []
  );

  const nonPresetItems: IToppingItem[] = reduce(
    items,
    (result: IToppingItem[], item, index) => {
      if (!item.preset) {
        result.push({
          ...item,
          itemIndex: index
        });
      }
      return result;
    },
    []
  );
  if (items.length) {
    return (
      <ExpansionPanel className={classes.expansionPanel} expanded={expanded}>
        <ExpansionPanelSummary className={classes.expansionSummary}>
          <Box paddingX={2} width="100%">
            <Box display="flex" alignItems="center" paddingY={1} marginLeft={-0.5}>
              {expanded ? (
                <IndeterminateCheckBoxIcon className={classes.expansionIcon} color="secondary" onClick={() => setExpanded(false)} />
              ) : (
                <AddBoxIcon className={classes.expansionIcon} color="disabled" onClick={() => setExpanded(true)} />
              )}

              <Typography variant="h6">{groupTitle}</Typography>
            </Box>
            {/* Preset items should be shown even when panel collapsed so putting it in ExpansionPanelSummary */}
            <Box>
              {map(presetItems, (topping) => {
                const { preset, selectedDensity, title, prices, itemIndex } = topping;

                const pricesBySize = find(prices, ({ size }) => size.id === selectedSize);

                const { intValue, shift } = pricesBySize?.densityPrices.find((priceItem) => priceItem.density === selectedDensity)?.price || {
                  intValue: 0,
                  shift: 0
                };
                return (
                  <Box key={itemIndex} display="flex" alignItems="center" paddingY={1}>
                    <Box flex={2}>
                      <Box>
                        <Typography variant="subtitle2" style={preset && selectedDensity === PizzaToppingDensity.None ? { color: 'red', textDecoration: 'line-through' } : {}}>
                          {title}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">${convertPrice(intValue, shift)}</Typography>
                        {preset && (
                          <>
                            <Box padding={0.5} marginX={0.5} height="max-content" width="max-content" borderRadius="100%" bgcolor={grey[300]} />
                            <i>
                              <Typography variant="body2">Chef's Selection</Typography>
                            </i>
                          </>
                        )}
                      </Box>
                    </Box>
                    {pricesBySize?.densities && pricesBySize.densities.length && (
                      <Box flex={1}>
                        <TextField value={selectedDensity} name={`${itemName}[${itemIndex}].selectedDensity`} select={true} size="small" variant="outlined" fullWidth={true} onChange={handleChange}>
                          {map(pricesBySize.densities, (density, densityIndex) => {
                            return (
                              <MenuItem key={densityIndex} value={density}>
                                <Typography variant="body1">{density}</Typography>
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <Box bgcolor={grey[100]} width="100%">
            {map(nonPresetItems, (topping) => {
              const { preset, selectedDensity, title, prices, itemIndex } = topping;

              const pricesBySize = find(prices, ({ size }) => size.id === selectedSize);

              const { intValue, shift } = pricesBySize?.densityPrices.find((priceItem) => priceItem.density === selectedDensity)?.price || {
                intValue: 0,
                shift: 0
              };

              return (
                <Box key={itemIndex} bgcolor={selectedDensity !== PizzaToppingDensity.None ? '#fff' : ''} display="flex" alignItems="center" paddingX={2} paddingY={1}>
                  <Box flex={2}>
                    <Box>
                      <Typography variant="subtitle2" style={preset && selectedDensity === PizzaToppingDensity.None ? { color: 'red', textDecoration: 'line-through' } : {}}>
                        {title}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">${convertPrice(intValue, shift)}</Typography>
                    </Box>
                  </Box>
                  {pricesBySize?.densities && pricesBySize.densities.length && (
                    <Box flex={1}>
                      <TextField
                        className={classes.selectField}
                        value={selectedDensity}
                        name={`${itemName}[${itemIndex}].selectedDensity`}
                        select={true}
                        size="small"
                        variant="outlined"
                        fullWidth={true}
                        onChange={handleChange}>
                        {map(pricesBySize.densities, (density, densityIndex) => {
                          return (
                            <MenuItem key={densityIndex} value={density}>
                              <Typography variant="body1">{density}</Typography>
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  return null;
};

export default Toppings;
