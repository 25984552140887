import { useState, useEffect, useContext } from 'react';
import { useStore } from 'store';
import Big from 'big.js';
import { min, map, isEqual } from 'lodash';
import { CartItem } from 'models';
import { DishType } from 'generated/types';
import { plainToClass } from 'class-transformer';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { getRestaurant as getRestaurantQuery, getUserRewardPointsQuery } from 'graphql/query';
import { useLazyQueryWithLoader } from 'hooks/loader';
import { AuthContext } from 'fbase/authContext';

type IsAddPossibleReturnType = {
  isPossible: Boolean;
  message: string;
};

const useReward = () => {
  const {
    state: {
      cartItems: storeCartItems,
      cartRewardItems: storeCartRewardItems,
      totalPoints,
      redeemablePoints,
      usedPoints: storeUsedPoints,
      minimumPointsRequired,
      minimumPurchaseRequired,
      maximumRedeemablePoints,
      issueRewardPoint
    },
    dispatch
  } = useStore();

  const { restaurantId } = useParams<any>();

  const { currentUser } = useContext(AuthContext);

  const [subtotal, setSubtotal] = useState<Big>(Big(0));

  const [usedPoints, setUsedPoints] = useState(0);

  const [isRedeemAllowed, setRedeemAllowed] = useState(false);

  const [isMinimumPurchaseSatisfied, setMinimumPurchaseSatisfied] = useState(false);

  const [isMinimumPointsSatisfied, setMinimumPointsSatisfied] = useState(false);

  const [remainingPoints, setRemainingPoints] = useState(0);

  const [pointsCutoff, setPointsCutOff] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [bizId, setBizId] = useState(null);

  const [totalPointsUser, setTotalPoints] = useState(0);

  const [configFetched, setConfigFetched] = useState(false);

  const [getRestaurant, { data: restaurantData }] = useLazyQuery(getRestaurantQuery);

  const [getRewardPoints, { data: rewardPointData, loading: rewardPointLoading }] = useLazyQueryWithLoader(
    getUserRewardPointsQuery,
    {
      fetchPolicy: 'cache-first'
    },
    false
  );

  useEffect(() => {
    const firstItem = storeCartItems[0] || storeCartRewardItems[0];

    const cartRestaurantId = firstItem?.restaurant?.id;

    let bizId = null;

    if (restaurantId) {
      bizId = restaurantId;
    } else {
      bizId = cartRestaurantId;
    }

    setBizId(bizId);
  }, [restaurantId, storeCartItems, storeCartRewardItems]);

  useEffect(() => {
    if (bizId) {
      if (currentUser) {
        getRewardPoints({
          variables: {
            input: {
              bizId
            }
          }
        });
      }

      getRestaurant({
        variables: {
          input: {
            id: bizId
          }
        }
      });
    }
  }, [bizId, currentUser, getRewardPoints, getRestaurant]);

  /**
   * Getting user total reward points
   */
  useEffect(() => {
    if (rewardPointData && rewardPointData.getRewardPointsForConsumer && rewardPointData.getRewardPointsForConsumer[0]) {
      const totalPoints = rewardPointData?.getRewardPointsForConsumer[0]?.activePoints;

      setTotalPoints(totalPoints);
    }
  }, [rewardPointData]);

  /**
   * Setting reward setting in the store
   */
  useEffect(() => {
    if (restaurantData && restaurantData.getRestaurant.rewardSetting) {
      const { rewardSetting, issueRewardPoint } = restaurantData.getRestaurant;

      const minimumPointsRequired = rewardSetting.minStartPoints;

      const minimumPurchaseRequired = rewardSetting.minRedeemPurchase;

      const maximumRedeemablePoints = rewardSetting.maxRedeemPointsPerUsage;

      const pricePointRatio = rewardSetting.pricePointRatio;

      const redeemablePoints = min([maximumRedeemablePoints, totalPointsUser]);

      dispatch({
        type: 'SET_REWARD_SETTING',
        payload: {
          totalPoints: totalPointsUser,
          redeemablePoints,
          minimumPointsRequired,
          minimumPurchaseRequired,
          maximumRedeemablePoints,
          pricePointRatio,
          issueRewardPoint
        }
      });
    } else {
      dispatch({
        type: 'SET_REWARD_SETTING',
        payload: {
          totalPoints: totalPointsUser,
          redeemablePoints: 0,
          minimumPointsRequired: 0,
          minimumPurchaseRequired: 0,
          maximumRedeemablePoints: 0,
          pricePointRatio: 0,
          issueRewardPoint: false
        }
      });
    }
    setConfigFetched(true);
  }, [totalPointsUser, restaurantData, dispatch]);

  /**
   * Setting used points
   */
  useEffect(() => {
    const firstItem = storeCartRewardItems[0];

    const cartRestaurantId = firstItem?.restaurant?.id;

    // If hook is called from cart page then use usedPoints from store
    // OR
    // If hook is called from restaurant pages and
    // cart is having reward items from active restaurant then use usedPoints from store
    if (!!!restaurantId || isEqual(cartRestaurantId, restaurantId)) {
      setUsedPoints(storeUsedPoints);
    }
  }, [restaurantId, storeCartRewardItems, storeUsedPoints]);

  /**
   * Getting cart subtotal to check for minimum purchase required
   */
  useEffect(() => {
    const firstItem = storeCartItems[0];

    const cartRestaurantId = firstItem?.restaurant?.id;

    let total = Big(0);

    if (!!!restaurantId || isEqual(cartRestaurantId, restaurantId)) {
      map(storeCartItems, (item) => {
        const cartItem = plainToClass(CartItem, item);

        if (cartItem.type === DishType.Pizza && cartItem.dishTypePizzaTotalPrice) {
          total = total.add(Big(cartItem.type === DishType.Pizza ? cartItem.getDishTypePizzaTotalPrice : cartItem.subtotal));
        } else {
          total = total.add(cartItem.subtotal);
        }
      });
    }

    setSubtotal(total);
  }, [restaurantId, storeCartItems]);

  /**
   * Checking minimum purchase required threshold
   */
  useEffect(() => {
    const cartTotal = subtotal.toFixed(2);

    const isAllowed = Big(cartTotal).gte(minimumPurchaseRequired);

    setMinimumPurchaseSatisfied(isAllowed);
  }, [subtotal, minimumPurchaseRequired]);

  /**
   * Checking minimum points required threshold
   */
  useEffect(() => {
    const isAllowed = Big(totalPoints).gte(minimumPointsRequired);

    setMinimumPointsSatisfied(isAllowed);
  }, [totalPoints, minimumPointsRequired]);

  /**
   * Calculating remaining points
   */
  useEffect(() => {
    const remainingPoints = parseInt(
      Big(redeemablePoints)
        .minus(usedPoints)
        .toString()
    );

    setRemainingPoints(remainingPoints);
  }, [usedPoints, redeemablePoints]);

  /**
   * Checking if redeem allowed and setting error message
   */
  useEffect(() => {
    let isRedeemDisabled = false;

    let errorMessage = '';

    if (configFetched) {
      if (!isMinimumPurchaseSatisfied) {
        isRedeemDisabled = true;

        const firstItem = storeCartItems[0];

        const cartRestaurantId = firstItem?.restaurant?.id;

        if ((!!!restaurantId || isEqual(cartRestaurantId, restaurantId)) && storeCartItems && storeCartItems.length) {
          const cartTotal = subtotal.toFixed(2);

          const requiredPurchase = Big(minimumPurchaseRequired).minus(cartTotal);

          errorMessage = `Spend $${requiredPurchase} more to redeem upto ${redeemablePoints} PTS`;
        } else {
          errorMessage = `Spend minimum $${minimumPurchaseRequired} to redeem upto ${redeemablePoints} PTS`;
        }
      }

      if (!isMinimumPointsSatisfied) {
        isRedeemDisabled = true;

        errorMessage = `Need atleast ${minimumPointsRequired} points to start redeeming`;
      }

      if (totalPoints === 0) {
        isRedeemDisabled = true;

        errorMessage = '';
      }

      if (remainingPoints === 0) {
        isRedeemDisabled = true;
      }

      if (!issueRewardPoint) {
        isRedeemDisabled = true;
        errorMessage = ``;
      }

      setRedeemAllowed(!isRedeemDisabled);

      setErrorMessage(errorMessage);
    }
  }, [
    configFetched,
    restaurantId,
    storeCartItems,
    subtotal,
    totalPoints,
    remainingPoints,
    isMinimumPurchaseSatisfied,
    isMinimumPointsSatisfied,
    minimumPurchaseRequired,
    redeemablePoints,
    minimumPointsRequired,
    restaurantData,
    issueRewardPoint
  ]);

  /**
   * Checking if maximum redeemable points is applied
   */
  useEffect(() => {
    if (totalPoints && maximumRedeemablePoints) {
      const pointsCutoff = Big(totalPoints).gt(maximumRedeemablePoints);

      setPointsCutOff(pointsCutoff);
    }
  }, [totalPoints, maximumRedeemablePoints]);

  /**
   * Function to check if one more reward item can be redeemed or not
   * It's value is used to disable the stepper inc button
   * @param pointsToAdd
   * @param dishPoints
   */
  const isAddPossible = (pointsToAdd: string, dishPoints: string) => {
    const ptsCanBeAdded = Big(remainingPoints)
      .sub(pointsToAdd)
      .toString();

    const isOneMoreIncPossible = !Big(dishPoints).gt(ptsCanBeAdded);

    return isOneMoreIncPossible;
  };

  /**
   * Function to check if n number of points can be redeemed or not
   * @param pointsToAdd
   * @param dishTitle
   */
  const isIncPossible = (pointsToAdd: string, dishTitle: string): IsAddPossibleReturnType => {
    const isPossible = Big(remainingPoints).gte(pointsToAdd);

    let message = '';

    if (!isPossible) {
      const ptsRunningOutOf = Big(pointsToAdd)
        .sub(remainingPoints)
        .toString();

      message = `Can not add one more ${dishTitle}, running short of ${ptsRunningOutOf} PTS`;
    }

    return {
      isPossible,
      message
    };
  };

  /**
   * This function will increment usedPoints
   * @param pointsToInc
   */
  const incUsedPoints = (pointsToInc: string) => {
    const updatedPoints = parseInt(
      Big(usedPoints)
        .add(pointsToInc)
        .toString()
    );

    dispatch({
      type: 'SET_RESTAURANT_USED_POINTS',
      payload: updatedPoints
    });
  };

  /**
   * This function will decrement usedPoints
   * @param pointsToDec
   */
  const decUsedPoints = (pointsToDec: string) => {
    const updatedPoints = parseInt(
      Big(usedPoints)
        .sub(pointsToDec)
        .toString()
    );

    dispatch({
      type: 'SET_RESTAURANT_USED_POINTS',
      payload: updatedPoints
    });
  };

  const resetUsedPoints = () => {
    dispatch({
      type: 'SET_RESTAURANT_USED_POINTS',
      payload: 0
    });
  };

  return {
    rewardPointLoading,
    totalPoints,
    redeemablePoints,
    minimumPointsRequired,
    isMinimumPointsSatisfied,
    minimumPurchaseRequired,
    maximumRedeemablePoints,
    isRedeemAllowed,
    isAddPossible,
    isIncPossible,
    remainingPoints,
    usedPoints,
    pointsCutoff,
    errorMessage,
    incUsedPoints,
    decUsedPoints,
    resetUsedPoints
  };
};

export default useReward;
