import { CartItem, Restaurant } from 'models';
import { map, filter, isEqual, findIndex, isEmpty } from 'lodash';
import Big from 'big.js';
import { classToPlain, plainToClass } from 'class-transformer';
import { IAddress } from 'components/AddressModal/types';
import { Coupon } from 'generated/types';
import { calculateDistanceCharge, calculateLokobeeFee, calculateServiceFee, calculateTaxRate, getRestaurantDiscountPriceAndSubtotalAfterDiscount } from 'util/priceCalculator';
import { DeliveryMode, DeliveryProvider, DropOffType, IDeliveryEstimate, IDoordashDeliveryEstimate } from 'generated/custom';
import { Bugfender } from '@bugfender/sdk';
import { Moment } from 'moment';

import { v4 as uuidv4 } from 'uuid';

const deliveryDiscount = 7; // $$

export interface ICartItems {
  cartItems: CartItem[];
  cartRewardItems: CartItem[];
  cartRestaurant: Restaurant | null;
  isCartOpen: boolean;
  isPrediscountEnabled: boolean;
  subtotal: Big;
  subtotalToDiscount: Big;
  subtotalWithoutPrediscount: Big;
  totalPriceAfterTax: Big | null;
  tip: Big;
  serviceFee: Big | null;
  tax: Big | null;
  deliveryTime: number;
  orderNote: string;
  numberOfPeople: number;
  tableNumber: string;
  orderType: 'TAKE_OUT' | 'DINING' | 'DELIVERY' | '';
  deliveryProvider: DeliveryProvider;
  deliveryMode: DeliveryMode;
  deliveryAddress: IAddress | null;
  deliveryMiles: Big;
  deliveryCharge: Big;
  doordashCharge: Big;
  deliveryDuration: string;
  deliveryNote: string;
  isDiscountApplied: false;
  couponData: Partial<Coupon> | null;
  couponToken: string | null;
  discount: Big | null;
  subtotalAfterDiscount: Big;
  boonsQuoteId: string;
  dropOffType: DropOffType;
  apartmentNumber: string;
  doordashQuoteId: string | null;
  isAutoApplyCouponAllowed: boolean;
  phoneOrder: boolean;
  selectedPickerDate: Moment | null;
  selectedPickerTime: Moment | null;
  cartSessionId: string | null;
}

export const cartInitialState: ICartItems = {
  cartItems: [],
  cartRewardItems: [],
  isCartOpen: false,
  isPrediscountEnabled: false,
  subtotal: Big(0),
  subtotalToDiscount: Big(0),
  subtotalWithoutPrediscount: Big(0),
  totalPriceAfterTax: null,
  tax: null,
  serviceFee: null,
  tip: Big(0),
  deliveryTime: Date.now(),
  orderNote: '',
  orderType: '',
  numberOfPeople: 1,
  tableNumber: '',
  cartRestaurant: null,
  deliveryProvider: '',
  deliveryMode: '',
  deliveryAddress: null,
  deliveryMiles: Big(0),
  deliveryCharge: Big(0),
  doordashCharge: Big(0),
  deliveryDuration: '',
  deliveryNote: '',
  isDiscountApplied: false,
  couponData: null,
  couponToken: null,
  discount: null,
  subtotalAfterDiscount: Big(0),
  boonsQuoteId: '',
  dropOffType: DropOffType.InHand,
  apartmentNumber: '',
  doordashQuoteId: null,
  isAutoApplyCouponAllowed: true,
  phoneOrder: false,
  selectedPickerDate: null,
  selectedPickerTime: null,
  cartSessionId: null
};

export interface ICartItemQuantityArgs {
  itemId: string;
  quantity: number;
}

export const cartActions = {
  CART_ACTION_ADD: (state: ICartItems, payload: CartItem) => {
    const newArray: any = classToPlain(state.cartItems);

    // const cartSessionUuid: string = uuidv4();
    // if (!state.cartSessionId) {
    //   state.cartSessionId = cartSessionUuid;
    // }
    const index = findIndex(state.cartItems, (item) => {
      return isEqual(item, payload);
    });

    // If index is found increase quantity.
    if (index !== -1) {
      newArray[index] = { ...newArray[index], quantity: newArray[index].quantity + payload.quantity };

      return { ...state, cartItems: plainToClass(CartItem, newArray) };
    }

    return { ...state, cartItems: [...state.cartItems, payload] };
  },
  CART_ACTION_REWARD_ADD: (state: ICartItems, payload: CartItem) => {
    const newArray: any = classToPlain(state.cartRewardItems);

    const index = findIndex(state.cartRewardItems, (item) => {
      return isEqual(item, payload);
    });

    // If index is found increase quantity.
    if (index !== -1) {
      newArray[index] = { ...newArray[index], quantity: newArray[index].quantity + payload.quantity };

      return { ...state, cartRewardItems: plainToClass(CartItem, newArray) };
    }

    return { ...state, cartRewardItems: [...state.cartRewardItems, payload] };
  },
  CART_ACTION_SET_QUANTITY: (state: ICartItems, payload: ICartItemQuantityArgs) => {
    const newArray = map(state.cartItems, (item) => {
      if (item.uuid === payload.itemId) {
        return { ...item, quantity: payload.quantity };
      }

      return item;
    });

    return { ...state, cartItems: newArray };
  },
  CART_ACTION_SET_REWARD_QUANTITY: (state: ICartItems, payload: ICartItemQuantityArgs) => {
    const newArray = map(state.cartRewardItems, (item) => {
      if (item.uuid === payload.itemId) {
        return { ...item, quantity: payload.quantity };
      }

      return item;
    });

    return { ...state, cartRewardItems: newArray };
  },
  CART_ACTION_REMOVE_ITEM: (state: ICartItems, payload: { uuid: string }) => {
    const newArray = filter(state.cartItems, (item) => item.uuid !== payload.uuid);

    return { ...state, cartItems: newArray };
  },
  CART_ACTION_REMOVE_REWARD_ITEM: (state: ICartItems, payload: { uuid: string }) => {
    const newArray = filter(state.cartRewardItems, (item) => item.uuid !== payload.uuid);

    return { ...state, cartRewardItems: newArray };
  },
  CART_ACTION_CLEAR_ALL: (state: ICartItems) => {
    return {
      ...state,
      cartItems: [],
      cartRewardItems: [],
      subtotal: Big(0),
      totalPriceAfterTax: null,
      tax: null,
      tip: Big(0),
      orderNote: '',
      orderType: '',
      numberOfPeople: 1,
      deliveryAddress: null,
      deliveryCharge: Big(0),
      doordashCharge: Big(0),
      deliveryMiles: Big(0),
      deliveryNote: '',
      apartmentNumber: '',
      isDiscountApplied: false,
      couponData: null,
      couponToken: null,
      discount: null,
      serviceFee: null,
      isUserDefinedServiceFee: false,
      phoneOrder: false,
      selectedPickerDate: null,
      selectedPickerTime: null,
      doordashQuoteId: null,
      cartSessionId: null
    };
  },
  CART_ACTION_SET_TOTALPRICE: (state: ICartItems, payload: Big | null) => {
    return { ...state, subtotal: payload };
  },
  CART_ACTION_SET_TOTALPRICE_AFTER_TAX: (state: ICartItems, payload: Big | null) => {
    return { ...state, totalPriceAfterTax: payload };
  },
  CART_ACTION_SET_TAX: (state: ICartItems, payload: Big | null) => {
    return { ...state, tax: payload };
  },
  CART_ACTION_TIP_AMOUNT: (state: ICartItems, payload: Big | null) => {
    if (payload) {
      return { ...state, tip: payload };
    }
  },
  CART_ACTION_SET_SERVICE_FEE: (state: ICartItems, payload: Big) => {
    if (payload) {
      return { ...state, serviceFee: payload, isUserDefinedServiceFee: true };
    }
  },
  CART_ACTION_SET_DELIVERY_TIME: (state: ICartItems, payload: number | string) => {
    if (payload) {
      return { ...state, deliveryTime: payload };
    }
  },
  CART_ACTION_RESET_DELIVERY_TIME: (state: ICartItems) => {
    return { ...state, selectedPickerDate: null, selectedPickerTime: null, deliveryTime: Date.now() };
  },
  CART_ACTION_SET_PICKER_DATE: (state: ICartItems, payload: Moment | null) => {
    return { ...state, selectedPickerDate: payload };
  },
  CART_ACTION_SET_PICKER_TIME: (state: ICartItems, payload: Moment | null) => {
    return { ...state, selectedPickerTime: payload };
  },
  CART_ACTION_RESET_ORDER_TYPE_INFO: (state: ICartItems) => {
    return {
      ...state,
      orderType: '',
      boonsQuoteId: '',
      dropOffType: DropOffType.InHand,
      apartmentNumber: '',
      doordashQuoteId: null,
      deliveryAddress: null,
      deliveryMiles: Big(0),
      deliveryCharge: Big(0),
      doordashCharge: Big(0),
      deliveryDuration: '',
      deliveryNote: '',
      deliveryTime: Date.now(),
      numberOfPeople: 1,
      tableNumber: '',
      selectedPickerDate: null,
      selectedPickerTime: null
    };
  },
  CART_ACTION_SET_ORDER_NOTE: (state: ICartItems, payload: string) => {
    return { ...state, orderNote: payload };
  },
  CART_ACTION_SET_ORDER_TYPE: (state: ICartItems, payload: string) => {
    if (payload === 'TAKE_OUT') {
      return {
        ...state,
        orderType: payload,
        boonsQuoteId: '',
        dropOffType: DropOffType.InHand,
        apartmentNumber: '',
        doordashQuoteId: null,
        deliveryAddress: null,
        deliveryMiles: Big(0),
        deliveryCharge: Big(0),
        doordashCharge: Big(0),
        deliveryDuration: '',
        deliveryNote: '',
        numberOfPeople: 1,
        tableNumber: '',
        deliveryTime: Date.now(),
        selectedPickerDate: null,
        selectedPickerTime: null,
        isAutoApplyCouponAllowed: true
      };
    }

    if (payload === 'DINING') {
      return {
        ...state,
        orderType: payload,
        boonsQuoteId: '',
        dropOffType: DropOffType.InHand,
        apartmentNumber: '',
        doordashQuoteId: null,
        deliveryAddress: null,
        deliveryMiles: Big(0),
        deliveryCharge: Big(0),
        doordashCharge: Big(0),
        deliveryDuration: '',
        deliveryNote: '',
        deliveryTime: Date.now(),
        selectedPickerDate: null,
        selectedPickerTime: null,
        isAutoApplyCouponAllowed: true
      };
    }

    if (payload === 'DELIVERY') {
      return {
        ...state,
        orderType: payload,
        numberOfPeople: 1,
        tableNumber: '',
        deliveryTime: Date.now(),
        selectedPickerDate: null,
        selectedPickerTime: null,
        isAutoApplyCouponAllowed: true
      };
    }

    return { ...state, orderType: payload };
  },
  CART_ACTION_SET_DINING_NUMBER_OF_PEOPLE: (state: ICartItems, payload: number) => {
    return { ...state, numberOfPeople: payload };
  },
  CART_ACTION_SET_TABLE_NUMBER: (state: ICartItems, payload: number) => {
    return { ...state, tableNumber: payload };
  },
  CART_ACTION_SET_RESTAURANT: (state: ICartItems, payload: Restaurant) => {
    return { ...state, cartRestaurant: payload };
  },
  CART_ACTION_SET_DELIVERY_CHARGE: (state: ICartItems, payload: Big | null) => {
    if (payload) {
      return { ...state, deliveryCharge: payload };
    }
  },
  CART_ACTION_SET_DELIVERY_MILES: (state: ICartItems, payload: Big | null) => {
    if (payload) {
      return { ...state, deliveryMiles: payload };
    }
  },
  CART_ACTION_SET_BOONS_DELIVERY_ESTIMATE: (state: ICartItems, payload: IDeliveryEstimate) => {
    if (payload) {
      const { deliveryCharge, deliveryDuration, deliveryMiles, boonsQuoteId } = payload;

      return { ...state, deliveryMiles, deliveryDuration, deliveryCharge, boonsQuoteId };
    }
  },
  CART_ACTION_SET_DOORDASH_DELIVERY_ESTIMATE: (state: ICartItems, payload: IDoordashDeliveryEstimate) => {
    if (payload) {
      const { deliveryCharge, deliveryMiles, doordashQuoteId } = payload;

      return { ...state, deliveryMiles, deliveryCharge, doordashQuoteId, doordashCharge: deliveryCharge };
    }
  },
  CART_ACTION_SET_DELIVERY_META_DATA: (state: ICartItems, payload: { deliveryProvider: DeliveryProvider; deliveryMode: DeliveryMode }) => {
    if (payload) {
      const { deliveryMode, deliveryProvider } = payload;

      return { ...state, deliveryMode, deliveryProvider };
    }
  },
  CART_ACTION_SET_DELIVERY_ADDRESS: (state: ICartItems, payload: IAddress | null) => {
    return { ...state, deliveryAddress: payload };
  },
  CART_ACTION_SET_DELIVERY_NOTE: (state: ICartItems, payload: string) => {
    return { ...state, deliveryNote: payload };
  },
  CART_ACTION_SET_APARTMENT_NUMBER: (state: ICartItems, payload: string) => {
    return { ...state, apartmentNumber: payload };
  },
  TOGGLE_CART: (state: ICartItems) => ({ ...state, isCartOpen: !state.isCartOpen }),
  CART_ACTION_SET_COUPON_DATA: (state: ICartItems, payload: Partial<Coupon>) => {
    if (payload) {
      return { ...state, couponData: payload, isDiscountApplied: true };
    }

    return { ...state, couponData: payload, isDiscountApplied: false };
  },
  CART_ACTION_TOGGLE_COUPON_AUTO_APPLY: (state: ICartItems, payload: boolean) => {
    return {
      ...state,
      isAutoApplyCouponAllowed: payload
    };
  },
  CART_ACTION_SET_COUPON_TOKEN: (state: ICartItems, payload: string) => ({ ...state, couponToken: payload }),
  CART_ACTION_SET_DISCOUNT: (state: ICartItems, payload: Big | null) => ({ ...state, discount: payload }),
  CART_ACTION_REMOVE_DISCOUNT: (state: ICartItems) => ({ ...state, discount: null, couponData: null, isDiscountApplied: false, couponToken: null }),
  CART_ACTION_DISABLE_PREDISCOUNT: (state: ICartItems, payload: boolean) => ({ ...state, isPrediscountEnabled: payload }),
  CART_ACTION_IS_PHONE_ORDER: (state: ICartItems, payload: boolean) => ({ ...state, phoneOrder: payload }),

  CART_ACTION_SET_DROP_OFF_INSTRUCTION: (state: ICartItems, payload: string) => {
    return { ...state, dropOffType: payload };
  },

  CART_ACTION_EVALUATE_CHARGES: (state: ICartItems, payload: any) => {
    const { couponData, orderType, deliveryProvider, deliveryMiles, deliveryCharge, tip, deliveryAddress, isPrediscountEnabled, doordashCharge } = state;

    const { subtotal, subtotalWithoutPrediscount, subtotalToDiscount, restaurant, serviceFeePayload, calledBy, bizPlan } = payload;

    Bugfender.info('CART_ACTION_EVALUATE_CHARGES calledBy', calledBy);

    Bugfender.info('current state', JSON.stringify(state));
    Bugfender.info('payload', JSON.stringify(payload));

    // Lokobee service fee.
    // Initially it will be zero.
    let lokobeeServiceFee = Big(0);

    let deliveryFee: Big = Big(0);

    let baseAmount = isPrediscountEnabled ? subtotal : subtotalWithoutPrediscount;

    let baseAmountToDiscount = isPrediscountEnabled ? subtotalToDiscount : subtotalWithoutPrediscount;

    const taxRate = restaurant.getTaxRate;

    if (orderType === 'DELIVERY') {
      const { freeDeliveryAmount, fees } = restaurant.getDeliverySettings;

      if (deliveryProvider && deliveryProvider === 'SELF') {
        deliveryFee =
          orderType === 'DELIVERY' && deliveryAddress
            ? calculateDistanceCharge({
                subtotal: baseAmount,
                deliveryMiles,
                freeDeliveryAmount,
                fees
              })
            : Big(0);
      } else if (deliveryProvider && deliveryProvider === 'DOORDASH_CLASSIC') {
        if (freeDeliveryAmount) {
          if (baseAmount.gt(Big(freeDeliveryAmount))) {
            if (doordashCharge.gt(Big(deliveryDiscount))) {
              deliveryFee = doordashCharge.sub(Big(deliveryDiscount));
            } else {
              deliveryFee = Big(0);
            }
          } else {
            deliveryFee = doordashCharge;
          }
        } else {
          deliveryFee = doordashCharge;
        }
      }
    }

    Bugfender.info('deliveryCharge : ', deliveryCharge?.toString());

    const { discountedPrice, subtotalAfterDiscount } = getRestaurantDiscountPriceAndSubtotalAfterDiscount({
      couponData,
      subtotalBeforeDiscount: baseAmount,
      subtotalToDiscount: baseAmountToDiscount
    });

    Bugfender.info('discountedPrice : ', discountedPrice?.toString());
    Bugfender.info('subtotalAfterDiscount : ', subtotalAfterDiscount.toString());

    const { tax1, totalAfterTax } = calculateTaxRate({
      subtotal: subtotalAfterDiscount,
      couponData,
      orderType,
      deliveryCharge: deliveryFee,
      discountPrice: discountedPrice,
      taxRate
    });

    Bugfender.info('tax1 : ', tax1.toString());
    Bugfender.info('totalAfterTax : ', totalAfterTax.toString());

    if (!serviceFeePayload) {
      // If service is not in state ie - it's null then we calculate lokobee fee.
      // Else we assign value already in store.

      if (!isEmpty(bizPlan)) {
        lokobeeServiceFee = calculateServiceFee({ bizPlan, orderType, deliveryProvider, subtotal: subtotalAfterDiscount });
      } else {
        if (orderType !== 'DINING') {
          lokobeeServiceFee = calculateLokobeeFee({
            subtotal: subtotalAfterDiscount,
            lokobeeFeePercent: restaurant.lokobeeFeePercent,
            lokobeeFeeLimit: restaurant.lokobeeFeeLimit,
            lokobeeFee: restaurant?.lokobeeFee
          });
        }
      }
    } else {
      lokobeeServiceFee = serviceFeePayload;
    }

    Bugfender.info('lokobeeServiceFee : ', lokobeeServiceFee.toString());

    const totalPriceAfterTaxWithTip = tip ? totalAfterTax?.add(tip) : totalAfterTax;

    Bugfender.info('totalPriceAfterTaxWithTip : ', totalPriceAfterTaxWithTip.toString());

    // Add service fee to final price.
    const finalPrice = restaurant?.lokobeeFee ? totalPriceAfterTaxWithTip.add(lokobeeServiceFee) : totalPriceAfterTaxWithTip;

    Bugfender.info('tax', tax1.toString());
    Bugfender.info('subtotal', baseAmount.toString());
    Bugfender.info('subtotalToDiscount', subtotalToDiscount.toString());
    Bugfender.info('subtotalWithoutPrediscount', subtotalWithoutPrediscount.toString());
    Bugfender.info('subtotalToDiscount', subtotalToDiscount.toString());
    Bugfender.info('subtotalAfterDiscount', subtotalAfterDiscount.toString());
    Bugfender.info('totalPriceAfterTax', finalPrice.toString());
    Bugfender.info('discount', discountedPrice?.toString());
    Bugfender.info('deliveryCharge', deliveryCharge?.toString());
    Bugfender.info('serviceFee', lokobeeServiceFee?.toString());

    return {
      ...state,
      tax: tax1,
      subtotal: baseAmount,
      subtotalToDiscount,
      subtotalWithoutPrediscount,
      subtotalAfterDiscount,
      totalPriceAfterTax: finalPrice,
      cartRestaurant: restaurant,
      discount: discountedPrice,
      deliveryCharge: deliveryFee,
      serviceFee: lokobeeServiceFee
    };
  },
  CART_ACTION_SET_FROM_LOCAL_STORAGE: (state: ICartItems, payload: any) => {
    return { ...state, ...payload };
  },
  CART_ACTION_SET_CART_SESSION_ID: (state: ICartItems) => {
    const cartSessionUuid: string = uuidv4();
    if (!state.cartSessionId) {
      state.cartSessionId = cartSessionUuid;
    }
    return { ...state };
  }
};

export default {};
