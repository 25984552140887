import React from 'react';
import { Typography, Box, Grid, useMediaQuery } from '@material-ui/core';
import { useStyles } from './style';
import S3Image from 'components/S3Image';
import PopularIcon from 'assets/img/popular_icon.svg';
import LiquorIcon from 'assets/img/liquor.svg';
import StarIcon from '@material-ui/icons/Star';
import defaultImage from 'assets/img/photo_coming_soon.jpg';
interface IProps {
  title: string;
  description: string;
  imgSrc: string | null | undefined;
  menuPrice?: any;
  points?: string;
  isPopular?: boolean;
  isAlcohol: boolean;
  currency?: 'USD' | 'POINT';
  rateScore: number;
  rateCount: number;
}

const DishDetails: React.FC<IProps> = ({ title, imgSrc, menuPrice = null, points = null, isPopular, isAlcohol, currency = 'USD', rateScore, rateCount }) => {
  const classes = useStyles();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const getPrice = () => {
    if (currency === 'USD' && menuPrice) {
      return `$${menuPrice.price}`;
    }

    if (currency === 'POINT' && points) {
      return `${points} PTS`;
    }

    return '';
  };

  return (
    <Box height="100%" className={classes.root} boxShadow={0} borderRadius={isDesktop ? 8 : 0}>
      <Grid container={true} className={classes.dishContainer} direction="row-reverse">
        {isDesktop ? (
          <>
            {imgSrc && (
              <Grid item={true} md={3}>
                <Box margin={1}>
                  <S3Image className={classes.image} src={imgSrc} defaultImage={defaultImage} />
                </Box>
              </Grid>
            )}
            <Grid item={true} md={imgSrc ? 9 : 12}>
              <Box padding={3}>
                <Typography variant="body1" noWrap={true} component="div">
                  <Box display="flex" alignItems="center" justifyItems="center">
                    {title}
                    {isPopular && <img src={PopularIcon} alt="Popular" />}
                  </Box>
                </Typography>
                {isAlcohol && (
                  <Box>
                    <img src={LiquorIcon} alt="" />
                  </Box>
                )}

                <Box display="flex" justifyContent="flex-start" alignItems="baseline">
                  <Box className={classes.priceItem}>
                    <Box display="flex" alignItems="center">
                      {!!menuPrice && !!menuPrice.maxDiscountPercent && (
                        <Box className={classes.priceDiscount}>
                          <Typography variant="body2" className={classes.rateScore} noWrap={true}>
                            {menuPrice.maxDiscountPercent !== menuPrice.discountPercent && 'Up To'}&nbsp;
                            {menuPrice.maxDiscountPercent}% OFF
                          </Typography>
                        </Box>
                      )}

                      {rateCount !== 0 && rateScore !== 0 && (
                        <Box alignItems="center" display="flex">
                          <StarIcon color="primary" fontSize="inherit" />
                          <Typography variant="body2" className={classes.rateScore} noWrap={true}>
                            {`${rateScore} (${rateCount})`}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box display="flex">
                      {!!menuPrice && !!menuPrice.strikedPrice && (
                        <Box className={classes.priceItem}>
                          <Typography className={classes.priceStriked} variant="subtitle2" noWrap={true}>
                            <Box fontWeight="bolder">${menuPrice.strikedPrice}</Box>
                          </Typography>
                        </Box>
                      )}
                      <Typography variant="subtitle2" noWrap={true}>
                        <Box fontWeight="bolder">{getPrice()}</Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            {imgSrc && (
              <Grid item={true} sm={4} xs={4}>
                <Box margin={1}>
                  <S3Image className={classes.image} src={imgSrc} defaultImage={defaultImage} />
                </Box>
              </Grid>
            )}
            <Grid item={true} sm={imgSrc ? 8 : 12} xs={imgSrc ? 8 : 12}>
              <Box padding={1}>
                <Typography variant="body1" component="div">
                  <Box display="flex" alignItems="center" justifyItems="center">
                    {title}
                    {isPopular && <img src={PopularIcon} alt="Popular" />}
                  </Box>
                </Typography>
                {isAlcohol && (
                  <Box>
                    <img src={LiquorIcon} alt="" />
                  </Box>
                )}
                <Box display="flex" justifyContent="start" alignItems="baseline">
                  <Box className={classes.priceItem}>
                    <Box display="flex" alignItems="center">
                      {!!menuPrice && !!menuPrice.maxDiscountPercent && (
                        <Box className={classes.priceDiscount}>
                          <Typography variant="body2" className={classes.rateScore} noWrap={true}>
                            {menuPrice.maxDiscountPercent !== menuPrice.discountPercent && 'Up To'}&nbsp;
                            {menuPrice.maxDiscountPercent}% OFF
                          </Typography>
                        </Box>
                      )}

                      {rateCount !== 0 && rateScore !== 0 && (
                        <Box display="flex">
                          <StarIcon color="primary" fontSize="inherit" />
                          <Typography variant="body2" className={classes.rateScore} noWrap={true}>
                            {`${rateScore} (${rateCount})`}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box display="flex" paddingY={0.5}>
                      {!!menuPrice && !!menuPrice.strikedPrice && (
                        <Box className={classes.priceItem}>
                          <Typography className={classes.priceStriked} variant="subtitle2" noWrap={true}>
                            <Box fontWeight="bolder">${menuPrice.strikedPrice}</Box>
                          </Typography>
                        </Box>
                      )}
                      <Typography variant="subtitle2" noWrap={true}>
                        <Box fontWeight="bolder">{getPrice()}</Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default DishDetails;
