import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    height: '100%',
    padding: 0,
    '&:first-child': {
      padding: 0
    }
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer'
  },
  mapContainer: {
    padding: theme.spacing(1, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2)
    }
  },
  infoHoursBtn: {
    textTransform: 'capitalize',

    marginRight: theme.spacing(1),
    display: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center'
  },

  restaurantDetail: {
    color: grey[500],
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    fontSize: '6px'
  },

  locationIcon: {
    backgroundColor: theme.palette.secondary.dark,
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    color: 'white'
  },
  phoneIcon: {
    backgroundColor: theme.palette.success.main,
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    color: 'white'
  }
}));
