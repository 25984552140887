import React from 'react';
import { useStyles } from '../style';
import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Paragraph from 'components/Paragraph';
import StarIcon from '@material-ui/icons/Star';
import PopularIconWhite from 'assets/img/popular_white.svg';
import LiquorIconWhite from 'assets/img/liquor_white.svg';

interface IProps {
  dishTitle: string | null;
  dishDescription: string | null;
  handleClose: () => void;
  imgSrc: string | null;
  isHeaderExpanded: boolean;
  showImg: () => void;
  hideImg: () => void;
  isPopular: Boolean;
  isAlcohol: Boolean;
  rateScoreValue: number;
  rateCountValue: number;
}

const ExpandingHeader: React.FC<IProps> = ({ dishTitle, dishDescription, isPopular, handleClose, isHeaderExpanded, imgSrc, showImg, isAlcohol, rateScoreValue, rateCountValue }) => {
  const classes = useStyles();
  return (
    <Box className={classes.dishHeader} style={{ backgroundImage: `url('${imgSrc}')` }}>
      {isHeaderExpanded && (
        <>
          {imgSrc ? (
            <Box position="absolute" right={16} top={16} borderRadius={4} boxShadow={5} bgcolor="rgba(255,255,255,0.8)" padding={0.5}>
              <CloseIcon onClick={handleClose} color="inherit" style={{ cursor: 'pointer', display: 'flex' }} />
            </Box>
          ) : (
            <Box paddingX={1.5} paddingTop={1} display="flex" justifyContent="flex-end">
              <CloseIcon onClick={handleClose} color="inherit" style={{ cursor: 'pointer', display: 'flex' }} />
            </Box>
          )}{' '}
        </>
      )}

      <Typography className={classes.dishTitle} variant="h6">
        <Box display="flex" alignItems="center" justifyItems="center">
          {dishTitle}
          {isPopular && (
            <Box marginY={1} display="flex" alignItems="center" justifyItems="center">
              <img src={PopularIconWhite} className={classes.smallIcon} alt="Popular" />
            </Box>
          )}
        </Box>
      </Typography>
      {isAlcohol && (
        <Box paddingX={2}>
          <img src={LiquorIconWhite} alt="" />
        </Box>
      )}
      <Box>
        <Paragraph text={dishDescription + ' ' || ''} limit={40} className={classes.dishDescription} />
        {rateScoreValue > 0 && rateCountValue > 0 && (
          <Box display="flex" alignItems="center">
            <StarIcon color="primary" fontSize="inherit" />
            <Typography className={classes.rateScore} variant="body2" noWrap={true}>
              {`${rateScoreValue} (${rateCountValue})`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ExpandingHeader;
