import gql from 'graphql-tag';
import { Coupon } from 'graphql/fragments/coupon.fragments';

export const getCoupons = gql`
  query getCoupons($input: GetCouponsByBizIdInput!) {
    getCoupons(input: $input) {
      ...Coupon
    }
  }
  ${Coupon}
`;

export const getCouponByCouponId = gql`
  query getCouponByCouponId($input: GetCouponByCouponIdInput!) {
    getCouponByCouponId(input: $input) {
      ...Coupon
    }
  }
  ${Coupon}
`;

export const verifyCouponQuery = gql`
  query verifyCoupon($input: VerifyCouponInput) {
    verifyCoupon(input: $input) {
      success
      error
      coupon {
        id
        discountType
        discountValue
        code
        maxDiscount
        isPrivate
        orderType
        dayOfWeek
        isSingleUse
        startDate
        endDate
      }
      token
    }
  }
`;

export const verifyGroceryCouponQuery = gql`
  query verifyGroceryCouponQuery($input: VerifyGroceryCouponInput) {
    verifyGroceryCoupon(input: $input) {
      success
      error
      coupon {
        id
        discountType
        discountValue
        code
        subtotalThreshold
        maxDiscount
        isPrivate
        orderType
        dayOfWeek
        startDate
        endDate
      }
      token
    }
  }
`;
