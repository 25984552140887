import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import VeryDissatisfiedIcon from 'assets/img/emojis/1.svg';
import SatisfiedAltIcon from 'assets/img/emojis/3.svg';
import SatisfiedIcon from 'assets/img/emojis/4.svg';
import VerySatisfiedIcon from 'assets/img/emojis/5.svg';
import VeryDissatisfiedIconColor from 'assets/img/emojis/color1.svg';
import SatisfiedAltIconColor from 'assets/img/emojis/color3.svg';
import SatisfiedIconColor from 'assets/img/emojis/color4.svg';
import VerySatisfiedIconColor from 'assets/img/emojis/color5.svg';
import { useStyles } from './style';

interface IProps {
  value: number;
  onChange: (data: number) => void;
}

const RatingWidget: React.FC<IProps> = ({ value, onChange }) => {
  const classes = useStyles();

  const [selectedEmoji, setSelectedEmoji] = useState(value ? value : 0);

  const handleEmojiClick = (event: any) => {
    const rating = parseInt(event.target.id);
    setSelectedEmoji(rating);
    onChange(rating);
  };

  useEffect(() => {
    setSelectedEmoji(value);
  }, [value, setSelectedEmoji]);

  const getContent = () => {
    return (
      <Box className={classes.root} display="flex" justifyContent="space-evenly">
        <img alt="" id="1" onClick={handleEmojiClick} src={selectedEmoji === 1 ? VeryDissatisfiedIconColor : VeryDissatisfiedIcon} className={classes.icon} />
        <img alt="" id="3" onClick={handleEmojiClick} src={selectedEmoji === 3 ? SatisfiedAltIconColor : SatisfiedAltIcon} className={classes.icon} />
        <img alt="" id="4" onClick={handleEmojiClick} src={selectedEmoji === 4 ? SatisfiedIconColor : SatisfiedIcon} className={classes.icon} />
        <img alt="" id="5" onClick={handleEmojiClick} src={selectedEmoji === 5 ? VerySatisfiedIconColor : VerySatisfiedIcon} className={classes.icon} />
      </Box>
    );
  };

  return <Box>{getContent()}</Box>;
};

export default RatingWidget;
