import { gql } from 'apollo-boost';

export const getBuyerDeliveryOrders = gql`
query getBuyerDeliveryOrders($input: GetBuyerOngoingOrdersInput!) {
  getBuyerOngoingOrders(input: $input) {
      id
      orderNumber
      type
      deliveryProvider
      noOfBags
      specialHandlingInstruction
      cookingTimeMins
      expectTime
      deliveryStatusBoons
      deliveryTimelineBoons {
        id
        createdAt
        status
        title
        msg
      }
    }
  }
`;
