import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './style';
interface IProps {
  text: string;
  onBack: () => void;
  closeIconId?: string;
}

const DialogHeader: React.FC<IProps> = ({ text, onBack, closeIconId }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="h6">
        {text}
      </Typography>
      <CloseIcon id={closeIconId ? closeIconId : 'close'} className={classes.button} onClick={onBack} color="inherit" />
    </Box>
  );
};

export default DialogHeader;
