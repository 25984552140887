import React from 'react';
import { Switch, Route } from 'react-router-dom';
import MainContentRoutes from 'pages/Routes';
import Rewards from 'pages/Rewards-Outdated';
import MyCart from 'pages/MyCart';
import Profile from 'pages/Profile';
import AccountModal from 'components/AccountModal';
import PhoneVerificationDialog from 'components/AccountModal/PhoneVerificationDialog';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import UserAgreement from 'pages/UserAgreement';

import PaymentRoutes from 'pages/Payment';
import OrderSuccessPage from 'pages/Payment/OrderSuccessPage';
import UpdatedTerms from 'components/UpdatedTerms';
import LokoAlert from 'components/LokoAlert';
import RestaurantDeals from 'pages/RestaurantDeals';

const AppRoutes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/payment" component={PaymentRoutes} />
        <Route path="/success" component={OrderSuccessPage} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/deals/:restaurantId" component={RestaurantDeals} />
        <Route path="/useragreement" component={UserAgreement} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/cart" component={MyCart} />
        <Route path="/profile" component={Profile} />
        <Route path="/" component={MainContentRoutes} />
      </Switch>
      <AccountModal />
      <PhoneVerificationDialog />
      <UpdatedTerms />
      <LokoAlert />
    </>
  );
};

export default AppRoutes;
