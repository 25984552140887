import React, { useState, ReactElement } from 'react';
import { Box, Dialog, useMediaQuery, Theme } from '@material-ui/core';
import RewardsHome from './RewardHome';
import RedeemPoints from './RedeemPoints';
import RedeemRequested from './RedeemRequested';
import RedeemSuccess from './RedeemSuccess';
import RedeemSkipped from './RedeemSkipped';
import BottomNavigation from 'components/BottomNavigation';

const Rewards: React.FC = () => {
  const [displayComponent, setDisplayComponent] = useState<ReactElement | null>(null);

  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const title = 'The Green Barn';
  const currentPoints = 200;
  const subTotal = 83;
  const maxRedeemablePoints = 40;

  const [pendingPoints] = useState(0);

  React.useEffect(() => {
    const askRedeemPoints = () => {
      setDisplayComponent(<RedeemPoints title={title} subTotal={subTotal} maxRedeemablePoints={maxRedeemablePoints} requestRedeem={requestRedeem} skipRedeem={skipRedeem} />);
    };
    const skipRedeem = () => {
      setDisplayComponent(<RedeemSkipped title={title} currentPoints={currentPoints} pendingPoints={pendingPoints + subTotal} claimPoints={subTotal} />);
    };

    setDisplayComponent(<RewardsHome title={title} currentPoints={currentPoints} pendingPoints={pendingPoints} askRedeemPoints={askRedeemPoints} />);
  }, [pendingPoints]);

  const requestRedeem = (redeemPoints: number) => {
    setDisplayComponent(<RedeemRequested title={title} redeemPoints={redeemPoints} />);
    setTimeout(() => {
      setDisplayComponent(<RedeemSuccess title={title} redeemPoints={redeemPoints} claimPoints={subTotal - redeemPoints} />);
    }, 2000);
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm" disableBackdropClick={true} fullScreen={fullScreen}>
      {displayComponent}
      <Box>
        <BottomNavigation />
      </Box>
    </Dialog>
  );
};

export default Rewards;
