import { Box, Button, CircularProgress, Dialog, Divider, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import { useStyles } from './style';
import { getIn, useFormik } from 'formik';
import { useUpdateUserMutation } from 'graphql/hooks/user.hooks';
import { getLokobeeUserQuery } from 'graphql/query';
import { useAlert } from 'hooks';
import React, { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { isEqual } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import UpdatePhoneNumber from 'components/UpdatePhoneNumber';

//TODO: user guest
interface IProps {
  totalPriceAfterTax?: string | undefined;
  onHandleClose: () => void;
}

function ReviewPhoneNumber({ totalPriceAfterTax, onHandleClose }: IProps) {
  const classes = useStyles();
  const {
    dispatch,
    state: { user, deliveryProvider, orderType }
  } = useStore();

  const [open, setOpen] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const onUpdateHandleClose = () => {
    setOpen(false);
  };

  const { updateUser, loading } = useUpdateUserMutation();

  const onPrivacyClick = () => {
    dispatch({
      type: 'UI_ACTION_FROM_REVIEW_PAGE',
      payload: true
    });

    dispatch({
      type: 'UI_ACTION_PRIVACY_POPUP',
      payload: true
    });
  };

  const setUserInStore = (phoneNumber: string) => {
    dispatch({
      type: 'USER_ACTION_SET_CONTACT_DETAILS',
      payload: {
        phoneNumber
      }
    });
  };
  const history = useHistory();

  const { lokoAlert } = useAlert();

  const validate = async (values: { phonenumber: string; rephonenumber: string }) => {
    const errors: any = {};
    console.log();
    if (!values.phonenumber && !values.rephonenumber) {
      errors.phone = 'no error';
    } else if (!values.phonenumber && values.rephonenumber) {
      errors.phone = 'Please Enter Phone Number';
    } else if (values.phonenumber !== values.rephonenumber) {
      errors.phone = 'Values did not match';
    } else if (!isValidPhoneNumber(values.phonenumber)) {
      errors.phone = 'Enter valid phone number';
    }
    return errors;
  };

  const errorCheck = () => {
    if (getIn(formik.errors, 'phone') === 'no error') {
      return false;
    } else if (!getIn(formik.errors, 'phone')) {
      return false;
    } else {
      return true;
    }
  };

  const rewardDishCheck = () => {
    if (totalPriceAfterTax && parseInt(totalPriceAfterTax) === 0) {
      // This is a reward dish so not payment.
      history.push('/payment/onsite');
    } else if (totalPriceAfterTax) {
      history.push('/payment');
    } else {
      onHandleClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      phonenumber: '',
      rephonenumber: ''
    },
    // enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      const { phonenumber } = values;

      if (rememberMe === true) {
        formik.setSubmitting(true);
        try {
          const response = await updateUser({
            variables: {
              input: {
                orderPhoneNumber: phonenumber
              }
            },
            update: (cache) => {
              const { getLokobeeUser: oldData }: any = cache.readQuery({
                query: getLokobeeUserQuery
              });

              cache.writeQuery({
                query: getLokobeeUserQuery,
                data: { getLokobeeUser: { ...oldData, orderPhoneNumber: phonenumber } }
              });
            }
          });
          if (!response.errors) {
            setUserInStore(phonenumber);
            formik.setSubmitting(true);
            rewardDishCheck();
          } else {
            lokoAlert('Something went wrong.');
          }
        } catch (e) {
          formik.setSubmitting(false);
          lokoAlert('Something went wrong.');
        }
      } else {
        setUserInStore(phonenumber);
        rewardDishCheck();
      }
    }
  });

  const onReviewDone = () => {
    if (formik.dirty && !isEqual(formik.initialValues, formik.values)) {
      // * calling mutation
      formik.submitForm();
    } else {
      rewardDishCheck();
    }
  };

  return (
    <Dialog open={true} fullScreen>
      <DialogHeader text="Review Contact Information" onBack={onHandleClose} />
      <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" height="100%">
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box display="flex" alignItems="center" paddingX={6} paddingY={2}>
              <Typography variant="caption" align="center">
                The following information will be used to communicate the status of the current order.
              </Typography>
            </Box>
            <Box paddingX={3}>
              <Box paddingY={2}>
                <Typography variant="body1">
                  Email Address: <span className={classes.grey}>{user.email}</span>
                </Typography>
              </Box>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setOpen(true);
                        }}
                        onMouseDown={() => {
                          setOpen(true);
                        }}
                        edge="end">
                        <EditIcon color="secondary" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="Phone Number"
                variant="outlined"
                defaultValue={user.orderPhoneNumber}
                margin="normal"
                fullWidth
                inputProps={{ readOnly: true }}
              />
            </Box>
            <Box paddingTop={2} paddingBottom={2}>
              {deliveryProvider === 'DOORDASH_CLASSIC' && orderType === 'DELIVERY' ? (
                <Box display="flex" padding={3} flexDirection="column" bgcolor="#eeeeee">
                  <Typography variant="caption">
                    By providing a mobile number, you give DoorDash and Lokobee permission to contact you using automated text messages to provide transactional messages such as order status updates
                  </Typography>
                  <Typography color="primary" onClick={onPrivacyClick}>
                    Learn More
                  </Typography>
                </Box>
              ) : (
                <Box paddingX={6}>
                  <Divider />
                </Box>
              )}
            </Box>
          </Box>
        </form>
      </Box>
      <Box paddingX={3} paddingBottom={3} position="relative">
        <Button variant="contained" color="primary" fullWidth disabled={errorCheck() || formik.isSubmitting} onClick={() => onReviewDone()}>
          {totalPriceAfterTax ? 'Continue' : 'Save'}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Box>
      {open && <UpdatePhoneNumber totalPriceAfterTax={totalPriceAfterTax} onHandleClose={onUpdateHandleClose} />}
    </Dialog>
  );
}

export default ReviewPhoneNumber;
