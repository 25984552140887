import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    top: '0',
    left: '0',
    borderRadius: theme.spacing(1)
  },
  description: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    padding: theme.spacing(0.5),
    backgroundColor: 'rgba(255,255,255,0.7)',
    borderRadius: '5px'
  },
  discount: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    padding: theme.spacing(0.5),
    // backgroundColor: 'rgb(56,142,60, 0.8)',
    backgroundColor: theme.palette.success.dark,
    opacity: "0.9",
    borderRadius: '5px',
    color: '#fff'
  }
}));
