import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    textTransform: 'initial'
  },
  btnContainer: {
    maxWidth: '240px',
    width: '100%',
    margin: '0 auto'
  }
}));
