import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  textField: {
    height: 40
  },
  boldText: {
    fontWeight: 'bolder'
  },
  dialog: {
    '& .MuiPaper-root': {
      height: '100%'
    }
  },
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#fff'
  },
  loader: {
    color: '#fff',
    width: '25px',
    height: '25px'
  },
  applyCode: {
    cursor: 'pointer'
  },
  paymentButton: {
    borderRadius: 0,
    flex: '1 1 0'
  },
  couponGroup: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    background: '#fff',
    top: '-2px',
    color: '#000',
    zIndex: 1,
    position: 'sticky'
  },
  successBg: {
    backgroundColor: theme.palette.success.main,
    color: '#fff'
  }
}));
