import React from 'react';
import { useMediaQuery, Dialog } from '@material-ui/core';
import Transition from 'components/Transition';
import Content from './Content';
import { useStyles } from './style';

interface IProps {
  id: string | null;
  isOpen: boolean;
  handleClose: () => void;
  currency?: 'USD' | 'POINT';
}

const DishDialog: React.FC<IProps> = ({ id, isOpen, handleClose, currency = 'USD' }) => {
  const classes = useStyles();

  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  return (
    <Dialog className={classes.dialog} open={isOpen} fullScreen={fullScreen} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
      <Content id={id} handleClose={handleClose} currency={currency} />
    </Dialog>
  );
};

export default DishDialog;
