import React from 'react';
import { useStore } from 'store';
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core';
import { useStyles } from './style';
import Transition from 'components/Transition';
import PhoneVerification from './PhoneVerification';
import FormDialog from 'components/FormDialog';

const PhoneVerificationDialog: React.FC = () => {
  const classes = useStyles();

  const {
    state: {
      ui: { isPhoneVerificationPopupOpen }
    },
    dispatch
  } = useStore();

  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const onClose = () => {
    dispatch({
      type: 'UI_ACTION_PHONE_VERIFICATION_POPUP',
      payload: false
    });
  };

  return (
    <Dialog open={isPhoneVerificationPopupOpen} fullWidth={true} TransitionComponent={Transition} fullScreen={fullScreen}>
      <DialogContent className={classes.dialogContent}>
        <FormDialog title="Phone verification" onBack={onClose}>
          <PhoneVerification />
        </FormDialog>
      </DialogContent>
    </Dialog>
  );
};

export default PhoneVerificationDialog;
