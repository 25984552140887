import { makeStyles, Theme } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';

interface IProps {
  gradientColor: 'warning' | 'success' | 'error';
}

export const useStyles = makeStyles((theme: Theme) => {
  const gradients = {
    warning: 'linear-gradient(180deg, ' + amber[600] + ' 10%, ' + amber[900] + ' 30%)',
    success: 'linear-gradient(180deg, ' + green.A400 + ' 10%, ' + teal[700] + ' 30%)',
    error: 'linear-gradient(180deg, ' + theme.palette.error.light + ' 10%, ' + theme.palette.error.dark + ' 30%)'
  };

  return {
    gradientContainer: (props: IProps) => ({
      background: gradients[props.gradientColor],
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      padding: 0
    }),
    section1Container: {
      height: '35%',
      width: '100%',
      margin: 0,
      padding: 0
    },
    curvedContainer: {
      height: '65%',
      width: '100%',
      background: 'white',
      borderRadius: '80px 80px 0 0',
      margin: 0,
      padding: 0
    }
  };
});
