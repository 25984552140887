import React from 'react';
import useRestaurant from 'hooks/restaurant/useRestaurant';
import RestaurantHome from './RestaurantHome';

const Restaurant: React.FC = () => {
  const { isValid } = useRestaurant();

  if (isValid) {
    return <RestaurantHome />;
  }

  return null;
};

export default Restaurant;
