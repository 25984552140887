import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { useStyles } from './style';

const NotFound: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const onBack = () => {
    history.push('/');
  };

  return (
    <Box height="100%" display="flex" bgcolor="#fff" flexDirection="column">
      <Box flex={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center" padding={2}>
        <Box width="100%" textAlign="center" paddingY={1}>
          <SentimentVeryDissatisfiedIcon className={classes.checkIcon} />
        </Box>
        <Box width="100%" textAlign="center" paddingY={1}>
          <Typography variant="h2">Page Not Found</Typography>
        </Box>
        <Box width="100%" textAlign="center" paddingY={1}>
          <Typography variant="body1">We can't seem to find the page you are looking for</Typography>
        </Box>
        <Box marginY={2}>
          <Button variant="contained" color="primary" onClick={onBack} fullWidth={true}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
