import Big from 'big.js';
import { IAddress } from 'components/AddressModal/types';
import { GroceryDropOffType, IGroceryDoordashDeliveryEstimate } from 'generated/custom';
import { GroceryCoupon, GroceryOrderType, GroceryStoreCartItem } from 'generated/types';
import GroceryStoreModel from 'models/GroceryStore';
import { Moment } from 'moment';
import { convertPriceTo } from 'util/number';
import { calculateDistanceCharge, calculateGroceryServiceFee, calculateLokobeeFee, calculateSubTotalAndTaxForGrocery, getGroceryDiscountPriceAndSubtotalAfterDiscount } from 'util/priceCalculator';

export interface IGroceryCartItems {
  grocery_subtotal: Big;
  grocery_tax: Big | null;
  grocery_totalPriceAfterTax: Big | null;
  grocery_tip: Big;
  grocery_serviceFee: Big | null;
  grocery_deliveryTime: number;
  grocery_orderNote: string;
  grocery_orderType: GroceryOrderType | null;
  grocery_deliveryAddress: IAddress | null;
  grocery_deliveryMiles: Big;
  grocery_deliveryCharge: Big;
  grocery_deliveryNote: string;
  grocery_isUserDefinedServiceFee: boolean;
  grocery_finalPrice: Big;
  grocery_isDiscountApplied: false;
  grocery_couponData: Partial<GroceryCoupon> | null;
  grocery_couponToken: string | null;
  grocery_discount: Big | null;
  grocery_subtotalAfterDiscount: Big;
  grocery_doordashQuoteId: string | null;
  grocery_dropOffType: GroceryDropOffType;
  grocery_apartmentNumber: string;
  grocery_selectedPickerDate: Moment | null;
  grocery_selectedPickerTime: Moment | null;
}

export const groceryCartInitialState: IGroceryCartItems = {
  grocery_subtotal: Big(0),
  grocery_totalPriceAfterTax: null,
  grocery_tax: null,
  grocery_serviceFee: null,
  grocery_tip: Big(0),
  grocery_deliveryTime: Date.now(),
  grocery_orderNote: '',
  grocery_orderType: null,
  grocery_deliveryAddress: null,
  grocery_deliveryMiles: Big(0),
  grocery_deliveryCharge: Big(0),
  grocery_deliveryNote: '',
  grocery_isUserDefinedServiceFee: false,
  grocery_finalPrice: Big(0),
  grocery_isDiscountApplied: false,
  grocery_couponData: null,
  grocery_couponToken: null,
  grocery_discount: null,
  grocery_subtotalAfterDiscount: Big(0),
  grocery_doordashQuoteId: null,
  grocery_dropOffType: GroceryDropOffType.InHand,
  grocery_apartmentNumber: '',
  grocery_selectedPickerDate: null,
  grocery_selectedPickerTime: null
};

export const groceryCartActions = {
  GROCERY_CART_ACTION_SET_SUBTOTAL: (state: IGroceryCartItems, payload: Big | null) => {
    return { ...state, grocery_subtotal: payload };
  },
  GROCERY_CART_ACTION_SET_TOTALPRICE_AFTER_TAX: (state: IGroceryCartItems, payload: Big | null) => {
    return { ...state, grocery_totalPriceAfterTax: payload };
  },
  GROCERY_CART_ACTION_SET_TAX: (state: IGroceryCartItems, payload: Big | null) => {
    return { ...state, grocery_tax: payload };
  },
  GROCERY_CART_ACTION_TIP_AMOUNT: (state: IGroceryCartItems, payload: Big | null) => {
    if (payload) {
      return { ...state, grocery_tip: payload };
    }
  },
  GROCERY_CART_ACTION_SET_SERVICE_FEE: (state: IGroceryCartItems, payload: Big) => {
    if (payload) {
      return { ...state, grocery_serviceFee: payload, grocery_isUserDefinedServiceFee: true };
    }
  },
  GROCERY_CART_ACTION_SET_DELIVERY_TIME: (state: IGroceryCartItems, payload: number | string) => {
    if (payload) {
      return { ...state, grocery_deliveryTime: payload };
    }
  },
  GROCERY_CART_ACTION_RESET_DELIVERY_TIME: (state: IGroceryCartItems) => {
    return { ...state, grocery_selectedPickerDate: null, grocery_selectedPickerTime: null, grocery_deliveryTime: Date.now() };
  },
  GROCERY_CART_ACTION_SET_PICKER_DATE: (state: IGroceryCartItems, payload: Moment | null) => {
    return { ...state, grocery_selectedPickerDate: payload };
  },
  GROCERY_CART_ACTION_SET_PICKER_TIME: (state: IGroceryCartItems, payload: Moment | null) => {
    return { ...state, grocery_selectedPickerTime: payload };
  },
  GROCERY_CART_ACTION_SET_ORDER_NOTE: (state: IGroceryCartItems, payload: string) => {
    return { ...state, grocery_orderNote: payload };
  },
  GROCERY_CART_ACTION_SET_DROP_OFF_INSTRUCTION: (state: IGroceryCartItems, payload: string) => {
    return { ...state, grocery_dropOffType: payload };
  },
  GROCERY_CART_ACTION_SET_APARTMENT_NUMBER: (state: IGroceryCartItems, payload: string) => {
    return { ...state, grocery_apartmentNumber: payload };
  },
  GROCERY_CART_ACTION_SET_ORDER_TYPE: (state: IGroceryCartItems, payload: GroceryOrderType) => {
    if (payload === 'TAKEOUT') {
      return {
        ...state,
        grocery_orderType: payload,
        grocery_deliveryAddress: null,
        grocery_deliveryMiles: Big(0),
        grocery_deliveryCharge: Big(0),
        grocery_deliveryNote: '',
        grocery_dropOffType: GroceryDropOffType.InHand,
        grocery_apartmentNumber: '',
        grocery_doordashQuoteId: null,
        grocery_deliveryTime: Date.now(),
        grocery_selectedPickerDate: null,
        grocery_selectedPickerTime: null
      };
    }

    if (payload === 'DELIVERY') {
      return {
        ...state,
        grocery_orderType: payload,
        grocery_deliveryTime: Date.now(),
        grocery_selectedPickerDate: null,
        grocery_selectedPickerTime: null
      };
    }

    return { ...state, grocery_orderType: payload };
  },
  GROCERY_CART_ACTION_RESET_ORDER_TYPE_INFO: (state: IGroceryCartItems) => {
    return {
      ...state,
      grocery_orderType: null,
      grocery_deliveryAddress: null,
      grocery_deliveryMiles: Big(0),
      grocery_deliveryCharge: Big(0),
      grocery_deliveryNote: '',
      grocery_dropOffType: GroceryDropOffType.InHand,
      grocery_apartmentNumber: '',
      grocery_doordashQuoteId: null,
      grocery_deliveryTime: Date.now(),
      grocery_selectedPickerDate: null,
      grocery_selectedPickerTime: null
    };
  },
  GROCERY_CART_ACTION_SET_DELIVERY_CHARGE: (state: IGroceryCartItems, payload: Big | null) => {
    if (payload) {
      return { ...state, grocery_deliveryCharge: payload };
    }
  },
  GROCERY_CART_ACTION_SET_DELIVERY_MILES: (state: IGroceryCartItems, payload: Big | null) => {
    if (payload) {
      return { ...state, grocery_deliveryMiles: payload };
    }
  },
  GROCERY_CART_ACTION_SET_DOORDASH_DELIVERY_ESTIMATE: (state: IGroceryCartItems, payload: IGroceryDoordashDeliveryEstimate) => {
    if (payload) {
      const { deliveryCharge, deliveryMiles, doordashQuoteId } = payload;

      return { ...state, grocery_deliveryMiles: deliveryMiles, grocery_deliveryCharge: deliveryCharge, grocery_doordashQuoteId: doordashQuoteId };
    }
  },
  GROCERY_CART_ACTION_SET_DELIVERY_ADDRESS: (state: IGroceryCartItems, payload: IAddress | null) => {
    return { ...state, grocery_deliveryAddress: payload };
  },
  GROCERY_CART_ACTION_SET_DELIVERY_NOTE: (state: IGroceryCartItems, payload: string) => {
    return { ...state, grocery_deliveryNote: payload };
  },
  GROCERY_CART_ACTION_CLEAR_ALL: () => {
    return {
      ...groceryCartInitialState
    };
  },
  GROCERY_CART_ACTION_SET_COUPON_DATA: (state: IGroceryCartItems, payload: Partial<GroceryCoupon>) => {
    if (payload) {
      return { ...state, grocery_couponData: payload, grocery_isDiscountApplied: true };
    }

    return { ...state, grocery_couponData: payload, grocery_isDiscountApplied: false };
  },
  GROCERY_CART_ACTION_SET_COUPON_TOKEN: (state: IGroceryCartItems, payload: string) => ({ ...state, grocery_couponToken: payload }),
  GROCERY_CART_ACTION_SET_DISCOUNT: (state: IGroceryCartItems, payload: Big | null) => ({ ...state, grocery_discount: payload }),
  GROCERY_CART_ACTION_REMOVE_DISCOUNT: (state: IGroceryCartItems) => ({ ...state, grocery_discount: null, grocery_couponData: null, grocery_isDiscountApplied: false, grocery_couponToken: null }),
  GROCERY_CART_ACTION_EVALUATE_CHARGES: (
    state: IGroceryCartItems,
    payload: {
      groceryCartItems: GroceryStoreCartItem[];
      groceryStore: GroceryStoreModel | null;
    }
  ) => {
    const { groceryCartItems, groceryStore } = payload;

    const { grocery_orderType, grocery_deliveryAddress, grocery_deliveryMiles, grocery_tip, grocery_couponData, grocery_deliveryCharge } = state;

    const { freeDeliveryAmount, fees } = groceryStore?.deliverySetting || {};

    // All prices are in cents so ceiling to nearest INT

    const { subtotal, totalTax } = calculateSubTotalAndTaxForGrocery({ store: groceryStore, items: groceryCartItems });

    const deliveryProvider = groceryStore?.deliveryProvider;

    let deliveryFee: Big = Big(0);

    if (grocery_orderType === 'DELIVERY') {
      if (deliveryProvider && deliveryProvider === 'SELF') {
        deliveryFee =
          grocery_deliveryAddress && freeDeliveryAmount && fees
            ? calculateDistanceCharge({
                subtotal,
                deliveryMiles: grocery_deliveryMiles,
                freeDeliveryAmount,
                fees
              })
            : Big(0);
      } else if (deliveryProvider && deliveryProvider === 'DOORDASH_CLASSIC') {
        deliveryFee = grocery_deliveryCharge;
      }
    }

    const { discountedPrice, subtotalAfterDiscount } = getGroceryDiscountPriceAndSubtotalAfterDiscount({
      couponData: grocery_couponData,
      subtotalBeforeDiscount: Big(convertPriceTo(subtotal.valueOf(), 'DOLLAR')),
      subtotalToDiscount: Big(convertPriceTo(subtotal.valueOf(), 'DOLLAR'))
    });

    // Converting all prices to cents and ceiling to nearest INT
    const discountedPriceCents = discountedPrice ? Big(convertPriceTo(discountedPrice?.valueOf() || 0, 'CENT')) : null;
    const subtotalAfterDiscountCents = Big(convertPriceTo(subtotalAfterDiscount.valueOf(), 'CENT'));

    let lokobeeServiceFee = Big(0);

    if (groceryStore && groceryStore.bizPlan) {
      if (grocery_orderType) {
        lokobeeServiceFee = calculateGroceryServiceFee({
          bizPlan: groceryStore?.bizPlan,
          orderType: grocery_orderType,
          deliveryProvider: groceryStore.deliveryProvider,
          subtotal: subtotalAfterDiscountCents
        });
      }
    } else {
      lokobeeServiceFee = calculateLokobeeFee({
        subtotal: Big(convertPriceTo(subtotal.valueOf(), 'DOLLAR')),
        lokobeeFeePercent: groceryStore?.lokobeeFeePercent || 0,
        lokobeeFeeLimit: groceryStore?.lokobeeFeeLimit || 0,
        lokobeeFee: groceryStore?.lokobeeFee
      });
      lokobeeServiceFee = Big(convertPriceTo(lokobeeServiceFee.valueOf(), 'CENT'));
    }

    // Converting all prices to cents and ceiling to nearest INT
    let finalPrice = Big(0);
    finalPrice = finalPrice.add(subtotal);
    finalPrice = finalPrice.add(totalTax);
    if (discountedPriceCents) {
      finalPrice = finalPrice.minus(discountedPriceCents.round());
    }
    finalPrice = finalPrice.add(grocery_tip.round());
    if (groceryStore?.lokobeeFee) {
      finalPrice = finalPrice.add(lokobeeServiceFee.round());
    }

    if (deliveryFee) {
      finalPrice = finalPrice.add(deliveryFee.round());
    }

    finalPrice = finalPrice.round();

    // console.log('subtotal', subtotal.valueOf());
    // console.log('totalTax', totalTax.valueOf());
    // console.log(
    //   'grocery_totalPriceAfterTax',
    //   groceryStore?.lokobeeFee
    //     ? subtotal
    //         .add(totalTax)
    //         .add(lokobeeServiceFee)
    //         .valueOf()
    //     : subtotal.add(totalTax).valueOf()
    // );
    // console.log('deliveryCharge', deliveryCharge?.valueOf());
    // console.log('discountedPriceCents', discountedPriceCents?.round());
    // console.log('grocery_subtotalAfterDiscount', subtotalAfterDiscountCents?.round());
    // console.log('grocery_finalPrice', finalPrice.valueOf());

    return {
      ...state,
      grocery_subtotal: subtotal,
      grocery_tax: totalTax,
      grocery_totalPriceAfterTax: groceryStore?.lokobeeFee ? subtotal.add(totalTax).add(lokobeeServiceFee) : subtotal.add(totalTax),
      grocery_deliveryCharge: deliveryFee,
      grocery_serviceFee: lokobeeServiceFee,
      grocery_finalPrice: finalPrice,
      grocery_discount: discountedPriceCents?.round(),
      grocery_subtotalAfterDiscount: subtotalAfterDiscountCents
    };
  }
};

export default {};
