import React, { useEffect, useState, useContext } from 'react';
import firebase from 'firebase';
import { FirebaseContext } from 'fbase';
import { LokobeeUser } from 'generated/types';
import { useLazyQueryWithLoader } from 'hooks/loader';
import { getLokobeeUserQuery } from 'graphql/query';
import { Bugfender } from '@bugfender/sdk';

interface IContextProps {
  currentUser: firebase.User | null;
  lokobeeUser: LokobeeUser | null;
  token: string | null;
  isAuthStateChanged: Boolean;
}

export const AuthContext = React.createContext<IContextProps>({ lokobeeUser: null, currentUser: null, token: null, isAuthStateChanged: false });

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);

  const [isAuthStateChanged, setIsAuthStateChanged] = useState(false);

  const [lokobeeUser, setLokobeeUser] = useState<LokobeeUser | null>(null);

  const [token, setToken] = useState<string | null>(null);

  const [getUser, { data }] = useLazyQueryWithLoader(getLokobeeUserQuery, {}, true);

  /**
   * Get firebase reference
   */
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    const auth = firebase?.getAuth();

    if (auth) {
      /**
       *
       * Event listener whenever user auth state is changed. Gets triggered only in case of user sign in or sign out.
       *
       * Signs in user anonymously if id token is not available.
       */
      auth().onAuthStateChanged(async (user) => {
        if (user) {
          const firebaseUseridToken = await user.getIdToken();

          try {
            getUser({
              context: {
                headers: {
                  Authorization: firebaseUseridToken
                }
              }
            });

            if (data && data.getLokobeeUser) {
              setLokobeeUser(data.getLokobeeUser);
            }
          } catch {
            setLokobeeUser(null);
          }
          if (user.displayName) {
            Bugfender.setDeviceKey('user_email', user.displayName);
          }
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
          setLokobeeUser(null);
          setToken(null);
        }
        setIsAuthStateChanged(true);
      });

      /**
       *
       * Event listener for id token changes or renewed.
       */
      auth().onIdTokenChanged(async (user) => {
        if (user) {
          /**
           * Retrieves id token from currently active user.
           */
          const firebaseUseridToken = await user.getIdToken();

          setToken(firebaseUseridToken);
        }
      });
    }
  }, [data, firebase, getUser, token]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        lokobeeUser,
        isAuthStateChanged,
        token
      }}>
      {children}
    </AuthContext.Provider>
  );
};
