import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { map } from 'lodash';
import { useStyles } from './style';
import { formatPriceToString } from '../utils';
import LiquorIcon from 'assets/img/liquor.svg';

interface IProps {
  items: any;
}

const OrderItems = ({ items }: IProps) => {
  const classes = useStyles();

  const Row = ({ children }: any) => {
    return <Box padding={1}>{children}</Box>;
  };

  const getDishPoints = (qty: number, points: number) => {
    return qty * points;
  };

  const getExclusions = (data: any) => {
    if (!!data.exclusions && !!data.exclusions.length) {
      return (
        <Box paddingLeft={2}>
          {map(data.exclusions, (topping, index1) => {
            const { title = '' } = topping;
            return (
              <>
                <Typography variant="body2" display="block">
                  <span className={classes.redBoldTextColor}>NO&nbsp;</span>
                  {title}
                </Typography>
              </>
            );
          })}
        </Box>
      );
    }

    return null;
  };

  const getAdditions = (data: any) => {
    if (!!data.additions && !!data.additions.length) {
      return (
        <Box paddingLeft={2}>
          {map(data.additions, (topping, index1) => {
            const { title = '', density = '' } = topping;
            return (
              <Typography key={index1} display="inline" variant="body2">
                {title}&nbsp;
                {density !== 'Regular' && <span className={classes.boldText}> ({density}) </span>}
                {Number(index1) < data.additions.length - 1 && <span>,&nbsp;</span>}
              </Typography>
            );
          })}
        </Box>
      );
    }

    return null;
  };

  return (
    <Box paddingX={1} className={classes.root}>
      {map(items, (item: any, index) => {
        const dishType = item.dishType || 'REGULAR';

        if (dishType === 'REGULAR') {
          const { dishTitle, count, dishPrice, strikedPrice, discountPercent, isAlcohol, points, dishExtras, note, dishOriginalPrice } = item;
          const [{ text: _title }] = dishTitle || [{ text: '' }];

          const [{ text: _dishSize }] = dishOriginalPrice ? dishOriginalPrice.size : [{ text: '' }];

          return (
            <Row key={index}>
              <Box display="flex">
                <Box flexGrow={1} paddingRight={1}>
                  <Typography className={classes.boldText} variant="body1" display="inline">
                    {count} x
                  </Typography>
                  <Typography className={classes.lightText} style={{ paddingLeft: '8px' }} variant="body1" display="inline">
                    {_title}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Box marginRight={0.5}>
                      <Typography className={classes.lightText} variant="body1">
                        {_dishSize}
                      </Typography>
                    </Box>
                    {isAlcohol && <img src={LiquorIcon} alt="" />}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                  {!!strikedPrice && (
                    <Box className={classes.priceItem}>
                      <Typography variant="body1" className={classes.priceStriked} noWrap={true} align="right">
                        ${formatPriceToString(strikedPrice * count)}
                      </Typography>
                    </Box>
                  )}
                  <Box className={classes.priceItem}>
                    <Typography variant="body1" className={classes.boldText} noWrap={true} align="right">
                      {item.isReward ? `${getDishPoints(count, points)} PTS` : `$${formatPriceToString(dishPrice * count)}`}
                    </Typography>
                  </Box>
                  {!!discountPercent && (
                    <Box className={classes.priceItem}>
                      <Typography variant="body1" className={classes.priceDiscount} noWrap={true} align="right">
                        {discountPercent}%&nbsp;OFF
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {!!dishExtras && (
                <>
                  {map(dishExtras, (extras, index1) => {
                    const { groupTitle, items: extraItems } = extras;

                    return (
                      <Typography variant="body2" key={index1}>
                        <span className={classes.boldText}>{groupTitle}:</span>&nbsp;{extraItems.join(', ')}
                      </Typography>
                    );
                  })}
                </>
              )}
              {note && (
                <Typography variant="body2">
                  <span className={classes.boldText}>Dish Note: </span>
                  {note}
                </Typography>
              )}
            </Row>
          );
        }
        if (dishType === 'PIZZA') {
          const {
            count,
            dishTitle,
            pizzaCrust,
            toppings: { leftToppings, rightToppings },
            sauces: { leftSauces, rightSauces },
            cheeses: { leftCheeses, rightCheeses },

            halfAndHalf,
            pizzaBasePrice,
            dishPrice,
            note,
            discountPercent,
            strikedPrice
          } = item;

          const [{ text: _title }] = dishTitle || [{ text: '' }];

          const { title: _dishSize } = pizzaBasePrice && pizzaBasePrice.size ? pizzaBasePrice.size : { title: '' };

          const { title: crust } = pizzaCrust || { title: '' };

          return (
            <Row key={index}>
              <Box display="flex">
                <Box flexGrow={1} paddingRight={1}>
                  <Typography className={classes.boldText} variant="body1" display="inline">
                    {count} x
                  </Typography>
                  <Typography className={classes.lightText} style={{ paddingLeft: '8px' }} variant="body1" display="inline">
                    {_title}
                  </Typography>
                  <Typography className={classes.lightText} variant="body1">
                    {_dishSize}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                  {!!strikedPrice && (
                    <Box className={classes.priceItem}>
                      <Typography variant="body1" className={classes.priceStriked} noWrap={true} align="right">
                        ${formatPriceToString(strikedPrice * count)}
                      </Typography>
                    </Box>
                  )}
                  <Box className={classes.priceItem}>
                    <Typography variant="body1" className={classes.boldText} noWrap={true} align="right">
                      ${formatPriceToString(dishPrice * count)}
                    </Typography>
                  </Box>
                  {!!discountPercent && (
                    <Box className={classes.priceItem}>
                      <Typography variant="body1" className={classes.priceDiscount} noWrap={true} align="right">
                        {discountPercent}%&nbsp;OFF
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box paddingTop={1}>
                <Typography variant="body2">{crust}</Typography>
                {!halfAndHalf ? (
                  <>
                    {getExclusions(leftSauces)}
                    {getExclusions(leftCheeses)}
                    {getExclusions(leftToppings)}
                    {getAdditions(leftSauces)}
                    {getAdditions(leftCheeses)}
                    {getAdditions(leftToppings)}
                  </>
                ) : (
                  <>
                    <Typography variant="body2">{'LEFT HALF'}</Typography>
                    {getExclusions(leftSauces)}
                    {getExclusions(leftCheeses)}
                    {getExclusions(leftToppings)}
                    {getAdditions(leftSauces)}
                    {getAdditions(leftCheeses)}
                    {getAdditions(leftToppings)}

                    <Typography variant="body2">{'RIGHT HALF'}</Typography>
                    {getExclusions(rightSauces)}
                    {getExclusions(rightCheeses)}
                    {getExclusions(rightToppings)}
                    {getAdditions(rightSauces)}
                    {getAdditions(rightCheeses)}
                    {getAdditions(rightToppings)}
                  </>
                )}
              </Box>

              {note && (
                <Typography variant="body2">
                  <span className={classes.boldText}>Dish Note: </span>
                  {note}
                </Typography>
              )}
            </Row>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default OrderItems;
