import gql from 'graphql-tag';

export const Coupon = gql`
  fragment Coupon on Coupon {
    bizId
    code
    id
    createdAt
    name
    description
    discountType
    discountValue
    maxDiscount
    startDate
    endDate
    minSpending
    isPrivate
    isSingleUse
    orderType
    dayOfWeek
    used
    totalSales
  }
`;
