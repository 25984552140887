import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './style';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface IProps {
  title: string;
  children: ReactNode;
  onBack: () => void;
}

const FormDialog = ({ title, children, onBack }: IProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} padding={2}>
      <ArrowBackIcon className={classes.backArrow} onClick={onBack} />
      {title !== '' && (
        <Box textAlign="center">
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default FormDialog;
