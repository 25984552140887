import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export const initialise = async () => {
  let version = '';

  const metaData = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' });

  try {
    const metaDatajson = await metaData.json();
    version = metaDatajson && metaDatajson.version ? metaDatajson.version : 'NA';
  } catch (e) {
    version = 'NA';
  }

  if (window.location.hostname !== 'localhost') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      integrations: [new Integrations.BrowserTracing()],
      release: version,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0
    });
  }
};
