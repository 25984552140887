import { gql } from 'apollo-boost';

export const getTablesQuery = gql`
  query getTables($input: GetTablesInput!) {
    getTables(input: $input) {
      tableName
      seats
    }
  }
`;

export const getTableServicesQuery = gql`
  query getTableServices($input: GetTableServicesInput!) {
    getTableServices(input: $input) {
      dishes(status: ACTIVE) {
        id
        title {
          text
        }
        price {
          price {
            intValue
            shift
          }
        }
      }
    }
  }
`;
