import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  dialogContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: 'color .1s ease-in-out,opacity .1s ease-in-out',
    '&:hover': {
      color: '#111'
    },
    padding: 0,
    '&:first-child': {
      padding: 0
    }
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  phoneInputField: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${grey[300]}`,
    background: '#fff',
    borderRadius: 20,
    '& input': {
      border: 'none',
      fontFamily: "'Itim'",
      '&::placeholder': {
        color: grey[500],
        fontWeight: '200'
      }
    }
  }
}));
