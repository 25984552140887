import { useContext } from 'react';
import { AuthContext } from 'fbase/authContext';

const useProfileRoles = () => {
  const { currentUser } = useContext(AuthContext);

  const isPhoneLogin = currentUser?.providerData && currentUser.providerData.length && !!currentUser.providerData.filter((provider) => provider?.providerId === 'password').length;

  const isGoogleLogin = currentUser?.providerData && currentUser.providerData.length && currentUser.providerData.filter((provider) => provider?.providerId === 'google.com').length;

  const isFacebookLogin = currentUser?.providerData && currentUser.providerData.length && currentUser.providerData.filter((provider) => provider?.providerId === 'facebook.com').length;

  return {
    isPhoneLogin: !!isPhoneLogin,
    isGoogleLogin: !!isGoogleLogin,
    isFacebookLogin: !!isFacebookLogin
  };
};

export default useProfileRoles;
