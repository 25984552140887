import React from 'react';
import { ICoupon } from '../types';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ReactComponent as DiscountIcon } from 'assets/img/discount.svg';
import { useStyles } from '../style';
import Big from 'big.js';

interface IProps {
  handleOpen: () => void;
  isDiscountApplied: boolean;
  code: string | undefined;
  setCouponData: (payload: { coupon: Partial<ICoupon>; token: string } | null) => void;
  isApplyingCoupon: boolean;
  isOpen: boolean;
  discount: string | null;
  removeDiscount: () => void;
  couponData: Partial<ICoupon> | null;
}
const ApplyPromoButton: React.FC<IProps> = ({ handleOpen, discount, isDiscountApplied, code, isApplyingCoupon, isOpen, removeDiscount }) => {
  const classes = useStyles();
  if (isDiscountApplied && discount) {
    return (
      <Box paddingX={1}>
        <Box paddingX={2} borderRadius={10} marginY={2} paddingY={1} className={classes.successBg} display="flex" justifyContent={'space-between'} alignItems="center">
          <Box display="flex" alignItems="center">
            <Box display="flex" flexDirection={'column'}>
              <Box flex={1}>
                <Typography variant="subtitle2" style={{ fontWeight: 'normal' }}>
                  '{code}' APPLIED
                </Typography>
              </Box>
              {isDiscountApplied && discount && (
                <Box flex={1}>
                  <Typography variant="subtitle2" style={{ fontWeight: 'normal' }}>
                    <span style={{ fontWeight: 'bold' }}>${Big(discount)?.toFixed(2)}</span> coupon savings
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box color={'#fff'}>
            <Button size="small" style={{ color: '#fff', outlineColor: '#fff', borderColor: '#fff' }} variant="outlined" onClick={removeDiscount}>
              Remove
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box paddingX={1}>
      <Box paddingX={2} className={classes.successBg} borderRadius={10} paddingY={2} onClick={handleOpen} marginY={2}>
        <Box paddingX={1} display="flex" alignItems="center" className={classes.applyCode}>
          <Box component="span" marginRight={2} height={20}>
            {isApplyingCoupon && !isOpen ? <CircularProgress size={20} /> : <DiscountIcon color="#fff" fill={'#fff'} width={20} />}
          </Box>
          <Box flex={1}>
            <Typography variant="body1" component="div">
              {isApplyingCoupon && !isOpen ? 'Finding the best deal for you...' : 'APPLY PROMO CODE'}
            </Typography>
          </Box>
          <KeyboardArrowRightIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default ApplyPromoButton;
