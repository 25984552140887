import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './style';
import empty_image from 'assets/img/empty_image.png';
import { grey } from '@material-ui/core/colors';

interface IProps {
  src: string | null | undefined;
  className?: string;
  defaultImageClassName?: string;
  getImageUrl?: (signedUrl: string) => void;

  defaultImage?: string;
  radius?: boolean;
  squareImage?: boolean;
}

const S3Image: React.FC<IProps> = ({ src, className, getImageUrl, defaultImage, defaultImageClassName, radius = false, squareImage = false }) => {
  const classes = useStyles();

  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '';
    setImgSrc(null);
  };

  const [imgSrc, setImgSrc] = useState<string | null | undefined>(src);

  return (
    <Box paddingTop={squareImage ? '100%' : '66.66%'} position="relative">
      {!imgSrc ? (
        <Box
          width="100%"
          height="100%"
          margin={0}
          padding={0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor={grey[200]}
          className={defaultImageClassName ? defaultImageClassName : undefined}
          position="absolute"
          top={0}
          left={0}>
          <img src={defaultImage ? defaultImage : empty_image} width="50%" onError={handleError} alt="s3" />
        </Box>
      ) : (
        <Box position="absolute" top={0} left={0} width="100%">
          <img src={imgSrc} className={`${classes.image} ${className ? className : ''}`} alt="S3" onError={handleError} />
        </Box>
      )}
    </Box>
  );
};

export default S3Image;
