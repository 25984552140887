import gql from 'graphql-tag';

export const Dish = gql`
  fragment Dish on Dish {
    id
    title {
      text
    }
    category {
      id
      title {
        text
      }
    }
    status
    isAlcohol
    isDiscounted
    isReward
    rewardPoints
    rateScore
    rateCount
    description {
      text
    }
    type
    tags
    price {
      id
      size {
        text
      }
      isDefault
      price {
        currencyCode
        intValue
        shift
      }
      discountAmount
    }
    images {
      id
      preview {
        id
        url
      }
      md {
        id
        url
      }
      xl {
        id
        url
      }
    }
    pizzaBasePrice {
      id
      size {
        id
        title
        enableHalfHalf
      }
      price {
        intValue
        shift
      }
      isDefault
      discountAmount
    }
  }
`;
