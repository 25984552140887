import React from 'react';
import { Order } from 'models';
import { useFormik, getIn } from 'formik';
import { Box, Typography, Divider, TextField, Button } from '@material-ui/core';
import validations from './validations';
import { StarRating } from '@lokobee/lokobee-ui';
import { useStyles } from './style';
import { useCreateReview } from 'graphql/hooks/dish.hooks';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useAlert } from 'hooks';

interface IProps {
  order: Order;
  orderId: string | null;
  buyerId: string;
  handleClose: () => void;
}

interface IRatingsForm {
  items: {
    itemId: string;
    itemName: string;
    rating: number;
  }[];
  restaurantRating: number;
  restaurantReview: string;
}

const Content = ({ order, orderId, buyerId, handleClose }: IProps) => {
  const classes = useStyles();

  const items = order.getItemsWithoutDuplicateRewardItems;

  const { createReview } = useCreateReview(order.seller.id);

  const snackbar = useSnackbar();

  const { lokoAlert } = useAlert();

  const formik = useFormik<IRatingsForm>({
    initialValues: {
      items: items
        ? items.map((item) => {
            return {
              itemId: item.dishId || '',
              itemName: item.dishTitle,
              rating: 0
            };
          })
        : [],
      restaurantRating: 0,
      restaurantReview: ''
    },
    validationSchema: validations,
    onSubmit: async (formValues: IRatingsForm) => {
      const response = await createReview({
        variables: {
          input: {
            orderId,
            userUid: buyerId,
            bizId: order.seller.id,
            dishRatings: formValues.items
              .map((item) => {
                if (item.rating !== 0) {
                  return {
                    dishId: item.itemId,
                    rateScore: item.rating
                  };
                }
                return undefined;
              })
              .filter((item) => item),
            bizRating:
              formValues.restaurantRating || formValues.restaurantReview.trim() !== ''
                ? {
                    rateScore: formValues.restaurantRating ? formValues.restaurantRating : 0,
                    rateText: formValues.restaurantReview.trim() !== '' ? formValues.restaurantReview.trim() : undefined
                  }
                : undefined
          }
        }
      });

      if (response && response.data && response.data.createReview) {
        snackbar.enqueueSnackbar('Review submitted successfully', {
          variant: 'success'
        });
        handleClose();
      } else {
        lokoAlert('Something went wrong.');
      }
    }
  });

  const { values } = formik;

  return (
    <Box paddingX={2} display="flex" flexDirection="column" height="92%">
      <Box flex={1} overflow="scroll">
        <Box paddingY={1}>
          <Typography variant="body1">Your review helps foodies make better choices.</Typography>
          <Typography variant="body1">What items did you enjoy?</Typography>
        </Box>
        <Box>
          {values.items.map((item, index) => {
            return (
              <Box key={index}>
                <Typography variant="subtitle1">{item.itemName}</Typography>
                <StarRating
                  name={`items[${index}].rating`}
                  value={values.items[index].rating}
                  onChange={(val: number) => {
                    formik.setFieldTouched(`items[${index}].rating`);
                    formik.setFieldValue(`items[${index}].rating`, val);
                  }}
                />
                <Divider className={classes.divider} />
              </Box>
            );
          })}

          <Box paddingBottom={1}>
            <Box paddingY={1}>
              <Typography variant="subtitle1">Write your feedback to restaurant.</Typography>
              <StarRating
                name="restaurantRating"
                value={values.restaurantRating}
                onChange={(val: number) => {
                  formik.setFieldTouched('restaurantRating');
                  formik.setFieldValue('restaurantRating', val);
                }}
              />
            </Box>
            <TextField
              name="restaurantReview"
              variant="outlined"
              fullWidth={true}
              value={values.restaurantReview}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldTouched('restaurantReview');
                formik.setFieldValue('restaurantReview', e.target.value);
              }}
              onBlur={formik.handleBlur}
              error={!!getIn(formik.touched, 'restaurantReview') && !!getIn(formik.errors, 'restaurantReview')}
              helperText={!!getIn(formik.touched, 'restaurantReview') && !!getIn(formik.errors, 'restaurantReview') ? getIn(formik.errors, 'restaurantReview') : ''}
              multiline={true}
              rows={2}
            />
          </Box>
        </Box>
      </Box>

      <Box paddingY={1}>
        <Button variant="contained" disableElevation={true} color="primary" fullWidth={true} disabled={isEmpty(formik.touched)} onClick={() => formik.submitForm()}>
          Submit review
        </Button>
      </Box>
    </Box>
  );
};

export default Content;
