import React from 'react';
import { useState } from 'react';
import { Grid, Box, Divider, useMediaQuery } from '@material-ui/core';
import DishDetails from 'components/DishDetails';
import { useStyles } from './style';
import DishDialog from 'components/DishDialog';

interface Iprops {
  onhandleClose?: () => void;
  getAllDishes?: discountedDishes[];
  keepSmGrid: boolean;
}

interface discountedDishes {
  id: any;
  getTitle: any;
  images?: any;
  getMenuPrice: any;
  getDescription: any;
  isAlcohol: any;
  rateScore: any;
  rateCount: any;
}

function FlashDeals({ onhandleClose, getAllDishes, keepSmGrid }: Iprops) {
  const classes = useStyles();

  const [activeDish, setActiveDish] = useState<null | string>(null);
  const [isDishDialogOpen, setDishDialogOpen] = useState(false);
  const _dishGridItemOnClick = (id: string) => {
    setActiveDish(id);
    onhandleClose && onhandleClose();
    setDishDialogOpen(true);
  };
  const _handleClose = () => {
    setDishDialogOpen(false);
  };
  const allDiscountedDishes = getAllDishes;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  if (allDiscountedDishes) {
    const dishData = allDiscountedDishes.map(({ id: dishId, getTitle, images, getMenuPrice, getDescription, isAlcohol, rateScore, rateCount }) => {
      const title = getTitle;

      const description = getDescription;
      const menuPrice = getMenuPrice;
      const {
        preview: { url }
      } = (images && images[0]) || { preview: { url: null } };

      return (
        <Grid item={true} sm={6} md={keepSmGrid ? 6 : 4} xs={12} zeroMinWidth={true} key={dishId} onClick={() => _dishGridItemOnClick(dishId)}>
          <DishDetails title={title} description={description} imgSrc={url} menuPrice={menuPrice} isAlcohol={isAlcohol} rateScore={rateScore} rateCount={rateCount} />
          {isDesktop && (
            <Box paddingX="5%">
              <Divider />
            </Box>
          )}
        </Grid>
      );
    });

    return (
      <Box className={classes.dishListWrapper}>
        <Grid container={true} spacing={1} alignItems="stretch">
          {dishData}
        </Grid>
        <DishDialog isOpen={isDishDialogOpen} handleClose={_handleClose} id={activeDish} />
      </Box>
    );
  }
  return null;
}

export default FlashDeals;
