import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(0)
  },
  selectedBtn: {
    border: '1px solid rgba(0, 0, 0, 0.23)'
  },
  backdrop: {
    zIndex: 10
  },
  customTipContainer: {
    padding: theme.spacing(2),
    backgroundColor: 'white',
    height: '30%',
    width: '95%',
    bottom: 0,
    position: 'absolute',
    borderRadius: '80px 80px 0 0'
  },
  customBtn: {
    cursor: 'pointer'
  },
  btns: {
    margin: theme.spacing(0, 1)
  }
}));
