import React from 'react';
import { Typography, Box } from '@material-ui/core';

interface IProps {
  hasError: boolean;
  primaryText: string;
  secondaryText: string;
}
const ChoiceHeading: React.FC<IProps> = ({ hasError, primaryText, secondaryText }) => {
  return (
    <Box display="flex">
      <Box flex={1}>
        <Typography variant="caption" color={(hasError && 'error') || 'inherit'}>
          {primaryText}
        </Typography>
      </Box>
      <Box color="error">
        <Typography variant="caption" color={(hasError && 'error') || 'inherit'}>
          {secondaryText}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChoiceHeading;
