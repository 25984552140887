import { makeStyles } from '@material-ui/core';
// import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: blueGrey[50],
    '& form': {
      width: '100%'
    }
  },
  backArrow: {
    position: 'absolute',
    top: 8,
    left: 8,
    cursor: 'pointer'
  }
}));
