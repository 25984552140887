import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  btnContainer: {
    '& button': {
      margin: theme.spacing(1)
    }
  },
  paymentButton: {
    borderRadius: 0,
    flex: '1 1 0'
  },
  addressButton: {
    borderRadius: '6px',
    flex: '1 1 0'
  },
  root: {
    maxWidth: '345',
    backgroundColor: '#e8f5e9'
  },
  action: {
    color: 'red'
  },
  subheader: {
    color: '#424242'
  },
  icon: {
    color: grey[500],

    marginRight: 10
  },
}));
