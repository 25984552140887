import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dropDown: {
    margin: theme.spacing(1)
  },
  menuItem: {
    minHeight: 'unset',
    padding: theme.spacing(0.5, 2)
  },
  errorText: {
    color: theme.palette.error.main
  }
}));
