import { DeliveryProviders } from "generated/types";

export type TBoonsEventStatus = 'initiated' | 'accepted' | 'trip_created' | 'in_progress' | 'completed' | 'cancelled' | 'auto_cancel' | 'returned' | 'delayed';

export type TDoordashClassicEventStatus = 'scheduled' | 'assigned' | 'enroute_to_pickup' | 'arrived_at_store' | 'picked_up' | 'arrived_at_consumer' | 'delivered' | 'cancelled' | 'pending_return' | 'could_not_deliver';

export enum MapStatusToNumber {
  initiated = 0,
  accepted = 1,
  trip_created = 2,
  in_progress = 3,
  completed = 4,
  cancelled = 3,
  auto_cancel = 3,
  returned = 3,
  delayed = 3,
  // doordash
  scheduled = 0,
  assigned = 2,
  enroute_to_pickup = 2,
  arrived_at_store = 2,
  picked_up = 3,
  arrived_at_consumer = 3,
  delivered = 4,
  pending_return = 5,
  could_not_deliver = 5
}

export interface IDeliveryEvent {
  deliveryProvider: DeliveryProviders;
  deliveryStatusBoons: TBoonsEventStatus;
  deliveryStatusDoordashClassic: TDoordashClassicEventStatus;
  created_on: number;
  cancelledReason?: string;
  delayReason?: string;
}

export interface IEventState {
  type: TBoonsEventStatus;
  isTrue: boolean;
  reason: string | null;
  eventTime: string 
}