import React, { useState } from 'react';
import { Grid, Typography, Box, makeStyles, withStyles, Tabs, useTheme, Tab } from '@material-ui/core';
import { IBizHour } from '../types';
import { combineDaysOfWeek } from 'util/convertBusinessHours';
import { map } from 'lodash';
import { Date } from 'util/date';
import moment from 'moment';
import { groupBy } from 'lodash';

export const lpadNumber: any = (input: any, digits: any) => {
  return Array(Math.max(digits - String(input).length + 1, 0)).join('0') + input;
};

interface IProps {
  businessHours: IBizHour[] | undefined | null;
}

export interface ICombinedHour {
  openDay: number[];
  closeDay: number[];
  openHour: number;
  openMinute: number;
  closeHour: number;
  closeMinute: number;
}

type ITabOptions = 'Takeout' | 'Dining' | 'Delivery';

const useStyles = makeStyles((theme) => ({
  activeBizHour: {
    backgroundColor: theme.palette.success.main,
    padding: theme.spacing(0.5, 0),
    color: '#fff',
    borderRadius: '2px',
    '& div p': {
      fontWeight: 600
    }
  },
  boldText: {
    fontWeight: 600
  }
}));

const BizHours = ({ businessHours }: IProps) => {
  const dayConverter: any = {
    1: 'Mon',
    2: 'Tues',
    3: 'Wed',
    4: 'Thurs',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  const fullDayConverter: any = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
  };

  // const RestaurantServiceType: any = {
  //   DINING: 'Dining',
  //   RESTAURANT_PICK_UP: 'Pick up',
  //   RESTAURANT_DELIVERY: 'Delivery'
  // };

  const classes = useStyles();

  const curDate = moment();

  const todayDay = curDate.day() ? curDate.day() : 7;

  const theme = useTheme();

  const [activeTab, setActiveTab] = useState<ITabOptions>('Takeout');

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const renderHours = () => {
    const hoursGroupedByOrderType = groupBy(businessHours, 'serviceType');

    const takeoutBizHours = hoursGroupedByOrderType['RESTAURANT_PICK_UP'];

    const diningBizHours = hoursGroupedByOrderType['RESTAURANT_DINING'];

    const deliveryHours = hoursGroupedByOrderType['RESTAURANT_DELIVERY'];

    let selectedHours: IBizHour[] = [];

    if (activeTab === 'Takeout' && takeoutBizHours) {
      selectedHours = takeoutBizHours;
    }

    if (activeTab === 'Dining' && diningBizHours) {
      selectedHours = diningBizHours;
    }

    if (activeTab === 'Delivery' && deliveryHours) {
      selectedHours = deliveryHours;
    }

    if (selectedHours.length === 0) {
      return (
        <Box padding={1}>
          <Typography variant="body1">No business hours found</Typography>
        </Box>
      );
    }

    return map(selectedHours, (bizHour: IBizHour) => {
      const { serviceName, serviceType, hours: slots } = bizHour;

      const hours = combineDaysOfWeek(slots);

      return (
        <Box width="100%" marginY={1} key={serviceName + serviceType}>
          <Typography variant="body1">{serviceName}</Typography>
          {map(hours, (hour: ICombinedHour, index: number) => {
            const { openDay, openHour, openMinute, closeHour, closeMinute } = hour;

            let currentlyActive = false;

            if (openDay.length === 1) {
              if (openDay[0] === todayDay) {
                currentlyActive = true;
              }
            } else {
              if (openDay[0] <= todayDay && openDay[openDay.length - 1] >= todayDay) {
                currentlyActive = true;
              }
            }

            return (
              <Grid container={true} key={index} className={currentlyActive ? classes.activeBizHour : undefined}>
                <Grid item={true} xs={6}>
                  {openDay.length === 1 ? (
                    <Typography variant="body2">{dayConverter[openDay[0]]}</Typography>
                  ) : (
                    <Typography variant="body2">{dayConverter[openDay[0]] + ' - ' + dayConverter[openDay[openDay.length - 1]]}</Typography>
                  )}
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body2" align="right">
                    {Date.convertDateToMeridienFormat(openHour, lpadNumber(openMinute, 2)) + ' - ' + Date.convertDateToMeridienFormat(closeHour, lpadNumber(closeMinute, 2))}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      );
    });
  };

  return (
    <Box paddingX={2} marginBottom={2}>
      <Typography variant="h6" component="div">
        <Box fontWeight="500">Business Hours</Box>
      </Typography>
      <Typography variant="body2" className={classes.boldText}>
        {`Happy ${fullDayConverter[todayDay]} ! Active hours highlighted in green.`}
      </Typography>

      <StyledTabs variant="fullWidth" value={activeTab} onChange={(_e: any, index: any) => setActiveTab(index)}>
        <Tab label="Takeout" value="Takeout" />
        <Tab label="Dining" value="Dining" />
        <Tab label="Delivery" value="Delivery" />
      </StyledTabs>

      {renderHours()}
    </Box>
  );
};

export default BizHours;
