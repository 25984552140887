import React, { useState, useEffect } from 'react';
import { Dish } from 'models';
import { Box, Typography, TextField } from '@material-ui/core';
import { map } from 'lodash';
import { applyDiscount, convertPrice, convertPriceTo } from 'util/number';
import ExtraGroup from '../ExtraGroup';
import Size from '../Size';
import { ITotaPrice, IItems, IHasError } from '../types';
import { calculateTotalPrice } from 'util/priceCalculator';
import { Stepper } from '@lokobee/lokobee-ui';
import AddDishButton from '../AddDishButton';
import RestaurantModel from 'models/Restaurant';
import { useReward } from 'hooks/restaurant';
import Big from 'big.js';
import { useAlert } from 'hooks';
import { IDiscount } from 'generated/custom';

interface IProps {
  dish: Dish | null;
  restaurant: RestaurantModel | null;
  handleClose: () => void;
  currency?: 'USD' | 'POINT';
  hideImg: () => void;
  showImg: () => void;
  isHeaderExpanded: boolean;
}

const DishTypeRegular: React.FC<IProps> = ({ dish, restaurant, handleClose, currency = 'USD', isHeaderExpanded, hideImg, showImg }) => {
  const { lokoAlert } = useAlert();

  const [errors, setErrors] = useState<IHasError>({});

  const [subtotal, setTotalPrice] = useState<ITotaPrice>({ quantity: 1, sizePrice: 0, extraGroupPrice: {} });

  const [selectedSizeIndex, setSelectedSizeIndex] = useState<number>(0);

  const [selectedExtraGroups, setSelectedExtraGroup] = useState<{ [key: number]: IItems[] }>([]);

  const [hasError, setHasError] = useState<boolean>(false);

  const [dishNote, setDishNote] = useState(null);

  const [sizeDiscount, setSizeDiscount] = useState<string | null>(null);

  const { isIncPossible, remainingPoints } = useReward();

  const orderEnabled = (restaurant?.enableDeliveryOrder || restaurant?.enableDiningOrder || restaurant?.enableTakeoutOrder) && restaurant.isLokobeePartner;

  useEffect(() => {
    const handleScroll = () => {
      const elemOffsetHeight = document.getElementById('scrollerBox')?.offsetHeight || 0;
      const elemScrollHeight = document.getElementById('scrollerBox')?.scrollHeight || 0;
      const viewDiff = elemScrollHeight - elemOffsetHeight;
      const st = window.pageYOffset || document.documentElement.scrollTop || document.getElementById('scrollerBox')?.scrollTop || 0;
      if (st > 0 && !(st >= viewDiff - 10)) {
        // downscroll code
        hideImg();
      } else {
        // upscroll code
      }
    };
    document.getElementById('scrollerBox')?.addEventListener('scroll', handleScroll, true);
  }, [hideImg]);

  useEffect(() => {
    if (dish && dish.isReward && currency === 'POINT') {
      setTotalPrice((prev) => ({ ...prev, sizePrice: dish?.rewardPoints }));
    }
  }, [dish, currency]);

  useEffect(() => {
    let error = false;

    for (const i in errors) {
      if (errors[i]) {
        error = true;
      }
    }

    setHasError(error);
  }, [errors]);

  const onDishQuantityChange = ({ quantity, dishTitle, dishPoints }: { quantity: number; dishTitle: string; dishPoints: string }) => {
    if (quantity > 0) {
      //Check if the dish could be redeemed and alert to the user if the user is runningout of points
      if (dish && dish.isReward && currency === 'POINT') {
        const pointsToAdd = Big(dish.rewardPoints)
          .times(quantity)
          .toString();

        const isIncPossibleRes = isIncPossible(pointsToAdd, dishTitle);

        if (!isIncPossibleRes.isPossible) {
          lokoAlert(isIncPossibleRes.message);
          return;
        }
      }

      setTotalPrice((prev) => ({
        ...prev,
        quantity: quantity
      }));
    }
  };

  const onErrorHandler = (index: number, error: boolean) => {
    setErrors((prev) => {
      return { ...prev, [index]: error };
    });
  };

  const onDishSizeOnChangeHandler = (selectDishSizeIndex: number) => {
    if (dish) {
      const { price } = dish;

      const {
        price: { intValue, shift }
      } = (price && price[selectDishSizeIndex]) || { price: { intValue: 0, shift: 0 } };

      const priceValue = convertPrice(intValue, shift);

      const discountAmountInCent = price && price[selectDishSizeIndex].discountAmount;

      let sizePrice = priceValue;

      let sizeDiscount = null;

      //If dish is on a discount
      if (dish.isDiscounted && discountAmountInCent) {
        const discount: IDiscount = applyDiscount(priceValue, convertPriceTo(discountAmountInCent, 'DOLLAR'));

        sizePrice = discount.price;

        sizeDiscount = discount.percentage;
      }

      setSizeDiscount(sizeDiscount);

      setSelectedSizeIndex(selectDishSizeIndex);

      setTotalPrice((prev) => ({ ...prev, sizePrice }));
    }
  };

  const onExtrasChangeHandler = (arg: IItems[], extraDishIndex: number, index: number) => {
    setTotalPrice((prev) => ({
      ...prev,
      extraGroupPrice: {
        ...prev.extraGroupPrice,
        [extraDishIndex]: arg
      }
    }));

    setSelectedExtraGroup((prev) => ({ ...prev, [index]: arg }));
  };

  /**
   * Based on total price object this method adds dish size price and total extra groups price after adding prices of all items of extra groups
   *
   * @param totalPriceObj Takes subtotal object as an argument and calculates total price.
   * @returns {String} Final Price.
   */
  const getTotalPrice = (totalPriceObj: ITotaPrice) => {
    const { sizePrice, extraGroupPrice, quantity } = totalPriceObj;

    const extraGroupPriceArray = [];

    for (const i in extraGroupPrice) {
      for (const item of extraGroupPrice[parseInt(i, 10)]) {
        const { price } = item;
        extraGroupPriceArray.push(price);
      }
    }

    return calculateTotalPrice({
      extrasPrice: extraGroupPriceArray,
      sizePrice,
      quantity
    });
  };

  /**
   * Returns the total calcluated points to be displayed on the screen
   * @param totalPriceObj
   */
  const getTotalPoints = (totalPriceObj: ITotaPrice) => {
    const { sizePrice, quantity } = totalPriceObj;

    const totalPoints = Big(sizePrice)
      .times(quantity)
      .toString();

    return totalPoints;
  };

  const onDishNoteChange = (e: any) => {
    setDishNote(e?.target?.value);
  };

  if (dish) {
    const extraGroups1 = map(dish.extraGroups, ({ title: extraGroupTitle, maxSelect, minSelect, items, isOptional }, index) => {
      const [{ text: extraGroupTitle1 }] = extraGroupTitle || [{ text: '' }];

      const items1 = map(items, ({ title: extraGroupItemTitle, price: { intValue, shift }, disabled }, index1) => {
        const [{ text: extraGroupItemTitle1 }] = extraGroupItemTitle || [{ text: '' }];

        const itemPrice = convertPrice(intValue, shift);

        return {
          index: index1,
          title: extraGroupItemTitle1,
          price: itemPrice,
          disabled: disabled ? disabled : false
        };
      });

      return (
        <ExtraGroup
          key={extraGroupTitle1}
          title={extraGroupTitle1}
          min={minSelect}
          max={maxSelect}
          items={items1}
          isOptional={isOptional}
          onChange={(result) => onExtrasChangeHandler(result, index, index)}
          onError={(e) => onErrorHandler(index, e)}
          currency={currency}
        />
      );
    });

    return (
      <>
        <Box paddingX={2} display="flex">
          <Box flex={1}>
            <Typography variant="subtitle1">
              <Box component="span" fontWeight="bolder">
                {dish.isReward && currency === 'POINT' ? `${getTotalPoints(subtotal)} PTS` : `$${getTotalPrice(subtotal)}`}
              </Box>
            </Typography>
          </Box>
          <Box>
            <Stepper onChange={(value) => onDishQuantityChange({ quantity: value, dishTitle: dish.getTitle, dishPoints: dish.rewardPoints })} value={subtotal.quantity} />
          </Box>
        </Box>
        <Box marginY={1}></Box>
        {dish.isReward && currency === 'POINT' && remainingPoints > 0 && (
          <Box paddingX={2} display="flex" justifyContent="center" alignContent="center">
            <Typography variant="body1" color="secondary">
              <strong>{remainingPoints}</strong> PTS available
            </Typography>
          </Box>
        )}
        <Box marginY={1}></Box>
        <Box paddingX={2} id="scrollerBox" flex={1} paddingRight={1} overflow="scroll">
          <Size prices={dish.price} isDiscounted={dish.isDiscounted} rewardPoints={dish.isReward ? subtotal.sizePrice : 0} currency={currency} onChange={onDishSizeOnChangeHandler} />
          {extraGroups1}
        </Box>
        {!!restaurant?.enableDishNote && (
          <Box paddingX={2}>
            <TextField
              label="Add any special instructions ..."
              placeholder="Example, extra spicy, no spearmint etc."
              name="dishNote"
              value={dishNote ? dishNote : ''}
              onChange={onDishNoteChange}
              variant="outlined"
              rows={2}
              fullWidth={true}
              multiline={true}
            />
          </Box>
        )}
        <Box paddingX={2} marginTop={1}>
          <AddDishButton
            dish={dish}
            disabled={!orderEnabled || hasError}
            selectedSizeIndex={selectedSizeIndex}
            selectedExtraGroups={selectedExtraGroups}
            quantity={subtotal.quantity}
            handleClose={handleClose}
            note={dishNote}
            currency={currency}
            discountPercent={sizeDiscount}
          />
        </Box>
      </>
    );
  }

  return null;
};

export default DishTypeRegular;
