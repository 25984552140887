import { useState } from 'react';
import { getRestaurant } from 'graphql/query';
import { useQueryWithLoader } from 'hooks/loader';

const useRestaurantList = () => {
  const { loading, data, error } = useQueryWithLoader(getRestaurant);

  const [restaurantList, setRestaurantList] = useState([]);

  if (!loading && data && data.getRestaurantByUser) {
    setRestaurantList(data.getRestaurantByUser);
  }

  return {
    loading,
    data: restaurantList,
    error
  };
};

export default useRestaurantList;
