import React from 'react';
import { useStyles } from './style';
import { Box } from '@material-ui/core';

const RewardsLayout = ({ section1, section2, gradientColor = 'warning' }: any) => {
  const classes = useStyles({
    gradientColor
  });

  return (
    <>
      <Box className={classes.gradientContainer}>
        <Box className={classes.section1Container}>{section1}</Box>
        <Box className={classes.curvedContainer}>{section2}</Box>
      </Box>
    </>
  );
};

export default RewardsLayout;
