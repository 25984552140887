export interface IDrawerInitialState {
  isDrawerOpen: boolean;
}

export const drawerInitialState: IDrawerInitialState = {
  isDrawerOpen: false
};

export const drawerActions = {
  TOGGLE_DRAWER: (state: IDrawerInitialState) => ({ ...state, isDrawerOpen: !state.isDrawerOpen })
};

export default {};
