import { Box, Typography } from '@material-ui/core';
import CouponCard from 'components/CouponCard';
import { Coupon } from 'generated/types';
import React from 'react';
import { useStyles } from '../style';
interface IProps {
  appliedCode?: string;
  applicableCoupons: Coupon[];
  notApplicableCoupons: Coupon[];
  selectCoupon: (code: string) => void;
}

const CouponsListContainer: React.FC<IProps> = ({ applicableCoupons, notApplicableCoupons, selectCoupon, appliedCode = '' }) => {
  const classes = useStyles();

  const renderCoupons = (couponsList: Coupon[], isApplicableCouponsList: boolean) => {
    return (
      <Box>
        <Box className={classes.couponGroup}>
          <Box marginLeft={0.5} marginBottom={1}>
            <Typography variant="subtitle1"> {isApplicableCouponsList ? 'Applicable Coupons for this Purchase' : 'Coupons NOT Applicable to Current Purchase'}</Typography>
          </Box>
        </Box>
        <Box>
          {couponsList &&
            couponsList.map((couponItem: Coupon, index: number) => {
              return (
                <Box
                  key={index}
                  marginBottom={2}
                  paddingX={2}
                  onClick={() => {
                    if (isApplicableCouponsList) {
                      selectCoupon(couponItem.code);
                    }
                  }}>
                  <CouponCard appliedCode={appliedCode} couponData={couponItem} isApplicable={isApplicableCouponsList} isSelectionCard={true} />{' '}
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  };
  return (
    <>
      {applicableCoupons && applicableCoupons.length > 0 && <>{renderCoupons(applicableCoupons, true)}</>}
      {notApplicableCoupons && notApplicableCoupons.length > 0 && <>{renderCoupons(notApplicableCoupons, false)}</>}
    </>
  );
};

export default CouponsListContainer;
