import { GroceryStoreItem, GroceryStoreItemStatus, Maybe } from 'generated/types';

class GroceryStoreItemModal implements GroceryStoreItem {
  id!: string;
  categoryId!: string;
  subCategoryId!: string;
  title!: string;
  price!: number;
  size!: string;
  images!: Array<any>;
  brand!: string;
  taxMode?: string;
  description?: Maybe<string> | undefined;
  status?: Maybe<GroceryStoreItemStatus> | undefined;
  isReward?: boolean;
  rewardPoints?: number;
  upcCode?: string;
}

export default GroceryStoreItemModal;
