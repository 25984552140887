import { getDishesQuery, getDishQuery, getActiveDishesQuery, getDishCategoriesQuery, getAllActiveDishesQuery, getRewardItemsQuery } from 'graphql/query/dish.query';
import { useMutationWithLoader, useQueryWithLoader } from 'hooks/loader';
import { useStore } from 'store';
import { useEffect, useState } from 'react';
import { map, uniq, union, filter, chain } from 'lodash';
import { useActiveRestaurant } from 'hooks/restaurant';
import { GetDishQuery, GetActiveDishesQuery, GetDishesQuery } from 'generated/types';
import { IDishCategories } from 'generated/custom';
import { Dish } from 'models';
import { plainToClass } from 'class-transformer';
import { createReviewMutation } from 'graphql/mutations/dish.mutation';
import { getBuyerOrdersQuery } from 'graphql/query';

interface IGetDishArgs {
  restaurantId: string | null;
}

const pageLength = 100;

export const useGetDishesQuery = ({ restaurantId }: IGetDishArgs) => {
  const { dispatch } = useStore();

  const [dishes, setDishes] = useState<any>(null);

  const { loading, data, error } = useQueryWithLoader<GetDishesQuery>(getDishesQuery, {
    variables: {
      activeDishesInput: {
        bizId: restaurantId,
        status: 'ACTIVE'
      },
      hiddenDishesInput: {
        bizId: restaurantId,
        status: 'HIDDEN'
      }
    },
    skip: !restaurantId
  });

  useEffect(() => {
    if (data) {
      const { activeDishes, hiddenDishes } = data;

      if (activeDishes && hiddenDishes) {
        const activeDishes1 = map(activeDishes.edges, ({ node }) => node);

        const hiddenDishes1 = map(hiddenDishes.edges, ({ node }) => node);

        const _dishes = union(activeDishes1, hiddenDishes1);

        const dishesObjectArray = plainToClass(Dish, _dishes);

        const categories = uniq(
          map(dishesObjectArray, ({ getCategory }) => {
            return getCategory;
          })
        );

        dispatch({
          payload: categories,
          type: 'SET_CATEGORY_LIST'
        });

        setDishes(_dishes);
      }
    }
  }, [data, dispatch, error, loading]);

  return {
    loading,
    dishes,
    error
  };
};

export const useGetDishQuery = (id: string | undefined | null) => {
  const { restaurantId } = useActiveRestaurant();

  const [dish, setDish] = useState<Dish | null>(null);

  const { loading, data, error } = useQueryWithLoader<GetDishQuery>(getDishQuery, {
    variables: {
      input: {
        bizId: restaurantId,
        id
      }
    },
    skip: !restaurantId || !id
  });

  useEffect(() => {
    if (data && data.getDish) {
      const dishObject = plainToClass(Dish, data.getDish);

      setDish(dishObject);
    }
  }, [data]);

  return {
    loading,
    dish,
    error
  };
};

export const useGetActiveDishesQuery = () => {
  const { restaurantId } = useActiveRestaurant();

  const { dispatch } = useStore();

  const [dishes, setDishes] = useState<Dish[] | null>(null);

  const [hasMore, setHasMore] = useState<boolean | null | undefined>(false);

  const [endCursor, setEndCursor] = useState<string | null | undefined>(null);

  const { loading, data, error, fetchMore } = useQueryWithLoader<GetActiveDishesQuery>(getActiveDishesQuery, {
    variables: {
      input: {
        bizId: restaurantId,

        first: pageLength
      }
    },
    skip: !restaurantId
  });

  useEffect(() => {
    if (data) {
      const { getDishes } = data;

      if (getDishes && getDishes.pageInfo) {
        const { hasNextPage, endCursor } = getDishes.pageInfo;
        setHasMore(hasNextPage);

        setEndCursor(endCursor);

        if (!hasNextPage && getDishes.edges?.length) {
          let dishEdgeArray = map(getDishes.edges, ({ node }) => node);

          dishEdgeArray = filter(dishEdgeArray, ({ status }) => status === 'ACTIVE');

          const dishesArray = plainToClass(Dish, dishEdgeArray);

          const categories = uniq(
            map(dishesArray, ({ getCategory }) => {
              return getCategory;
            }).filter((category: string) => category !== 'Table Services')
          );

          dispatch({
            payload: categories,
            type: 'SET_CATEGORY_LIST'
          });

          if (categories && categories.length) {
            dispatch({
              type: 'SET_ACTIVE_CATEGORY',
              payload: categories.sort()[0]
            });
          }

          setDishes(dishesArray);
        }
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (hasMore) {
      try {
        fetchMore({
          variables: {
            input: {
              bizId: restaurantId,

              first: pageLength,
              after: endCursor
            }
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const previousEntry = map(previousResult.getDishes.edges, ({ node }) => node);

            const newComments = map(fetchMoreResult?.getDishes.edges, ({ node }) => node);

            const updated = [...previousEntry, ...newComments];

            return {
              getDishes: {
                edges: map(updated, (i) => {
                  return {
                    node: i,
                    __typename: 'DishConnectionEdge'
                  };
                }),
                pageInfo: {
                  endCursor: fetchMoreResult?.getDishes.pageInfo.endCursor,
                  hasNextPage: fetchMoreResult?.getDishes.pageInfo.hasNextPage,
                  __typename: fetchMoreResult?.getDishes.pageInfo.__typename
                },
                __typename: previousResult.getDishes.__typename
              },
              __typename: previousResult.__typename
            };
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [dispatch, endCursor, fetchMore, hasMore, restaurantId]);

  return {
    loading,
    dishes,
    error,
    hasMore,
    fetchMore,
    endCursor
  };
};

export const useGetDishCategories = () => {
  const { restaurantId } = useActiveRestaurant();

  const { dispatch } = useStore();

  const [dishCategories, setDishCategories] = useState<IDishCategories[] | null>(null);

  const { loading, data, error } = useQueryWithLoader(getDishCategoriesQuery, {
    variables: {
      input: {
        bizId: restaurantId,
        noEmpty: true
      }
    },
    skip: !restaurantId
  });

  /**
   * Setting the first category of the result as active category
   */
  useEffect(() => {
    if (data && data.getDishCategoriesOfRestaurant) {
      const { getDishCategoriesOfRestaurant } = data;

      if (getDishCategoriesOfRestaurant && getDishCategoriesOfRestaurant.length) {
        const categories = chain(getDishCategoriesOfRestaurant)
          .filter(({ dishes, title: categoryTitleArray }) => {
            const [{ text: categoryTitle }] = categoryTitleArray || [{ text: '' }];
            const dishLength = filter(dishes, ({ status }) => !['DELETED', 'HIDDEN'].includes(status)).length;
            if (categoryTitle !== 'Table Services' && dishLength) {
              return true;
            }
            return false;
          })
          .map(({ id: categoryId, title: categoryTitleArray }) => {
            const [{ text: categoryTitle }] = categoryTitleArray || [{ text: '' }];

            return { id: categoryId, title: categoryTitle };
          })
          .value();

        setDishCategories(categories);

        dispatch({
          type: 'SET_ACTIVE_CATEGORY',
          payload: categories[0].title
        });
      }
    }
  }, [data, dispatch, error, loading]);

  return {
    loading,
    dishCategories,
    data,
    error
  };
};

export const useGetAllActiveDishesQuery = () => {
  const { restaurantId } = useActiveRestaurant();

  const { dispatch } = useStore();

  const [dishes, setDishes] = useState<Dish[] | null>(null);

  const [dishCategories, setDishCategories] = useState<IDishCategories[] | null>(null);

  const { data, error, loading } = useQueryWithLoader(getAllActiveDishesQuery, {
    variables: {
      input: {
        bizId: restaurantId
      }
    },
    skip: !restaurantId
  });

  /**
   * Filtering the dishes where status is active and category is not Table Service
   */
  useEffect(() => {
    if (data) {
      const { getAllDishes } = data;

      if (getAllDishes) {
        const filteredDishes = filter(getAllDishes, ({ status, category }) => status === 'ACTIVE' && category !== 'Table Services');

        const dishesArray = plainToClass(Dish, filteredDishes).filter(({ getCategory }) => {
          if (getCategory === 'Table Services') {
            return false;
          }

          return true;
        });

        const dishCategories = chain(dishesArray)
          .map(({ getCategoryId, getCategory }) => {
            return { id: getCategoryId, title: getCategory };
          })
          .uniqBy('title')
          .value();

        setDishCategories(dishCategories);

        dispatch({
          type: 'SET_ACTIVE_CATEGORY',
          payload: 'All'
        });

        dispatch({
          type: 'SET_LASTACTIVERESTAURANT',
          payload: restaurantId
        });

        setDishes(dishesArray);
      }
    }
  }, [data, dispatch, restaurantId]);

  return {
    loading,
    dishes,
    dishCategories,
    error
  };
};

export const useGetRewardDishes = (bizId: string) => {
  const status = 'ACTIVE';

  const { loading, data } = useQueryWithLoader(getRewardItemsQuery, {
    variables: {
      input: {
        bizId,
        status
      }
    },
    skip: !bizId
  });

  if (data && data.getRewardItems) {
    const count = data.getRewardItems.length;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const rewardItems = plainToClass(Dish, <Dish[]>data.getRewardItems);

    return {
      loading,
      count,
      rewardItems
    };
  }

  return {
    loading,
    count: 0,
    rewardItems: null
  };
};

export const useCreateReview = (restaurantId: string) => {
  const [createReview, { data, loading, error }] = useMutationWithLoader(
    createReviewMutation,
    {
      refetchQueries: [
        {
          query: getBuyerOrdersQuery,
          variables: {
            input: {
              first: 40
            }
          }
        },
        {
          query: getAllActiveDishesQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        }
      ]
    },

    true
  );

  return {
    createReview,
    data,
    loading,
    error
  };
};
