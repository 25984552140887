import React, { useState, useEffect } from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import moment from 'moment';
import { IAddress } from 'components/AddressModal/types';
import { Address } from 'generated/types';

const ORDER_TYPE = {
  DELIVERY: 'DELIVERY',
  TAKE_OUT: 'TAKEOUT',
  DINING: 'DINING',
  '': ''
};

type orderType = 'DELIVERY' | 'TAKE_OUT' | 'DINING' | '';

interface checkoutSummaryProps {
  restaurantName: string | undefined;
  restaurantAddress: Address | undefined | null;
  orderType: orderType;
  partySize?: number;
  tableNumber?: string;
  deliveryAddress: IAddress | null;
  eta: number;
}

const useStyles = makeStyles((theme: any) => ({
  light: {
    fontWeight: 400
  },
  cardData: {
    padding: theme.spacing(0.5)
  },
  orderDetails: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: theme.palette.grey[100]
  }
}));

export const CheckoutSummary: React.FC<checkoutSummaryProps> = ({ restaurantName, restaurantAddress = null, orderType, partySize = 0, tableNumber = '', deliveryAddress = null, eta }) => {
  const classes = useStyles();

  const [estimatedTime, setEstimatedTime] = useState('');

  useEffect(() => {
    const reference = moment();

    const today = reference.clone().startOf('day');

    const tomorrow = reference
      .clone()
      .add(1, 'days')
      .startOf('day');

    const selectedSlot = moment(eta);

    const slotTime = selectedSlot.format('LT');

    if (selectedSlot.isSame(today, 'day')) {
      setEstimatedTime(`Today, ${slotTime}`);
    } else if (selectedSlot.isSame(tomorrow, 'day')) {
      setEstimatedTime(`Tomorrow, ${slotTime}`);
    } else {
      setEstimatedTime(selectedSlot.format('DD MMM, dddd, LT'));
    }
  }, [eta]);

  const renderRow = (label: string, data: any) => {
    return (
      <Box className={classes.cardData}>
        <Typography className={classes.light} variant="body1">
          {label}:&nbsp;<b>{data}</b>
        </Typography>
      </Box>
    );
  };

  const getDeliverAddress = () => {
    const { text: deliveryAddressText } = deliveryAddress || { text: '' };

    if (deliveryAddressText && orderType === 'DELIVERY') {
      return renderRow('Deliver to', deliveryAddressText);
    }
  };

  const getDiningData = () => {
    if (orderType === 'DINING') {
      return (
        <>
          {renderRow('No. of people', partySize)}
          {renderRow('Table number', tableNumber)}
        </>
      );
    }
  };

  const getETA = () => {
    if (orderType === 'TAKE_OUT' || orderType === 'DELIVERY') {
      return renderRow('ETA', estimatedTime);
    }
  };

  return (
    <Box className={classes.orderDetails}>
      <Box className={classes.cardData}>
        <Typography variant="h6">{restaurantName}</Typography>
        {restaurantAddress && <Typography variant="body1">{restaurantAddress.text}</Typography>}
      </Box>
      {getDeliverAddress()}
      {getDiningData()}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {getETA()}
        <Typography className={classes.light} variant="body1">
          <b>{ORDER_TYPE[orderType]}</b>
        </Typography>
      </Box>
    </Box>
  );
};

export default CheckoutSummary;
