import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Restaurant from '.';
import Rewards from '../Rewards';

const RestaurantRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact={true} path="/restaurants" render={() => <Redirect to="/" />} />
      <Route path="/restaurants/:restaurantId/rewards" component={Rewards} />
      <Route path="/restaurants/:restaurantId" component={Restaurant} />
    </Switch>
  );
};

export default RestaurantRoutes;
