import React from 'react';
import { useStyles } from '../style';
import { Box, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PopularIcon from 'assets/img/popular_icon.svg';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

interface IProps {
  dishTitle: string | null;
  dishDescription: string | null;
  handleClose: () => void;
  imgSrc: string | null;
  isHeaderExpanded: boolean;
  showImg: () => void;
  hideImg: () => void;
  isPopular: Boolean;
  isAlcohol: Boolean;
  rateScoreValue: number;
  rateCountValue: number;
}

const ShrinkingHeader: React.FC<IProps> = ({ dishTitle, dishDescription, isPopular, handleClose, hideImg, isHeaderExpanded, imgSrc, showImg, isAlcohol, rateScoreValue, rateCountValue }) => {
  const classes = useStyles();

  return (
    <Box className={classes.dishHeaderShrinked}>
      <Grid container>
        <Slide in={!isHeaderExpanded} direction="right" {...(!isHeaderExpanded ? { timeout: 800 } : {})}>
          <Grid item xs={2}>
            <Box display="flex" className={classes.dishSmallIcon} style={imgSrc ? { backgroundImage: `url('${imgSrc}')` } : { background: '#fff' }}>
              <Box borderRadius={4} boxShadow={5} bgcolor="rgba(255,255,255,0.8)" padding={0}>
                <AddIcon fontSize="small" onClick={showImg} color="inherit" style={{ cursor: 'pointer', display: 'flex' }} />
              </Box>
            </Box>
          </Grid>
        </Slide>
        <Fade in={!isHeaderExpanded} {...(!isHeaderExpanded ? { timeout: 1000 } : {})}>
          <Grid item xs={9} className={classes.alignTitle}>
            <Box display="flex" className={imgSrc ? classes.paddingL : undefined} width="100%" alignItems="center" justifyItems="center">
              <Typography variant="h6" color="inherit" className={classes.dishTitleWhite}>
                {dishTitle}
              </Typography>
              {isPopular && (
                <Box marginY={1} display="flex" alignItems="center" justifyItems="center">
                  <img src={PopularIcon} alt="Popular" />
                </Box>
              )}
            </Box>
          </Grid>
        </Fade>
        {!isHeaderExpanded ? (
          <Grid item xs={1} className={classes.alignTitle}>
            <Box display="flex" className={imgSrc ? classes.paddingL : undefined} justifyContent="flex-end">
              <CloseIcon onClick={handleClose} color="inherit" style={{ cursor: 'pointer', display: 'flex' }} />
            </Box>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};
export default ShrinkingHeader;
