import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: '0px !important',
    // background: "url('https://images.unsplash.com/photo-1564759298141-cef86f51d4d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60') no-repeat center center fixed",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: 'color .1s ease-in-out,opacity .1s ease-in-out',
    '&:hover': {
      color: '#111'
    }
    // backgroundColor: 'rgb(11, 21, 23)'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));
