import React, { useState, useContext } from 'react';
import { Box, Button, IconButton, InputAdornment } from '@material-ui/core';
import Loading from './Loading';
import 'react-phone-number-input/style.css';
import { FirebaseContext } from 'fbase';
import validations from './validations';
import { useFormik, FormikHelpers, getIn } from 'formik';
import { IFormikInitialState } from './types';
import { useStore } from 'store';
import InputField from 'components/Inputs/InputField';
import MailIcon from '@material-ui/icons/Mail';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonIcon from '@material-ui/icons/Person';
import { useStyles } from './style';
import FormDialog from 'components/FormDialog';
import ClearIcon from '@material-ui/icons/Clear';
import { grey } from '@material-ui/core/colors';
import { useAlert } from 'hooks';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const Register: React.FC<IProps> = ({ setStep }) => {
  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const classes = useStyles();

  const [{ done }, setProgress] = useState({ loading: false, done: false });

  const { dispatch } = useStore();

  const [isNickNameSuggest, setIsNickNameSuggest] = useState(false);

  const { lokoAlert } = useAlert();
  /**
   *
   *
   * @param values - Register values
   * @param actions - Formik Helpers
   */
  const onSubmit = async (values: IFormikInitialState, actions: FormikHelpers<IFormikInitialState>) => {
    actions.setSubmitting(true);

    setProgress((prev) => ({ ...prev, loading: true }));

    const { email, password, displayName } = values;

    try {
      /**
       * If email already exists link it with credentials and update user profile.
       */
      const { user } = await auth().createUserWithEmailAndPassword(email, password);

      user?.updateProfile({
        displayName
      });

      dispatch({
        type: 'UI_ACTION_LOGIN_POPUP',
        payload: false
      });

      dispatch({
        type: 'UI_ACTION_UPDATED_TERMS_POPUP',
        payload: true
      });

      actions.setSubmitting(false);
    } catch (e) {
      let message = '';

      if (e.code) {
        switch (e.code) {
          case 'auth/provider-already-linked':
            message = 'Email already exists choose different email';
            break;
          case 'auth/invalid-credential':
            message = 'Invalid credentials, Please try again';
            break;
          case 'auth/credential-already-in-use':
            message = 'Email already exists.';
            break;
          case 'auth/email-already-in-use':
            message = 'Email already exists';
            break;
          case 'auth/invalid-email':
            message = 'Invalid email';
            break;
          case 'auth/wrong-password':
            message = 'Invalid password';
            break;
          case 'auth/invalid-verification-code':
            message = 'Verification code is wrong';
            break;
          case 'auth/invalid-verification-id':
            message = 'Invalid verfication id, Please try again';
            break;
          case 'auth/weak-password':
            message = 'Password not strong enough';
            break;
          default:
            message = e.message;
            console.log(e.message);
        }
        actions.setSubmitting(false);
        lokoAlert(message);
      }
    }
  };

  const formik = useFormik<IFormikInitialState>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      displayName: ''
    },
    validateOnMount: true,
    validationSchema: validations,
    onSubmit
  });

  // const { emailAlreadyExists } = formik.values;

  const isSubmitButtonDisabled = !formik.isValid;

  const onBack = () => {
    setStep(1);
  };
  const setNickNameFromEmail = () => {
    if (formik.values.displayName === '') {
      let emailItem = formik.values.email;
      let newDisplayName = emailItem.split('@').shift();
      formik.setFieldTouched('displayName');
      formik.setFieldValue('displayName', newDisplayName);
      setIsNickNameSuggest(true);
    }
  };
  const handleClearNickName = () => {
    formik.setValues({
      ...formik.values,
      displayName: ''
    });
  };

  const handleMouseDownClear = (event: any) => {
    event.preventDefault();
  };
  return (
    <>
      <Loading open={formik.isSubmitting} done={done} />
      <FormDialog title="Register" onBack={onBack}>
        <form className={classes.formWrapper} onSubmit={formik.handleSubmit}>
          <InputField
            className={classes.textField}
            name="email"
            type="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            onBlur={(e) => {
              formik.handleBlur('email');
              if (!getIn(formik.errors, 'email')) {
                setNickNameFromEmail();
              }
            }}
            error={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email')}
            helperText={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email') ? getIn(formik.errors, 'email') : ''}
            startIcon={<MailIcon />}
            autoCapitalize="off"
            variant="outlined"
          />
          <InputField
            className={classes.textField}
            name="password"
            type="password"
            placeholder="Password"
            onChange={formik.handleChange}
            error={!!getIn(formik.touched, 'password') && !!getIn(formik.errors, 'password')}
            helperText={!!getIn(formik.touched, 'password') && !!getIn(formik.errors, 'password') ? getIn(formik.errors, 'password') : ''}
            onBlur={formik.handleBlur}
            startIcon={<VpnKeyIcon />}
            variant="outlined"
          />
          <InputField
            className={classes.textField}
            name="confirmPassword"
            type="password"
            placeholder="Confirm password"
            onChange={formik.handleChange}
            error={!!getIn(formik.touched, 'confirmPassword') && !!getIn(formik.errors, 'confirmPassword')}
            helperText={!!getIn(formik.touched, 'confirmPassword') && !!getIn(formik.errors, 'confirmPassword') ? getIn(formik.errors, 'confirmPassword') : ''}
            onBlur={formik.handleBlur}
            startIcon={<VpnKeyIcon />}
            variant="outlined"
          />
          <InputField
            className={classes.textField}
            name="displayName"
            placeholder="Display Name"
            onChange={formik.handleChange}
            error={!!getIn(formik.touched, 'displayName') && !!getIn(formik.errors, 'displayName')}
            helperText={!!getIn(formik.touched, 'displayName') && !!getIn(formik.errors, 'displayName') ? getIn(formik.errors, 'displayName') : ''}
            onBlur={formik.handleBlur}
            InputProps={{
              style: {
                color: isNickNameSuggest ? grey[500] : 'black'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="handleClearNickName" onClick={handleClearNickName} onMouseDown={handleMouseDownClear}>
                    {formik.values?.displayName !== '' && <ClearIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            startIcon={<PersonIcon />}
            variant="outlined"
            onFocus={(e) => {
              formik.handleBlur(e);
              setIsNickNameSuggest(false);
            }}
            value={formik.values.displayName}
          />
          <Box marginTop={1}>
            <Button type="submit" size="large" variant="contained" color="primary" disabled={isSubmitButtonDisabled} fullWidth={true} disableElevation={true}>
              Submit
            </Button>
          </Box>
        </form>
      </FormDialog>
    </>
  );
};

export default Register;
