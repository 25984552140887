import React, { useContext } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useFormik, getIn } from 'formik';
import validationSchema from './validations';
import { FirebaseContext } from 'fbase';
import { useSnackbar } from 'notistack';
import FormDialog from 'components/FormDialog';
import InputField from 'components/Inputs/InputField';
import MailIcon from '@material-ui/icons/Mail';
import { useAlert } from 'hooks';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ForgotPassword: React.FC<IProps> = ({ setStep }) => {
  const { firebase } = useContext(FirebaseContext);

  const auth = firebase!.getAuth();

  const snackbar = useSnackbar();

  const { lokoAlert } = useAlert();

  const onBack = () => {
    setStep(1);
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    initialErrors: {
      email: ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ email }, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      try {
        await auth().sendPasswordResetEmail(email);

        resetForm();

        snackbar.enqueueSnackbar('Password reset send to your email', {
          variant: 'success'
        });

        setSubmitting(false);
      } catch (e) {
        lokoAlert('Email was not sent');
        setSubmitting(false);
      }
    }
  });

  return (
    <>
      <FormDialog onBack={onBack} title="Forgot Password">
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="body1" align="center" paragraph={true}>
            Enter your email to reset your password
          </Typography>
          <Box marginBottom={2}>
            <InputField
              name="email"
              type="email"
              value={formik.values.email}
              placeholder="Enter your email"
              onChange={formik.handleChange}
              error={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email')}
              helperText={!!getIn(formik.touched, 'email') && !!getIn(formik.errors, 'email') ? getIn(formik.errors, 'email') : ''}
              autoComplete="off"
              onBlur={formik.handleBlur}
              fullWidth={true}
              startIcon={<MailIcon />}
              variant="outlined"
            />
          </Box>

          <Button type="submit" size="large" variant="contained" color="primary" fullWidth={true} disabled={!formik.isValid} disableElevation={true}>
            Reset
          </Button>
        </form>
      </FormDialog>
    </>
  );
};

export default ForgotPassword;
