import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%'
  },
  box: { position: 'relative' },
  dishContainer: {
    height: '100%',
    cursor: 'pointer'
  },
  ribbon: {
    width: '150px',
    height: '150px',
    overflow: 'hidden',
    position: 'absolute',
    top: '-10px',
    left: '-10px',
    '&::before': { position: 'absolute', zIndex: -1, content: "''", display: 'block', border: '5px solid #2980b9', top: '0', right: '0' },
    '&::after': {
      position: 'absolute',
      zIndex: -1,
      content: "''",
      display: 'block',
      border: '5px solid #2980b9',
      bottom: '0',
      left: '0'
    },
    '& span': {
      position: 'absolute',
      display: 'block',
      width: '225px',
      padding: '15px 0',
      backgroundColor: '#3498db',
      boxShadow: '0 5px 10px rgba(0,0,0,.1)',
      color: '#fff',
      font: "700 12px/1 'Lato', sans-serif",
      textShadow: '0 1px 1px rgba(0,0,0,.2)',
      textTransform: 'uppercase',
      textAlign: 'center',
      right: '-25px',
      top: '30px',
      transform: 'rotate(-45deg)'
    }
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1)
  },
  priceItem: {
    padding: theme.spacing(0.1),
  },
  priceStriked: {
    marginRight: theme.spacing(1.5) ,
    color: '#7e818c',
    textDecoration: 'line-through'
  },

  priceDiscount: {    
    background: green[800], 
    color: 'white',
    padding: theme.spacing(0.1, 0.5),
    marginRight:theme.spacing(1),
    fontSize: '1rem',
    borderRadius: '3px',    
  },

  rateScore:{    
        
    fontWeight:700   
  }
 
}));
