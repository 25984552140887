import React from 'react';
import { useGetAllActiveDishesQuery, useGetCouponsByBizId, useGetRestaurantById } from 'graphql/hooks';
import restaurant_empty_icon from 'assets/img/restaurant-empty-icon.png';
import { Box, Button, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useStyles } from './style';
import FlashDeals from 'components/RestaurantDeals/FlashDeals';
import S3Image from 'components/S3Image';
import PhoneIcon from '@material-ui/icons/Phone';
import { formatPhoneNumber } from 'util/number';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import useRestaurant from 'hooks/restaurant/useRestaurant';
import { useHistory } from 'react-router-dom';
import { Coupon } from 'generated/types';
import CouponCard from 'components/CouponCard';

function RestaurantDeals() {
  const classes = useStyles();

  const { restaurantID } = useRestaurant();

  const { restaurant, loading: restaurantLoading } = useGetRestaurantById(restaurantID, 'ALL');

  const { coupons: restaurantCoupons } = useGetCouponsByBizId(restaurant?.id!!);

  const history = useHistory();

  const { dishes, loading: dishLoading } = useGetAllActiveDishesQuery();

  const discountedDishesData = dishes?.filter((dishes) => dishes.isDiscounted === true && dishes.getMenuPrice.maxDiscountPercent > 0) || [];

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const phone = restaurant?.getPhone;

  let formattedPhone = null;
  if (phone) {
    formattedPhone = formatPhoneNumber(phone);
  }

  const location = restaurant?.getLatLng;

  if (restaurantLoading || dishLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <Typography variant="body1">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="auto">
      {!isDesktop ? (
        <Box className={classes.banner} paddingTop={2}>
          {restaurant && (
            <Box display="flex" alignItems="center" flexDirection="column">
              <Grid container={true} alignItems="center">
                <Grid item={true} xs={6}>
                  <Box paddingX={1}>
                    <S3Image src={restaurant.getLogo} className={classes.image} defaultImage={restaurant_empty_icon} />
                  </Box>
                </Grid>
                <Grid item={true} xs={6}>
                  <Box display="flex" flexDirection="column" className={classes.restaurantName}>
                    <Typography variant="h6" noWrap={false}>
                      {restaurant.getName}
                    </Typography>
                    <Typography variant="body1" noWrap={false}>
                      {restaurant.getSubName}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
                <Box display="flex" justifyItems="center" alignItems="center" width="100%" justifyContent="center">
                  <LocationOnIcon fontSize="small" className={classes.locationIcon} />
                  <Typography variant="caption" className={classes.restaurantAbout}>
                    {restaurant.address?.text}
                  </Typography>
                </Box>
              </a>
              <a href={`tel:${phone}`} className={classes.link}>
                <Box display="flex" justifyItems="center" alignItems="center" className={classes.phoneContainer}>
                  <PhoneIcon fontSize="small" className={classes.phoneIcon} />
                  <Typography variant="caption" className={classes.restaurantAbout}>
                    {formattedPhone ? formattedPhone : phone}
                  </Typography>
                </Box>
              </a>
              <Box display="flex" alignItems="center" justifyContent="center" paddingBottom={1}>
                <Typography variant="caption">Powered by</Typography>
                <Box paddingX={0.5}>
                  <Typography variant="body1">Lokobee</Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box className={classes.bannerWhite}>
            <Box display="flex" justifyContent="center">
              <Button
                size="medium"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  history.push(`/restaurants/${restaurantID}`);
                }}>
                <Typography variant="body1">Order Online</Typography>
              </Button>
            </Box>
            <Box width="100%" paddingY={1}>
              <Divider variant="middle" />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.banner}>
          {restaurant && (
            <Grid container={true} direction="row" alignItems="center">
              <Grid item={true} md={5}>
                <Grid container={true} alignItems="center">
                  <Grid item={true} md={6}>
                    <Box paddingX={1} className={classes.imageBox}>
                      <S3Image src={restaurant.getLogo} className={classes.DesktopImage} defaultImage={restaurant_empty_icon} />
                    </Box>
                  </Grid>
                  <Grid item={true} md={6}>
                    <Typography variant="h6" noWrap={false}>
                      {restaurant.getName}
                    </Typography>
                    <Typography variant="body1" noWrap={false}>
                      {restaurant.getSubName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} md={4} className={classes.aboutDesktop}>
                <Grid container={true} direction="column">
                  <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
                    <Box display="flex" justifyItems="center" alignItems="center" width="100%" justifyContent="start">
                      <LocationOnIcon fontSize="small" className={classes.locationIcon} />
                      <Typography variant="caption" className={classes.restaurantAbout}>
                        {restaurant.address?.text}
                      </Typography>
                    </Box>
                  </a>
                  <a href={`tel:${phone}`} className={classes.link}>
                    <Box display="flex" justifyItems="start" alignItems="center" width="100%">
                      <PhoneIcon fontSize="small" className={classes.phoneIcon} />
                      <Typography variant="caption" className={classes.restaurantAbout}>
                        {formattedPhone ? formattedPhone : phone}
                      </Typography>
                    </Box>
                  </a>
                </Grid>
              </Grid>
              <Grid item={true} md={3}>
                <Grid container={true} direction="column">
                  <Box display="flex" justifyContent="center">
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => {
                        history.push(`/restaurants/${restaurantID}`);
                      }}>
                      <Typography variant="body1">Order Online</Typography>
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center" paddingBottom={1}>
                    <Typography variant="caption">Powered by</Typography>

                    <Box paddingX={0.5}>
                      <Typography variant="body1">Lokobee</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <Box flex={1} paddingTop={1} className={classes.scrollComponent}>
        {restaurantCoupons && restaurantCoupons.length === 0 && discountedDishesData.length === 0 && !restaurantLoading && !dishLoading ? (
          <Box display="flex" justifyContent="center" paddingX={2}>
            <Typography variant="body1" noWrap={false}>
              Sorry currently no deals available please come back later
            </Typography>
          </Box>
        ) : (
          <>
            {restaurantID && (
              <>
                <Box padding={isDesktop ? 2 : 1} paddingX={isDesktop ? 4 : 1}>
                  <Grid container={true} spacing={1} alignItems="stretch">
                    {restaurantCoupons &&
                      restaurantCoupons?.map((coupon: Coupon, index: number) => {
                        return (
                          <Grid item={true} sm={6} md={4} xs={12} zeroMinWidth={true} key={index}>
                            <Box minHeight={isDesktop ? 135 : 0} padding={1}>
                              <CouponCard couponData={coupon} />
                            </Box>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
                <Box paddingX={isDesktop ? 4 : 0}>
                  <FlashDeals getAllDishes={discountedDishesData} keepSmGrid={false} />
                </Box>{' '}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default RestaurantDeals;
