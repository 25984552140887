import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    flexGrow: 1,
    color: '#000',
    backgroundColor: '#fff',
    borderBottom: `1px solid ${grey[100]}`,
    zIndex: 10
  },
  toolbar: {
    [theme.breakpoints.only('sm')]: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.only('md')]: {},
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      minWidth: 1200,
      margin: 'auto'
    }
  },
  titleContainer: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexGrow: 1,
    textAlign: 'left'
  },
  icon: {
    width: '30px',
    marginRight: theme.spacing(1)
  },
  logo: {
    padding: theme.spacing(1),
    width: theme.spacing(6),
    cursor: 'pointer',
    margin: 'auto'
  },
  locationIcon: {
    color: theme.palette.grey[500]
  },
  restaurantLogoBox: {
    [theme.breakpoints.up('sm')]: {
      width: '14%'
    },
    [theme.breakpoints.up('md')]: {
      width: '10%'
    },
    width: '18%',
    borderRadius: '3px',

    padding: theme.spacing(1)
  },
  boxCart: {
    height: '3rem',
    width: '4rem',
    background: theme.palette.primary.main,
    borderRadius: '0.5rem 0px 0rem 0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center'
  },
  headerBtn: {
    textTransform: 'capitalize'
  }
}));
