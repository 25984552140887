import { gql } from 'apollo-boost';

export const getLokobeeUserQuery = gql`
  query getLokobeeUser {
    getLokobeeUser {
      uid
      displayName
      smsForDeals
      emailForDeals
      email
      orderEmail
      orderPhoneNumber
      stripeChargeSources {
        id
        brand
        last4
      }
    }
  }
`;

export const getLokobeeUserQueryStr = `
  query getLokobeeUser {
    getLokobeeUser {
      uid
      displayName
      smsForDeals
      emailForDeals
      email
      orderEmail
      orderPhoneNumber
      stripeChargeSources {
        id
        brand
        last4
      }
    }
  }
`;

export const getUserRewardPointsQuery = gql`
  query getRewardPointsForConsumer($input: GetRewardPointsForConsumerInput!) {
    getRewardPointsForConsumer(input: $input) {
      id
      type
      issuer
      activePoints
      pendingPoints
    }
  }
`;

export const getUserGroceryRewardPointsQuery = gql`
  query getGroceryRewardPointsForConsumer($input: GetGroceryRewardPointsForConsumerInput!) {
    getGroceryRewardPointsForConsumer(input: $input) {
      id
      type
      issuer
      activePoints
      pendingPoints
    }
  }
`;
