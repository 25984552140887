import React, { useContext, useState } from 'react';
import { BottomNavigation as MBottomNavigation, BottomNavigationAction, useMediaQuery, Box, Badge } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { AuthContext } from 'fbase/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './style';
import { useStore } from 'store';
import { grey } from '@material-ui/core/colors';
import logo from 'assets/img/icon-1024.png';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import InfoIcon from '@material-ui/icons/Info';

const BottomNavigation = () => {
  const classes = useStyles();

  const history = useHistory();

  const { dispatch } = useStore();

  const { pathname } = useLocation();

  const { currentUser } = useContext(AuthContext);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  const isAnonymousUser = currentUser?.isAnonymous;
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        history.push('/');
        break;
      case 1:
        history.push('/myaccount');
        break;
      case 2:
        history.push('/orders');
        break;
      case 3:
        dispatch({
          type: 'UI_ACTION_LOGIN_POPUP',
          payload: true
        });
        break;
      case 4:
        history.push('/wallet');
        break;
    }
  };

  const [showIcon] = useState({
    icon: false,
    length: 0
  });

  //This is for delivery icon
  // const { data, loading, error } = useGetDeliveryOrder(DeliveryProviders.Boons);

  // useEffect(() => {
  //   const showIcon = ongoingOrders.delivery.BOONS ? true : false;
  //   setShowIcon({ icon: showIcon, length: ongoingOrders.delivery.BOONS });
  // }, [ongoingOrders]);

  const getValue = () => {
    switch (pathname) {
      case '/':
        return 0;
      case '/myaccount':
        return 1;
      case '/orders':
        return 2;
      case '/signIn':
        return 3;
      case '/wallet':
        return 4;
    }
  };

  const my_orders_icon = () => {
    const total_delivery_orders = showIcon.length;

    if (showIcon.icon) {
      return (
        <Box display="flex" justifyContent="center">
          <Badge badgeContent={total_delivery_orders} color="primary">
            <LocalShippingIcon />
          </Badge>
        </Box>
      );
    }

    return (
      <Box display="flex" justifyContent="center">
        <LocalMallIcon />
      </Box>
    );
  };

  return (
    <Box hidden={isDesktop} borderTop={`1px solid ${grey[100]}`}>
      <MBottomNavigation className={classes.root} value={getValue()} onChange={handleChange} showLabels={true} color="primary">
        <BottomNavigationAction label="" icon={<img src={logo} alt="logo" width="30px" height="30px" />} />
        {currentUser && !isAnonymousUser ? <BottomNavigationAction label="My Account" icon={<PersonIcon />} /> : <BottomNavigationAction label="About" icon={<InfoIcon />} />}
        {currentUser && !isAnonymousUser && <BottomNavigationAction label="My Orders" icon={my_orders_icon()} />}
        {(!currentUser || isAnonymousUser) && <BottomNavigationAction label="Sign In" icon={<ExitToApp />} />}
        {/* <BottomNavigationAction
          label="Wallet"
          icon={
            <Badge
              badgeContent={'New'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              color="error">
              <AccountBalanceWalletIcon />
            </Badge>
          }
        /> */}
      </MBottomNavigation>
    </Box>
  );
};

export default BottomNavigation;
