import { getLocalStorage, setLocalStorage } from 'util/storage';

export interface IUserActionsInitialState {
  user: {
    address: string;
    coordinate: {
      latitude: number;
      longitude: number;
    };
    smsForDeals: boolean;
    emailForDeals: boolean;
    email: string;
    orderPhoneNumber: string;
    orderPhoneNumberFromUrl?: string;
    discardAfterOrderSuccess: boolean;
    nickName?: string;
  };
}

const getUserIntitialState = () => {
  const user = getLocalStorage('user');

  if (user) {
    const parsedUserData = JSON.parse(user);

    return {
      user: {
        ...parsedUserData
      }
    };
  }

  return {
    user: {
      address: 'Pleasanton, CA, USA',
      coordinate: { latitude: 37.6604, longitude: -121.8758 },
      smsForDeals: false,
      emailForDeals: false,
      email: '',
      phoneNumber: '',
      discardAfterOrderSuccess: false
    }
  };
};

export const userActionsInitialState: IUserActionsInitialState = getUserIntitialState();

export const userActions = {
  USER_ACTION_SET_ADDRESS: (state: IUserActionsInitialState, payload: string) => {
    const userAdressJSON = JSON.stringify({
      ...state.user,
      address: payload
    });

    setLocalStorage('user', userAdressJSON);

    return {
      ...state,
      user: {
        ...state.user,
        address: payload
      }
    };
  },
  USER_ACTION_SET_COORDINATES: (state: IUserActionsInitialState, { latitude, longitude }: any) => {
    const coordinateJSON = JSON.stringify({
      ...state.user,
      coordinate: {
        latitude,
        longitude
      }
    });

    setLocalStorage('user', coordinateJSON);

    return {
      ...state,
      user: {
        ...state.user,
        coordinate: {
          ...state.user.coordinate,
          latitude,
          longitude
        }
      }
    };
  },
  USER_ACTION_SET_PROMOTION_PREFERENCE: (state: IUserActionsInitialState, { smsForDeals, emailForDeals }: any) => {
    return { ...state, user: { ...state.user, smsForDeals, emailForDeals } };
  },
  USER_ACTION_SET_CONTACT_DETAILS: (
    state: IUserActionsInitialState,
    {
      email,
      phoneNumber,
      discardAfterOrderSuccess,
      nickName,
      orderPhoneNumberFromUrl
    }: { email: string; phoneNumber: string; discardAfterOrderSuccess: boolean; nickName: string; orderPhoneNumberFromUrl: string }
  ) => {
    const userState = { ...state.user, discardAfterOrderSuccess, nickName };

    if (email !== undefined && email !== null) {
      userState.email = email;
    }
    if (phoneNumber !== undefined && phoneNumber !== null) {
      userState.orderPhoneNumber = phoneNumber;
    }
    if (orderPhoneNumberFromUrl !== undefined && orderPhoneNumberFromUrl !== null) {
      userState.orderPhoneNumberFromUrl = orderPhoneNumberFromUrl;
    }

    return { ...state, user: userState };
  },
  USER_ACTION_CLEAR_CONTACT_DETAILS: (state: IUserActionsInitialState) => {
    return { ...state, user: { ...state.user, email: '', orderPhoneNumber: '', nickName: undefined, discardAfterOrderSuccess: false, orderPhoneNumberFromUrl: undefined } };
  },
  USER_ACTION_CLEAR_NUMBER_FROM_URL: (state: IUserActionsInitialState) => {
    return { ...state, user: { ...state.user, orderPhoneNumberFromUrl: undefined } };
  }
};

export default {};
