import React from 'react';
import { useMediaQuery, Dialog, DialogContent } from '@material-ui/core';
import Transition from 'components/Transition';
import { useStyles } from './style';
import DialogHeader from 'components/DialogHeader';
import { useGetOrderQuery } from 'graphql/hooks';
import Content from './Content';

interface IProps {
  orderId: string | null;
  buyerId: string;
  isOpen: boolean;
  handleClose: () => void;
}

const OrderRatingDialog: React.FC<IProps> = ({ orderId, buyerId, isOpen, handleClose }) => {
  const classes = useStyles();

  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  const onBack = () => {
    handleClose();
  };

  const { order } = useGetOrderQuery(orderId, buyerId);

  return (
    <Dialog className={classes.dialog} open={isOpen} fullScreen={fullScreen} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
      <DialogContent className={classes.dialogContent}>
        <DialogHeader text="RATE YOUR EXPERIENCE" onBack={onBack} />
        {order && <Content order={order} orderId={orderId} buyerId={buyerId} handleClose={handleClose} />}
      </DialogContent>
    </Dialog>
  );
};

export default OrderRatingDialog;
