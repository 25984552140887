import { IPizzaToppingData, IPizzaToppingGroupExtendedType, IPizzaData } from './types';
import { map, filter, find, cloneDeep } from 'lodash';
import { PizzaToppingDensity, Dish, OrderPizzaToppingInput, PizzaToppingSide } from 'generated/types';
import { convertPrice } from 'util/number';
import Big from 'big.js';

export const convertResponseToFormValues = (response: Dish) => {
  const values: IPizzaData = {
    selectedSizeId: '',
    selectedCrustId: '',
    halfAndHalf: false,
    leftPizza: {
      pizzaToppingsGroup: null,
      pizzaCheeseGroup: null,
      pizzaSauceGroup: null
    },
    rightPizza: {
      pizzaToppingsGroup: null,
      pizzaCheeseGroup: null,
      pizzaSauceGroup: null
    },
    wholePizza: {
      pizzaToppingsGroup: null,
      pizzaCheeseGroup: null,
      pizzaSauceGroup: null
    }
  };

  if (response.pizzaBasePrice) {
    values.selectedSizeId = response.pizzaBasePrice[0].size.id;
  }
  if (response.pizzaCrust) {
    const defaultCrust = filter(response.pizzaCrust, ({ isDefault }) => !!isDefault);

    if (defaultCrust.length) {
      values.selectedCrustId = defaultCrust[0].id;
    } else {
      values.selectedCrustId = response.pizzaCrust[0].id;
    }
  }

  let pizzaToppingGroupsdata: IPizzaToppingGroupExtendedType[] | null = null;

  if (response.pizzaToppingGroups) {
    pizzaToppingGroupsdata = map(response.pizzaToppingGroups, ({ id, items, ...rest }) => {
      const iItems = map(items, ({ preset, prices, ...iItemrest }) => {
        const priceBySize = prices.find((l) => l.size.id === values.selectedSizeId);
        if (preset) {
          const { intValue, shift } = priceBySize?.densityPrices.find((priceItem) => priceItem.density === PizzaToppingDensity.Regular)?.price || {
            intValue: 0,
            shift: 0
          };

          return { selectedDensity: PizzaToppingDensity.Regular, price: Big(convertPrice(intValue, shift)), priceId: priceBySize?.id || '', preset, prices, ...iItemrest };
        }
        const { intValue, shift } = priceBySize?.densityPrices.find((priceItem) => priceItem.density === PizzaToppingDensity.None)?.price || {
          intValue: 0,
          shift: 0
        };
        return { selectedDensity: PizzaToppingDensity.None, price: Big(convertPrice(intValue, shift)), priceId: priceBySize?.id || '', preset, prices, ...iItemrest };
      });

      return { id, items: iItems, ...rest };
    });
  }

  let pizzaSauceGroupData: IPizzaToppingGroupExtendedType | null = null;

  const pizzaSauceGroupItemsData = map(response?.pizzaSauce?.items, ({ preset, prices, ...rest }) => {
    const priceBySize = prices.find((l) => l.size.id === values.selectedSizeId);

    if (preset) {
      const { intValue, shift } = priceBySize?.densityPrices.find((priceItem) => priceItem.density === PizzaToppingDensity.Regular)?.price || {
        intValue: 0,
        shift: 0
      };

      return { selectedDensity: PizzaToppingDensity.Regular, price: Big(convertPrice(intValue, shift)), priceId: priceBySize?.id || '', preset, prices, ...rest };
    }
    const { intValue, shift } = priceBySize?.densityPrices.find((priceItem) => priceItem.density === PizzaToppingDensity.None)?.price || {
      intValue: 0,
      shift: 0
    };
    return { selectedDensity: PizzaToppingDensity.None, price: Big(convertPrice(intValue, shift)), priceId: priceBySize?.id || '', preset, prices, ...rest };
  });

  if (response.pizzaSauce) {
    pizzaSauceGroupData = { ...response?.pizzaSauce, items: pizzaSauceGroupItemsData };
  }

  let pizzaCheeseGroupData: IPizzaToppingGroupExtendedType | null = null;

  const pizzaCheeseGroupItemsData = map(response?.pizzaCheese?.items, ({ preset, prices, ...rest }) => {
    const priceBySize = prices.find((l) => l.size.id === values.selectedSizeId);
    if (preset) {
      const { intValue, shift } = priceBySize?.densityPrices.find((priceItem) => priceItem.density === PizzaToppingDensity.Regular)?.price || {
        intValue: 0,
        shift: 0
      };

      return { selectedDensity: PizzaToppingDensity.Regular, price: Big(convertPrice(intValue, shift)), priceId: priceBySize?.id || '', preset, prices, ...rest };
    }
    const { intValue, shift } = priceBySize?.densityPrices.find((priceItem) => priceItem.density === PizzaToppingDensity.None)?.price || {
      intValue: 0,
      shift: 0
    };
    return { selectedDensity: PizzaToppingDensity.None, price: Big(convertPrice(intValue, shift)), priceId: priceBySize?.id || '', preset, prices, ...rest };
  });

  if (response.pizzaCheese) {
    pizzaCheeseGroupData = { ...response?.pizzaCheese, items: pizzaCheeseGroupItemsData };
  }

  values.leftPizza = {
    pizzaSauceGroup: cloneDeep(pizzaSauceGroupData),
    pizzaCheeseGroup: cloneDeep(pizzaCheeseGroupData),
    pizzaToppingsGroup: cloneDeep(pizzaToppingGroupsdata)
  };
  values.rightPizza = {
    pizzaSauceGroup: cloneDeep(pizzaSauceGroupData),
    pizzaCheeseGroup: cloneDeep(pizzaCheeseGroupData),
    pizzaToppingsGroup: cloneDeep(pizzaToppingGroupsdata)
  };
  values.wholePizza = {
    pizzaSauceGroup: cloneDeep(pizzaSauceGroupData),
    pizzaCheeseGroup: cloneDeep(pizzaCheeseGroupData),
    pizzaToppingsGroup: cloneDeep(pizzaToppingGroupsdata)
  };

  return values;
};

export const getOrderLineItems = (toppingData: IPizzaToppingData, side: PizzaToppingSide, selectedSizeId: string) => {
  const pizzaToppingGroupsOrderLineItems: OrderPizzaToppingInput[] = [];

  const pizzaSaucesOrderLineItems: OrderPizzaToppingInput[] = [];

  const pizzaCheeseOrderLineItems: OrderPizzaToppingInput[] = [];

  const { pizzaSauceGroup, pizzaCheeseGroup, pizzaToppingsGroup } = toppingData;

  if (pizzaSauceGroup && pizzaSauceGroup.items) {
    pizzaSauceGroup.items.forEach((item) => {
      if ((item.preset && item.selectedDensity === PizzaToppingDensity.None) || item.selectedDensity !== PizzaToppingDensity.None) {
        const priceId = find(item.prices, ({ size }) => size.id === selectedSizeId)?.id;
        if (priceId) {
          pizzaSaucesOrderLineItems.push({
            toppingGroupId: pizzaSauceGroup.id,
            toppingId: item.id,
            side,
            toppingDensity: item.selectedDensity,
            priceId
          });
        }
      }
    });
  }

  if (pizzaCheeseGroup && pizzaCheeseGroup.items) {
    pizzaCheeseGroup.items.forEach((item) => {
      if ((item.preset && item.selectedDensity === PizzaToppingDensity.None) || item.selectedDensity !== PizzaToppingDensity.None) {
        const priceId = find(item.prices, ({ size }) => size.id === selectedSizeId)?.id;
        if (priceId) {
          pizzaCheeseOrderLineItems.push({
            toppingGroupId: pizzaCheeseGroup.id,
            toppingId: item.id,
            side,
            toppingDensity: item.selectedDensity,
            priceId
          });
        }
      }
    });
  }

  if (pizzaToppingsGroup && pizzaToppingsGroup.length) {
    pizzaToppingsGroup.forEach((group) => {
      if (group.items && group.items.length) {
        group.items.forEach((item) => {
          if ((item.preset && item.selectedDensity === PizzaToppingDensity.None) || item.selectedDensity !== PizzaToppingDensity.None) {
            const priceId = find(item.prices, ({ size }) => size.id === selectedSizeId)?.id;
            if (priceId) {
              pizzaToppingGroupsOrderLineItems.push({
                toppingGroupId: group.id,
                toppingId: item.id,
                side,
                toppingDensity: item.selectedDensity,
                priceId
              });
            }
          }
        });
      }
    });
  }

  return {
    pizzaToppingGroupsOrderLineItems,
    pizzaSaucesOrderLineItems,
    pizzaCheeseOrderLineItems
  };
};
