import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SecureCheckoutRoute } from 'components/SecureRoutes';
import Payment from './Payment';
import PayByCard from './PayByCard';
import PayByCash from './PayByCash';

const PaymentRoutes: React.FC = () => {
  return (
    <Switch>
      <SecureCheckoutRoute>
        <Route exact={true} path="/payment" component={Payment} />
        <Route path="/payment/card" component={PayByCard} />
        <Route path="/payment/onsite" component={PayByCash} />
      </SecureCheckoutRoute>
    </Switch>
  );
};

export default PaymentRoutes;
