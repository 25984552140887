import React, { useCallback, useEffect, useState } from 'react';
import { Box, useMediaQuery, Theme, Dialog } from '@material-ui/core';
import { IProps } from './types';
import { useStyles } from './style';
import Transition from 'components/Transition';
import DialogHeader from 'components/DialogHeader';
import SelectCouponDialogContent from './SelectCouponDialogContent';
import { Coupon, VerifyCouponQuery } from 'generated/types';
import { couponOrderTypeMap, getCheckedCouponFromList, getSortedCouponsbyDiscount } from 'util/couponUtils';
import { convertPriceTo } from 'util/number';
import Moment from 'moment';
import { useStore } from 'store';
import { useSnackbar } from 'notistack';
import client from 'apollo';
import { verifyCouponQuery } from 'graphql/query';
import ApplyPromoButton from './ApplyPromoButton';

enum TypeToCouponType {
  TAKE_OUT = 'TAKEOUT',
  DELIVERY = 'DELIVERY',
  DINING = 'DINING'
}
const PromotionsNew: React.FC<IProps> = ({
  restaurantId,
  coupons,
  orderType,
  subtotalToDiscount,
  subtotal,
  isDiscountApplied,
  appliedCoupon,
  setCouponData,
  isCartHavingPrediscountedItem,
  deliveryTime,
  isPrediscountEnabled,
  discount
}) => {
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [applicableCoupons, setApplicableCoupons] = useState<Coupon[]>([]);
  const [notApplicableCoupons, setNotApplicableCoupons] = useState<Coupon[]>([]);
  const [isOpen, setOpen] = useState(false);
  const {
    state: { couponData, couponToken, isAutoApplyCouponAllowed },
    dispatch
  } = useStore();
  const [applyCouponLoading, setApplyCouponLoading] = useState(false);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);

  useEffect(() => {
    if (coupons && coupons.length > 0) {
      const { applicableCouponsFiltered, notApplicableCouponsFiltered } = getCheckedCouponFromList(coupons, deliveryTime, subtotalToDiscount, orderType);
      setApplicableCoupons(applicableCouponsFiltered);
      setNotApplicableCoupons(notApplicableCouponsFiltered);
    }
  }, [coupons, deliveryTime, subtotalToDiscount, orderType]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  // Common function module to apply coupon weather it's auto or select
  const applyCoupon = useCallback(
    async (code: string) => {
      return new Promise(async (resolve, reject) => {
        setApplyCouponLoading(true);
        const { data: verifiedData } = await client.query<VerifyCouponQuery>({
          query: verifyCouponQuery,
          variables: {
            input: {
              bizId: restaurantId,
              subtotal: parseInt(convertPriceTo(subtotalToDiscount, 'CENT'), 10),
              code,
              verifyDay: Moment(deliveryTime).format('YYYY-MM-DD'),
              orderType: couponOrderTypeMap[orderType]
            }
          },
          fetchPolicy: 'network-only'
        });
        if (verifiedData && verifiedData.verifyCoupon && verifiedData.verifyCoupon.success) {
          if (verifiedData.verifyCoupon.coupon && verifiedData.verifyCoupon.token) {
            setCouponData({
              coupon: verifiedData.verifyCoupon.coupon,
              token: verifiedData.verifyCoupon.token
            });
            resolve({ isSuccess: true, applyCouponData: verifiedData.verifyCoupon });
            setApplyCouponLoading(false);
            if (isCartHavingPrediscountedItem && isPrediscountEnabled) {
              snackbar.enqueueSnackbar('Some Item(s) in the cart are already discounted. Coupons will not apply on them.', { variant: 'info', autoHideDuration: 4000 });
            }
          } else {
            resolve({ isSuccess: false, applyCouponData: verifiedData.verifyCoupon }); // triggering 'applycoupon' with async/await that's why resolving instead rejecting
            setApplyCouponLoading(false);
          }
        } else {
          resolve(null);
        }
      });
    },
    [deliveryTime, isCartHavingPrediscountedItem, isPrediscountEnabled, orderType, restaurantId, setCouponData, snackbar, subtotalToDiscount]
  );

  const pickAndApplyCoupons = useCallback(
    async (sortedCodes: string[]) => {
      if (sortedCodes.length > 0 && isAutoApplyCouponAllowed === true) {
        let tempSortedCodes = [...sortedCodes];
        let codeToApply = tempSortedCodes[0]; // first index coupon code will be maximum as array is sorted in decending manner
        const response: any = await applyCoupon(codeToApply);
        if (response && response.isSuccess) {
          setCouponApplied(true);
        } else {
          if (isAutoApplyCouponAllowed) {
            tempSortedCodes.shift(); // remove first index coupon code which got failed to apply
            pickAndApplyCoupons(tempSortedCodes);
          }
        }
      }
    },
    [applyCoupon, isAutoApplyCouponAllowed]
  );
  const removeDiscount = useCallback(() => {
    dispatch({
      type: 'CART_ACTION_TOGGLE_COUPON_AUTO_APPLY',
      payload: false
    });
    setCouponApplied(false);
    setCouponData(null);
  }, [dispatch, setCouponData]);
  const autoRemoveDiscount = useCallback(() => {
    setCouponApplied(false);
    setCouponData(null);
  }, [setCouponData]);
  useEffect(() => {
    if (
      couponData &&
      couponData.code !== getSortedCouponsbyDiscount(applicableCoupons, subtotalToDiscount)[0] &&
      orderType !== '' &&
      !couponData.orderType?.includes(TypeToCouponType[orderType]) &&
      couponToken
    ) {
      autoRemoveDiscount();
    }
  }, [applicableCoupons, couponData, couponToken, orderType, autoRemoveDiscount, subtotalToDiscount]);
  // Auto Apply coupon
  useEffect(() => {
    if (!applyCouponLoading && isAutoApplyCouponAllowed && applicableCoupons && applicableCoupons.length > 0 && !couponApplied && couponData === null) {
      /**
            Prepare coupon codes list sorted by Maximum discount in decending manner.
            If any coupon triggers error try another(next maximum discounted coupon)
        */
      const sortedCodes = getSortedCouponsbyDiscount(applicableCoupons, subtotalToDiscount);
      pickAndApplyCoupons(sortedCodes);
    }
  }, [applyCouponLoading, applicableCoupons, applyCoupon, couponApplied, couponData, isAutoApplyCouponAllowed, pickAndApplyCoupons, subtotalToDiscount]);

  return (
    <Box>
      <ApplyPromoButton
        isApplyingCoupon={applyCouponLoading}
        handleOpen={handleOpen}
        removeDiscount={removeDiscount}
        isDiscountApplied={isDiscountApplied}
        code={appliedCoupon?.code}
        isOpen={isOpen}
        couponData={couponData}
        discount={discount}
        setCouponData={setCouponData}
      />
      <Dialog className={classes.dialog} open={isOpen} fullScreen={fullScreen} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
        <DialogHeader text="Apply Promo" onBack={handleClose} />
        <SelectCouponDialogContent
          subtotalToDiscount={subtotalToDiscount}
          subtotal={subtotal}
          isCartHavingPrediscountedItem={isCartHavingPrediscountedItem}
          isPrediscountEnabled={isPrediscountEnabled}
          handleClose={handleClose}
          applicableCoupons={applicableCoupons}
          notApplicableCoupons={notApplicableCoupons}
          applyCoupon={applyCoupon}
        />
      </Dialog>
    </Box>
  );
};

export default PromotionsNew;
