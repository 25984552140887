import { Coupon } from 'generated/types';
import { Box, Typography, Button, CircularProgress, DialogContent, InputAdornment, TextField } from '@material-ui/core';
import { QUALIFIED, NOT_QUALIFIED } from 'strings';
import React, { ChangeEvent, useState } from 'react';
import { useStyles } from '../style';
import RedeemIcon from '@material-ui/icons/Redeem';
import { useFormik } from 'formik';
import Big from 'big.js';
import CouponsListContainer from '../CouponsListContainer';
interface IProps {
  subtotalToDiscount: string;
  subtotal: string;
  isCartHavingPrediscountedItem: boolean;
  isPrediscountEnabled: boolean;
  handleClose: () => void;
  applicableCoupons: Coupon[];
  notApplicableCoupons: Coupon[];
  applyCoupon: (code: string) => Promise<any>;
}

const SelectCouponDialogContent: React.FC<IProps> = ({
  subtotalToDiscount,
  subtotal,
  isCartHavingPrediscountedItem,
  isPrediscountEnabled,
  handleClose,
  applicableCoupons,
  notApplicableCoupons,
  applyCoupon
}) => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    onSubmit: async ({ code }) => {
      formik.setSubmitting(true);
      try {
        //Checking if coupon is applicable on the cart balance
        if (['0.00', '0', '.0', ''].includes(subtotalToDiscount)) {
          throw Error('Item(s) in the cart are already at discounted price. Coupon will not apply');
        }

        if (!(applicableCoupons.filter((couponItem: Coupon) => couponItem.code === code).length > 0) && notApplicableCoupons.filter((couponItem: Coupon) => couponItem.code === code).length > 0) {
          throw Error('Coupon not applicable.');
        }

        const response = await applyCoupon(code);

        if (response) {
          if (response.isSuccess) {
            formik.setSubmitting(false);
            formik.resetForm();
            handleClose();
          } else {
            if (response.applyCouponData && response.applyCouponData.error) {
              let errorCode = response.applyCouponData.error;
              formik.setSubmitting(false);
              if (errorCode === 'INVALID_COUPON_CODE') {
                throw Error('Invalid coupon code. Please try another coupon');
              } else if (errorCode === 'COUPON_EXPIRED') {
                throw Error('Coupon has expired. Please try another coupon');
              } else if (errorCode === 'EXCEED_MAX_USE') {
                throw Error('Maximun usage has exceeded. Please try another coupon');
              } else if (errorCode === 'DAY_OF_WEEK') {
                throw Error('Coupon not applicable today');
              } else if (errorCode === 'COUPON_ORDER_TYPE') {
                throw Error('Coupon not applicable for this order type');
              } else if (errorCode === 'ALREADY_USED') {
                throw Error('This coupon was already used once, it was only for one time usage.');
              } else if (errorCode === 'LOW_SUBTOTAL') {
                let errorMessage = '';
                if (response.applyCouponData?.coupon?.minSpending) {
                  const minSpendingCents = response.applyCouponData?.coupon?.minSpending;
                  const minSpending = Big(minSpendingCents)
                    .div(100)
                    .toString();

                  errorMessage = `A minimum purchase of $${minSpending} is required`;
                } else {
                  errorMessage = `Minimum purchase required`;
                }
                throw Error(errorMessage);
              } else {
                throw Error('Something went wrong');
              }
            }
          }
        } else {
          throw Error('Something went wrong');
        }
      } catch (e) {
        let err: any = e;
        formik.setSubmitting(false);
        if (err && err.message) {
          setErrorMessage(err?.message);
        } else {
          setErrorMessage('Something went wrong');
        }
      }
    }
  });

  const { values, setFieldValue } = formik;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);

    if (e.target.value.includes(' ')) {
      return false;
    }

    e.target.value = e.target.value.toUpperCase();

    formik.handleChange(e);
  };

  const selectCoupon = (code: string) => {
    setErrorMessage(null);
    setFieldValue('code', code);
  };
  return (
    <DialogContent className={classes.dialogContent}>
      <Box className={classes.container}>
        <Box paddingX={1} paddingY={2}>
          <TextField
            name="code"
            value={formik.values.code}
            variant="outlined"
            fullWidth={true}
            placeholder="Enter Promo Code"
            InputProps={{
              style: { textAlign: 'center' },
              startAdornment: (
                <InputAdornment position="start">
                  <RedeemIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={onChange}
            style={{ textAlign: 'center' }}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        </Box>
        {!!applicableCoupons && applicableCoupons.length === 0 && (
          <Box textAlign="center">
            <Typography variant="subtitle1">No Coupons found for this Purchase...</Typography>
          </Box>
        )}

        {isCartHavingPrediscountedItem && isPrediscountEnabled && (
          <Box textAlign="center" paddingX={2}>
            <Typography style={{ fontWeight: 'bold' }} variant="body1" color="secondary">
              {Big(subtotalToDiscount).gt(0) ? `${QUALIFIED} $${subtotalToDiscount}` : NOT_QUALIFIED}
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="body1" color="secondary">
              Already Discounted Items don’t Qualify
            </Typography>
          </Box>
        )}
        <Box paddingX={1} flex={1} overflow="scroll">
          <CouponsListContainer selectCoupon={selectCoupon} appliedCode={values.code} applicableCoupons={applicableCoupons} notApplicableCoupons={notApplicableCoupons} />
        </Box>
        <Box marginX={1} marginY={1} display="flex" boxShadow={1} flex="0 0 auto">
          <Box flex="1 1 0" paddingY={1} paddingX={2}>
            <Typography variant="body1">
              <strong>Total: ${subtotal}</strong>
            </Typography>
          </Box>
          <Button
            disabled={!formik.dirty || formik.isSubmitting}
            className={classes.paymentButton}
            variant="contained"
            disableElevation={true}
            color="primary"
            fullWidth={true}
            onClick={formik.submitForm}>
            {formik.isSubmitting ? <CircularProgress style={{ width: '25px', height: '25px', color: '#fff' }} /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default SelectCouponDialogContent;
