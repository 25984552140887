import React from 'react';
import { Box, Button, Typography, Backdrop, Divider } from '@material-ui/core';
import { useStyles } from './style';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

interface IProps {
  acceptDisclaimer: () => void;
  onClose: () => void;
}

const AlcoholAgreement: React.FC<IProps> = ({ acceptDisclaimer, onClose }) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <>
      <Backdrop open={true} className={classes.backdrop}>
        <Box height="max-content" className={classes.content}>
          <Box paddingX={2}>
            <Box onClick={onClose} className={classes.closeBtn}>
              <Close />
            </Box>
            <Box>
              <Typography className={classes.bold} variant="h6">
                Alcohol Agreement
              </Typography>
            </Box>
            <Box paddingTop={1}>
              <Typography className={classes.bold} variant="body2">
                You are purchasing alcohol with this order.
              </Typography>
              <Typography className={classes.bold} variant="body2">
                By continuing, you have agreed to the following:
              </Typography>
            </Box>
            <Box paddingTop={3}>
              <Box>
                <Typography className={classes.subheading} variant="h6">
                  Must be picked up by someone 21+
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.bold} variant="body2">
                  A valid, non-expired government ID must also be presented to pick up the order.
                </Typography>
              </Box>
            </Box>
            <Box paddingTop={3}>
              <Box>
                <Typography className={classes.subheading} variant="h6">
                  Refund restrictions apply
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.bold} variant="body2">
                  You won't receive any part of the order or a refund if the recipient is underage, intoxicated or unavailable.
                </Typography>
              </Box>
            </Box>
            <Box paddingTop={2}>
              <Typography className={classes.heading} variant="h6">
                See Lokobee's&nbsp;
                <span onClick={() => history.push('/useragreement')} className={classes.link}>
                  User Agreement
                </span>
              </Typography>
            </Box>
            <Box textAlign="justify" paddingTop={2}>
              <Typography variant="caption">
                <span className={classes.bold}>WARNING</span>: Drinking distilled spirits, beer, coolers, wine and other alcoholic beverages may increase cancer risk, and, during pregnancy, can cause
                birth defects. For more information go to&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="http://www.p65warnings.ca.gov/alcohol">
                  <span className={classes.link}>www.P65Warnings.ca.gov/alcohol</span>
                </a>
                .
              </Typography>
            </Box>
            <Box paddingTop={2}>
              <Divider />
            </Box>
            <Box paddingTop={2} textAlign="center">
              <Button onClick={acceptDisclaimer} variant="contained" fullWidth={true} color="secondary">
                Accept & Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

export default AlcoholAgreement;
