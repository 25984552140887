import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CustomBottomDialog } from '@lokobee/lokobee-ui';
import { useStyles } from './style';

interface IProps {
  note: string;
  onChange: (payload: string) => void;
}

const OrderNote = ({ note, onChange }: IProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [_note, setNote] = useState(note);

  const onSubmit = () => {
    onChange(_note);
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
    setNote(note);
  };

  const Clear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange('');
    setNote('');
  };

  return (
    <>
      <Box paddingX={2} onClick={() => setOpen(true)} marginY={2}>
        <TextField
          type="text"
          fullWidth={true}
          size="small"
          variant="outlined"
          value={note}
          placeholder="Order Note"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <React.Fragment>
                <CloseIcon cursor="pointer" onClick={Clear} />
              </React.Fragment>
            )
          }}
        />
      </Box>
      <CustomBottomDialog open={open}>
        <Box padding={2}>
          <Typography variant="h6">Enter Order Note</Typography>
          <br />
          <TextField type="text" multiline={true} rowsMax="3" fullWidth={true} variant="outlined" value={_note} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)} />
          <Box paddingY={1} textAlign="right">
            <Button onClick={onSubmit} variant="contained" color="primary" className={classes.btns}>
              Submit
            </Button>
            <Button onClick={onCancel} variant="outlined" color="default" className={classes.btns}>
              Cancel
            </Button>
          </Box>
        </Box>
      </CustomBottomDialog>
    </>
  );
};

export default OrderNote;
