import { useEffect, useContext, useState } from 'react';
import { useLazyQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { getLokobeeUserQueryStr, getUserGroceryRewardPointsQuery, getUserRewardPointsQuery } from 'graphql/query';
import { setUserDealOptOutMutation, feedbackMutation, updateUserMutation } from 'graphql/mutations';
import { AuthContext } from 'fbase/authContext';
import { useStore } from 'store';

export const useUserFeedbackMutation = () => {
  const [sendFeedback, { data, loading, error }] = useMutationWithLoader(feedbackMutation);

  return {
    sendFeedback,
    data,
    loading,
    error
  };
};

export const useSetUserDealOptMutation = () => {
  const [setUserDealOpt, { data, loading, error }] = useMutationWithLoader(setUserDealOptOutMutation);

  return {
    setUserDealOpt,
    data,
    loading,
    error
  };
};

export const useGetLokobeeUser = () => {
  // const { token } = useContext(AuthContext);

  // const [getUser, { loading, data, error }] = useLazyQueryWithLoader(
  //   getLokobeeUserQuery,
  //   {
  //     context: {
  //       headers: {
  //         Authorization: token
  //       }
  //     },
  //     fetchPolicy: 'network-only'
  //   },
  //   showLoader,
  //   from
  // );

  // useEffect(() => {
  //   if (token) getUser();
  // }, [token, getUser]);

  const { token } = useContext(AuthContext);
  const { dispatch } = useStore();

  const [data, setData] = useState<any | null>(null);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchUser() {
      console.log('Manually incremented');
      dispatch({
        type: 'LOADER_INCREMENT_COUNT'
      });
      setLoading(true);
      console.log('fetching user');

      const resp = await fetch(process.env.REACT_APP_BACKEND_GRAPH_URI!, {
        method: 'POST',
        body: JSON.stringify({
          query: getLokobeeUserQueryStr
        }),
        headers: {
          Authorization: token!,
          'content-type': 'application/json'
        }
      });

      if (resp.ok) {
        const res = await resp.json();
        setData(res.data);
      } else {
        setError('Failed to get user');
      }

      console.log('Manually decremented');
      dispatch({
        type: 'LOADER_DECREMENT_COUNT'
      });
      setLoading(false);
    }

    if (token) fetchUser();
  }, [dispatch, token]);

  return {
    loading,
    data,
    error
  };
};

export const useUpdateUserMutation = () => {
  const [updateUser, { data, loading, error }] = useMutationWithLoader(updateUserMutation);

  return {
    updateUser,
    data,
    loading,
    error
  };
};

export const useGetUserRewardsByRestaurant = (bizId: string = '', showLoader = false) => {
  const { currentUser } = useContext(AuthContext);

  const [rewardPointsData, setRewards] = useState<any>({});

  const [getRewards, { data, loading }] = useLazyQueryWithLoader(
    getUserRewardPointsQuery,
    {
      fetchPolicy: 'network-only'
    },
    showLoader
  );

  useEffect(() => {
    if (currentUser) {
      getRewards({
        variables: {
          input: {
            bizId: bizId ? bizId : ''
          }
        }
      });
    }
  }, [bizId, currentUser, getRewards]);

  useEffect(() => {
    if (data && data.getRewardPointsForConsumer) {
      const rewardsResult: any = {};

      data.getRewardPointsForConsumer.forEach((reward: any) => {
        rewardsResult[reward.issuer || ''] = reward;
      });

      setRewards(rewardsResult);
    }
  }, [data]);

  return {
    data,
    rewardPointsData,
    loading
  };
};

export const useGetUserGroceryRewards = (showLoader = false) => {
  const { currentUser } = useContext(AuthContext);

  const [rewardPointsData, setRewards] = useState<any>({});

  const [getRewards, { data, loading }] = useLazyQueryWithLoader(getUserGroceryRewardPointsQuery, {}, showLoader);

  useEffect(() => {
    if (currentUser) {
      getRewards();
    }
  }, [currentUser, getRewards]);

  useEffect(() => {
    if (data && data.getGroceryRewardPointsForConsumer) {
      const rewardsResult: any = {};

      data.getGroceryRewardPointsForConsumer.forEach((reward: any) => {
        rewardsResult[reward.issuer || ''] = reward;
      });

      setRewards(rewardsResult);
    }
  }, [data]);

  return {
    data,
    rewardPointsData,
    loading
  };
};

export const useGetUserRewardsByGroceryStore = (storeId: string | undefined | null = '', showLoader = false) => {
  const { currentUser } = useContext(AuthContext);

  const [rewardPointsData, setRewards] = useState<any>({});

  const [getRewards, { data, loading }] = useLazyQueryWithLoader(getUserGroceryRewardPointsQuery, {}, showLoader);

  useEffect(() => {
    if (currentUser && storeId) {
      getRewards({
        variables: {
          input: {
            storeId
          }
        }
      });
    }
  }, [storeId, currentUser, getRewards]);

  useEffect(() => {
    if (data && data.getGroceryRewardPointsForConsumer) {
      const rewardsResult: any = {};

      data.getGroceryRewardPointsForConsumer.forEach((reward: any) => {
        rewardsResult[reward.issuer || ''] = reward;
      });

      setRewards(rewardsResult);
    }
  }, [data]);

  return {
    data,
    rewardPointsData,
    loading
  };
};
