export interface IGroceryStoreState {
  lastActiveGroceryStore: string;
  groceryStoreId: string | null;
}

export const groceryStoreInitialState: IGroceryStoreState = {
  lastActiveGroceryStore: '',
  groceryStoreId: ''
};

export const groceryStoreActions = {
  SET_LASTACTIVE_GROCERY_STORE: (state: any, payload: any) => ({ ...state, lastActiveGroceryStore: payload }),
  RESET_LASTACTIVE_GROCERY_STORE: (state: any) => ({ ...state, lastActiveGroceryStore: '' }),
  SET_GROCERY_STORE: (state: any, payload: any) => ({ ...state, groceryStoreId: payload }),
  RESET_GROCERY_STORE: (state: any) => ({ ...state, groceryStoreId: null })
};

export default {};
